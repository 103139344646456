import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Badge,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import { getUser } from "../../../global/redux/action";
import { mobileNoValidation } from "../../../common/common";
import { invokeApi, apiList } from "./../../../services/apiServices";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DashboardHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [openProfileEditModal, setOpenProfileEditModal] = useState(false);
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const mobilevalidate = mobileNoValidation(phone);
  const [phoneHelperText, setPhoneHelperText] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [nameError, setNameError] = useState(false);
  const closeProfile = () => {
    setOpenProfile(false);
    setOpenProfileEditModal(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const updateUser = async (e) => {
    e.preventDefault();
    var validation = true;
    if (!mobilevalidate) {
      setPhoneHelperText("Please Enter Valid Mobile Number");
      setPhoneError(true);
      validation = false;
    }
    if (!name) {
      setNameHelperText("Please Enter Your Name");
      setNameError(true);
      validation = false;
    }
    if (validation) {
      let params = { id: userData?.users.id, name: name, phoneNumber: phone };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateUser,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setOpenProfileEditModal(false);
          closeProfile();
          setPhoneHelperText("");
          setNameError(false);
          setNameHelperText("");
          setPhoneError(false);
        } else {
          alert(
            "Something went wrong while getting the Update User Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Update User Details. Please try again later!!"
        );
      }
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={6}>
          <TextField
            type="text"
            size="small"
            value=""
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                borderRadius: 25,
                border: "none",
                outline: "none",
                backgroundColor: "#fff",
              }, // Adjust the border radius as needed
            }}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <Box sx={{ display: "inline-block", float: "right" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton color="inherit" size="large">
                <Badge badgeContent={5} color="error" overlap="circular">
                  <img
                    src="/media/svg/notificationIcon.svg"
                    width="26px"
                    height="26px"
                    alt="notification"
                  />
                </Badge>
              </IconButton>
              <Avatar
                onClick={handleClick}
                sx={{
                  width: 35,
                  height: 35,
                  cursor: "pointer",
                  backgroundColor: "primary.main",
                  border: "1px solid #fff",
                  borderRadius: "50%",
                }}
              >
                {userData?.users.name[0]}
              </Avatar>
            </Box>
          </Box>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setOpenProfile(true);
                handleClose();
              }}
            >
              My Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/");
                handleClose();
              }}
            >
              Visit Website
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/logout");
                handleClose();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <BootstrapDialog open={openProfile} onClose={closeProfile}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {openProfileEditModal ? "Update Details" : "Profile"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeProfile}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {!openProfileEditModal ? (
            <>
              <Typography sx={{ fontSize: "20px" }}>
                Name: {userData?.users?.name}
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                Email: {userData?.users?.email}
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                Mobile: {userData?.users?.phoneNumber}
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                Role: {userData?.users?.roles.map((role) => role + ",")}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  background: "#405189",
                  borderRadius: 5,
                  border: 0,
                  color: "white",
                  height: 40,
                  padding: "0 30px",

                  boxShadow: "#405189",
                  "&:hover": {
                    //you want this to be the same as the backgroundColor above
                    backgroundColor: "#405189",
                  },
                }}
                onClick={() => {
                  // Set the state to open the edit modal
                  setOpenProfileEditModal(true);
                  setName(userData?.users.name);
                  setPhone(userData?.users.phoneNumber);
                }}
              >
                Edit
              </Button>
              <Button
                onClick={closeProfile}
                sx={{
                  background: "#405189",
                  borderRadius: 5,
                  border: 0,
                  color: "white",
                  height: 40,
                  padding: "0 20px",
                  margin: "10px",
                  boxShadow: "#405189",
                  "&:hover": {
                    //you want this to be the same as the backgroundColor above
                    backgroundColor: "#405189",
                  },
                }}
              >
                cancel
              </Button>
            </>
          ) : (
            <>
              <form onSubmit={updateUser}>
                <TextField
                  label="Name"
                  value={name}
                  error={nameError}
                  helperText={nameHelperText}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  sx={{ my: 2 }}
                />
                <TextField
                  label="Mobile Number"
                  value={phone}
                  error={phoneError}
                  helperText={phoneHelperText}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                  sx={{ my: 2 }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  // disabled={isFileUploadFetching}
                  sx={{
                    background: "#405189",
                    borderRadius: 5,
                    border: 0,
                    color: "white",
                    height: 48,
                    padding: "0 30px",

                    boxShadow: "#405189",
                    "&:hover": {
                      //you want this to be the same as the backgroundColor above
                      backgroundColor: "#405189",
                    },
                  }}
                >
                  Update
                </Button>
              </form>
            </>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default DashboardHeader;
