import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";

const QuestionAnswer = () => {
  const [college, setCollege] = useState([]);
  return (
    <>
      <CollegeDetailHeader activeTab={10} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{ p: 3, height: "auto", overflow: "auto", width: "100%" }}
            >
              <Typography
                sx={{
                  backgroundColor: "#F2F2F2",
                  p: 1,
                  fontWeight: "bold",
                  color: "#000",
                  height: "41px",
                  fontSize: { xs: "14px", md: "18px" },
                }}
                variant="subtitle1"
              >
                {college.collegeName} FAQs:
              </Typography>
              <Box sx={{ my: 1, fontSize: { xs: "14px", md: "18px" } }}>
                {college.qnaSummary ? (
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: college.qnaSummary,
                    }}
                    sx={{ my: 2 }}
                  />
                ) : (
                  <Typography variant="h6">No data Found</Typography>
                )}
              </Box>
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default QuestionAnswer;
