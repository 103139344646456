import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";

const Placement = () => {
  const [college, setCollege] = useState([]);

  return (
    <>
      <CollegeDetailHeader activeTab={4} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }}>
              {college.placementCompanySummary ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      color: "#000",
                      fontSize: { xs: "14px", md: "18px" },
                    }}
                    variant="subtitle1"
                  >
                    {college.collegeName} Placements:
                  </Typography>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: college.placementCompanySummary,
                    }}
                    sx={{ my: 2 }}
                  />
                </>
              ) : null}

              <Typography
                sx={{
                  backgroundColor: "#F2F2F2",
                  p: 1,
                  fontWeight: "bold",
                  color: "#000",
                  fontSize: { xs: "14px", md: "18px" },
                  my: 2,
                }}
                variant="subtitle1"
              >
                Top Recruiters of {college.collegeName} include:
              </Typography>

              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                {/* <colgroup>
                  <col style={{ width: "33%" }} />
                  <col style={{ width: "33%" }} />
                  <col style={{ width: "34%" }} />
                </colgroup> */}
                <tbody>
                  {college.placementCompantDetails?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          fontWeight: "bold",
                        }}
                      >
                        {item.companyName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Placement;
