import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useNavigate } from "react-router-dom";

const CollegeCourse = ({
  course,
  setCourse,
  courseTemplate,
  courseNameError,
  setCourseFeeError,
  courseNameHelperText,
  setCourseNameHelperText,
  setCourseBatchCapacityHelperText,
  scholorshipList,
  setCourseBatchCapacityError,
  courseBatchCapacityError,
  setCourseFreeHelperText,
  courseFreeHelperText,
  courseBatchCapacityHelperText,
  setCourseNameError,
  courseFeeError,
  specializationIdError,
  specializationIdHelperText,
  setSpecializationIdError,
  setSpecializationIdHelperText,
  yearError,
  yearHelperText,
  setYearError,
  setYearHelperText,
  monthError,
  monthHelperText,
  setMonthError,
  setMonthHelperText,
  selectedProgram,
}) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [courseList, setCourseNameList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [examCutoffError, setExamCutoffError] = useState(false);
  const [scholarshipAmtError, setScholarshipAmtError] = useState(false);

  // below the function is adding course with existing course
  const addCourse = () => {
    setCourse([...course, courseTemplate]);
  };

  // below the function is course all fields and courseIndex save to the course state
  const onChangeGetCourseDetails = (e, courseIndex) => {
    const { name, value } = e.target;
    const updatedCourse = course.map((courseObj, i) =>
      i === courseIndex ? { ...courseObj, [name]: value } : courseObj
    );
    setCourse(updatedCourse);
  };
  // below the function is remove row in course
  const removeCourse = (courseIndex) => {
    const updatedCourse = [...course];
    updatedCourse.splice(courseIndex, 1);
    setCourse(updatedCourse);
  };

  const addExam = (courseIndex) => {
    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseEntryCutoffList.push({
      examId: "",
      cutoff: "",
      cutoffType: "",
    });
    setCourse(updatedCourse);
  };
  const addScholarship = (courseIndex) => {
    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseScholarshipCutoffList.push({
      examId: "",
      cutoff: "",
      cutoffType: "",
      scholarship: "",
      scholarshipId: "",
      scholarshipType: "",
    });
    setCourse(updatedCourse);
  };

  const removeExam = (courseIndex, examIndex) => {
    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseEntryCutoffList.splice(
      examIndex,
      1
    );
    setCourse(updatedCourse);
  };
  const removeScholarship = (courseIndex, scholarshipIndex) => {
    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseScholarshipCutoffList.splice(
      scholarshipIndex,
      1
    );
    setCourse(updatedCourse);
  };

  const onChangeGetCourseExamDetails = (e, examIndex, courseIndex) => {
    const { name, value } = e.target;

    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseEntryCutoffList[examIndex] = {
      ...updatedCourse[courseIndex].collegeCourseEntryCutoffList[examIndex],
      [name]: value,
    };

    setCourse(updatedCourse);
  };
  const onChangeGetCourseScholarshipDetails = (
    e,
    scholarshipIndex,
    courseIndex
  ) => {
    const { name, value } = e.target;

    const updatedCourse = [...course];
    updatedCourse[courseIndex].collegeCourseScholarshipCutoffList[
      scholarshipIndex
    ] = {
      ...updatedCourse[courseIndex].collegeCourseScholarshipCutoffList[
        scholarshipIndex
      ],
      [name]: value,
    };

    setCourse(updatedCourse);
  };

  useEffect(() => {
    const getSpecialization = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAllSpecialization,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setSpecializationList(response.data.specializations);
        } else {
          alert(
            "Something went wrong while getting the Specializations Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Specializations Details. Please try again later!!"
        );
      }
    };
    const getExam = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getExams,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setExamList(response.data.exams);
        } else {
          alert(
            "Something went wrong while getting the Exams Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Exams Details. Please try again later!!"
        );
      }
    };
    getSpecialization();

    getExam();
  }, [cookies]);

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
        programId: selectedProgram,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseNameList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the course list Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the course list Details. Please try again later!!"
        );
      }
    };
    if (selectedProgram) {
      getAllCourseName();
    }
  }, [cookies, selectedProgram]);
  return (
    <>
      {/* add row courses start from here  */}
      {course?.map((courseObj, courseIndex) => (
        <TableContainer
          key={courseIndex}
          component={Paper}
          elevation={3}
          sx={{ mb: 2 }}
        >
          <Table>
            <TableBody>
              <TableRow sx={{ backgroundColor: "#dfe6e9" }}>
                <TableCell>Course</TableCell>
                <TableCell>Course Fee</TableCell>
                <TableCell>Batch Capacity</TableCell>
                <TableCell>Specialization</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Add</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel id={`select-college-course-${courseIndex}`}>
                      Course
                    </InputLabel>
                    <Select
                      labelId={`select-college-course-${courseIndex}`}
                      id={`select-college-course-${courseIndex}`}
                      name="courseId"
                      error={courseNameError}
                      helperText={courseNameHelperText}
                      value={courseObj.courseId}
                      label="Course"
                      onChange={(e) => {
                        onChangeGetCourseDetails(e, courseIndex);
                        setCourseNameError(false);
                        setCourseNameHelperText("");
                      }}
                      autoComplete="off"
                    >
                      {courseList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.courseName + " (" + item.courseType + ")"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    error={courseFeeError}
                    helperText={courseFreeHelperText}
                    value={courseObj.courseFee}
                    label="Course Fee"
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (!isNaN(inputValue) || inputValue === "") {
                        onChangeGetCourseDetails(e, courseIndex);
                        setCourseFeeError(false);
                        setCourseFreeHelperText("");
                      } else {
                        setCourseFeeError(true);
                      }
                    }}
                    fullWidth
                    name="courseFee"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    type="text"
                    error={courseBatchCapacityError}
                    helperText={courseBatchCapacityHelperText}
                    label="Batch Capacity"
                    value={courseObj.batchCapacity}
                    onChange={(e) => {
                      const inputValue = e.target.value;

                      if (!isNaN(inputValue) || inputValue === "") {
                        onChangeGetCourseDetails(e, courseIndex);
                        setCourseBatchCapacityError(false);
                        setCourseBatchCapacityHelperText("");
                      } else {
                        setCourseBatchCapacityError(true);
                      }
                    }}
                    fullWidth
                    name="batchCapacity"
                  />
                </TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel id={`select-specialization-${courseIndex}`}>
                      Specialization
                    </InputLabel>
                    <Select
                      labelId={`select-specialization-${courseIndex}`}
                      id={`select-specialization-${courseIndex}`}
                      error={specializationIdError}
                      value={courseObj.courseSpecializationId}
                      label="Specialization"
                      name="courseSpecializationId"
                      onChange={(e) => {
                        onChangeGetCourseDetails(e, courseIndex);
                        setSpecializationIdError(false);
                        setSpecializationIdHelperText("");
                      }}
                      fullWidth
                      sx={{
                        color: specializationIdError ? "red" : undefined,
                      }}
                    >
                      {specializationList?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.specialization}
                        </MenuItem>
                      ))}
                    </Select>
                    {specializationIdError && (
                      <Typography sx={{ fontSize: "0.75rem", color: "red" }}>
                        {specializationIdHelperText}
                      </Typography>
                    )}
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      error={yearError}
                      helperText={yearHelperText}
                      value={courseObj.years}
                      label="year"
                      name="years"
                      onChange={(e) => {
                        onChangeGetCourseDetails(e, courseIndex);
                        setYearError(false);
                        setYearHelperText("");
                      }}
                      fullWidth
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      error={monthError}
                      helperText={monthHelperText}
                      value={courseObj.months}
                      label="Month"
                      name="months"
                      onChange={(e) => {
                        onChangeGetCourseDetails(e, courseIndex);
                        setMonthError(false);
                        setMonthHelperText("");
                      }}
                      fullWidth
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#405189",
                      borderRadius: 5,
                      border: 0,
                      color: "white",
                      height: 48,
                      padding: "0 30px",
                      boxShadow: "0 3px 5px 2px #405189",
                      transition: "background 0.3s",
                      "&:hover": {
                        background: "#405189",
                      },
                    }}
                    onClick={addCourse}
                  >
                    <AddIcon />
                  </Button>
                </TableCell>
                <TableCell>
                  {course.length > 1 && (
                    <IconButton
                      color="red"
                      onClick={() => removeCourse(courseIndex)}
                    >
                      <DeleteOutlineIcon fontSize="large" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#dfe6e9" }}>
                <TableCell>Exam</TableCell>
                <TableCell>Cut Off</TableCell>
                <TableCell>Type of Cut off</TableCell>
                <TableCell>Add</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
              {courseObj.collegeCourseEntryCutoffList?.map(
                (item, examIndex) => (
                  <TableRow key={examIndex}>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="select-college-course">Exam</InputLabel>
                        <Select
                          labelId="select-college-course"
                          id="select-college-course"
                          name="examId"
                          // error={courseNameError}
                          // helperText={courseNameHelperText}
                          value={item.examId} // Assuming `courseId` is the selected course ID
                          label="Exam"
                          onChange={(e) => {
                            onChangeGetCourseExamDetails(
                              e,
                              examIndex,
                              courseIndex
                            );
                            // setCourseNameError(false);
                            // setCourseNameHelperText("");
                          }}
                        >
                          {examList.map((exam) => (
                            <MenuItem key={exam.id} value={exam.id}>
                              {exam.examName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Cutoff"
                        value={item.cutoff}
                        name="cutoff"
                        error={examCutoffError}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (!isNaN(inputValue) || inputValue === "") {
                            onChangeGetCourseExamDetails(
                              e,
                              examIndex,
                              courseIndex
                            );
                            setExamCutoffError(false);
                          } else {
                            setExamCutoffError(true);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="select-college-course">
                          cutoff Type
                        </InputLabel>
                        <Select
                          labelId="select-college-course"
                          id="select-college-course"
                          name="cutoffType"
                          value={item.cutoffType}
                          label="Cutoff Type"
                          onChange={(e) => {
                            onChangeGetCourseExamDetails(
                              e,
                              examIndex,
                              courseIndex
                            );
                          }}
                        >
                          <MenuItem value="Percentage">Percentage</MenuItem>
                          <MenuItem value="Rank">Rank</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#405189",
                          borderRadius: 5,
                          border: 0,
                          color: "white",
                          height: 48,
                          padding: "0 30px",
                          boxShadow: "0 3px 5px 2px #405189",
                          transition: "background 0.3s",
                          "&:hover": {
                            background: "#405189",
                          },
                        }}
                        onClick={() => addExam(courseIndex)}
                      >
                        <AddIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      {course[courseIndex].collegeCourseEntryCutoffList.length >
                      1 ? (
                        <IconButton
                          color="red"
                          onClick={() => removeExam(courseIndex, examIndex)}
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                )
              )}
              <TableRow sx={{ backgroundColor: "#dfe6e9" }}>
                <TableCell>Scholarship</TableCell>
                <TableCell>Cut Off Amount</TableCell>
                <TableCell>Type of Cut off</TableCell>
                <TableCell>Exam</TableCell>
                <TableCell>Cut Off</TableCell>
                <TableCell>Type of Cut off</TableCell>
                <TableCell>Add</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
              {courseObj.collegeCourseScholarshipCutoffList?.map(
                (item, scholarshipIndex) => (
                  <TableRow key={scholarshipIndex}>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="select-college-course">
                          Scholarship
                        </InputLabel>
                        <Select
                          labelId="select-college-course"
                          id="select-college-course"
                          name="scholarshipId"
                          // error={courseNameError}
                          // helperText={courseNameHelperText}
                          value={item.scholarshipId} // Assuming `courseId` is the selected course ID
                          label="Exam"
                          onChange={(e) => {
                            onChangeGetCourseScholarshipDetails(
                              e,
                              scholarshipIndex,
                              courseIndex
                            );
                            // setCourseNameError(false);
                            // setCourseNameHelperText("");
                          }}
                        >
                          {scholorshipList.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.scholarshipName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="scholarship Amt"
                        name="scholarship"
                        value={item.scholarship}
                        error={scholarshipAmtError}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (!isNaN(inputValue) || inputValue === "") {
                            onChangeGetCourseScholarshipDetails(
                              e,
                              scholarshipIndex,
                              courseIndex
                            );
                            setScholarshipAmtError(false);
                          } else {
                            setScholarshipAmtError(true);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="select-college-course">
                          Scholarship Type
                        </InputLabel>
                        <Select
                          labelId="select-college-course"
                          id="select-college-course"
                          name="scholarshipType"
                          // error={courseNameError}
                          // helperText={courseNameHelperText}
                          value={item.scholarshipType} // Assuming `courseId` is the selected course ID
                          label="Scholarship Type"
                          onChange={(e) => {
                            onChangeGetCourseScholarshipDetails(
                              e,
                              scholarshipIndex,
                              courseIndex
                            );
                            // setCourseNameError(false);
                            // setCourseNameHelperText("");
                          }}
                        >
                          <MenuItem value="Percenteage">Percenteage</MenuItem>
                          <MenuItem value="Rank">Rank</MenuItem>
                          <MenuItem value="Rupees">Rupees</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="select-college-course">Exam</InputLabel>
                        <Select
                          labelId="select-college-course"
                          id="select-college-course"
                          name="examId"
                          // error={courseNameError}
                          // helperText={courseNameHelperText}
                          value={item.examId} // Assuming `courseId` is the selected course ID
                          label="Exam"
                          onChange={(e) => {
                            onChangeGetCourseScholarshipDetails(
                              e,
                              scholarshipIndex,
                              courseIndex
                            );
                            // setCourseNameError(false);
                            // setCourseNameHelperText("");
                          }}
                        >
                          {examList.map((scholarship) => (
                            <MenuItem
                              key={scholarship.id}
                              value={scholarship.id}
                            >
                              {scholarship.examName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Cut off"
                        name="cutoff"
                        value={item.cutoff}
                        onChange={(e) => {
                          onChangeGetCourseScholarshipDetails(
                            e,
                            scholarshipIndex,
                            courseIndex
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="select-college-course">
                          cutoff Type
                        </InputLabel>
                        <Select
                          labelId="select-college-course"
                          id="select-college-course"
                          name="cutoffType"
                          value={item.cutoffType} // Assuming `courseId` is the selected course ID
                          label="cutoff Type"
                          onChange={(e) => {
                            onChangeGetCourseScholarshipDetails(
                              e,
                              scholarshipIndex,
                              courseIndex
                            );
                          }}
                        >
                          <MenuItem value="Percentage">Percentage</MenuItem>
                          <MenuItem value="Rank">Rank</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        sx={{
                          background: "#405189",
                          borderRadius: 5,
                          border: 0,
                          color: "white",
                          height: 48,
                          padding: "0 30px",
                          boxShadow: "0 3px 5px 2px #405189",
                          transition: "background 0.3s",
                          "&:hover": {
                            background: "#405189",
                          },
                        }}
                        onClick={() => addScholarship(courseIndex)}
                      >
                        <AddIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      {course[courseIndex].collegeCourseScholarshipCutoffList
                        .length > 1 ? (
                        <IconButton
                          color="red"
                          onClick={() =>
                            removeScholarship(courseIndex, scholarshipIndex)
                          }
                        >
                          <DeleteOutlineIcon fontSize="large" />
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      {/* add row courses end  here  */}
    </>
  );
};

export default CollegeCourse;
