import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../general-components/ui-components/Header";
import Footer from "../../general-components/ui-components/Footer";
import BannerSlider from "./home-sections/BannerSlider";
import TopCollege from "./home-sections/TopCollege";
import ExamResultPage from "./home-sections/ExamResultPage";
import NewsBlog from "./home-sections/NewsBlog";
import SubscribeNewsLetter from "./home-sections/SubscribeNewsLetter";

const Home = () => {
  return (
    <>
      <Header />
      <BannerSlider />
      {/* <TopCollegeAdmission /> */}
      <TopCollege type="college" result={6} url="/colleges/college" />
      <TopCollege type="school" result={3} url="/schools/college" />
      <TopCollege
        type="training institute"
        result={3}
        url="/training-institutes/college"
      />
      <ExamResultPage />
      <NewsBlog />
      <SubscribeNewsLetter />
      <Footer />
    </>
  );
};

export default Home;
