import { React, useEffect } from "react";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import DashboardNav from "./DashboardNav";
import DashboardHeader from "./DashboardHeader";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../config/config";
import { getUser } from "../../../global/redux/action";

const DashboardAnalytics = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;
  const dispatch = useDispatch();
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  // useEffect(() => {
  //   if (!userData?.users.roles.includes("Admin")) {
  //     navigate("/");
  //   } else if (userData?.users.passwordChangeOnLogin === "Yes") {
  //     navigate("/setPassword");
  //     alert("Please Setup Your Password");
  //   } else {
  //     navigate("/");
  //   }
  // }, []);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />
          <Paper sx={{ mt: 3, p: 2 }}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Card>
                  <CardHeader>
                    <Typography variant="h6">Total Colleges</Typography>
                  </CardHeader>
                  <Grid container spacing={1}>
                    <Grid item sm={6}>
                      <Typography>856</Typography>
                      <Typography>Colleges</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      656
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card>
                  <CardHeader>
                    <Typography variant="h6">Total Colleges</Typography>
                  </CardHeader>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography>856</Typography>
                      <Typography>Colleges</Typography>
                    </Grid>
                    <Grid item>
                      <CircularProgress></CircularProgress>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card>
                  <CardHeader>
                    <Typography variant="h6">Total Colleges</Typography>
                  </CardHeader>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography>856</Typography>
                      <Typography>Colleges</Typography>
                    </Grid>
                    <Grid item>
                      <CircularProgress></CircularProgress>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              {/* <Grid item sm={12}>
                <Stack sx={{ width: "100%" }}>
                  <FormControlLabel
                    checked={connectNulls}
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setConnectNulls(event.target.checked)
                        }
                      />
                    }
                    label="connectNulls"
                    labelPlacement="end"
                  />
                  <LineChart
                    xAxis={[{ data: [2, 3, 5, 8, 10, 12, 15, 16, 18, 20] }]}
                    series={[
                      {
                        data: [5, 6.5, 3, 8, 10, 9.5, 2.5, 6, 10, 5],
                      },
                      {
                        data: [4, 4.5, 5.5, 4, 5.5, 4, 6, 4, 4, 10, 8],
                        connectNulls,
                        area: true,
                      },
                    ]}
                    height={200}
                    margin={{ top: 10, bottom: 20 }}
                  />
                </Stack>
              </Grid> */}
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default DashboardAnalytics;
