import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import TeamLeaderMappingTabs from "./TeamLeaderMappingTabs";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import { useState } from "react";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import DataTable from "react-data-table-component";

const TeamLeaderMappingList = () => {
  const [mappingData, setMappingData] = useState([]);
  const [mappingDataFilter, setMappingDataFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies();
  useEffect(() => {
    const getTeamLeaderMappingList = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getTeamLeaders,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setMappingData(response.data.collegeTeamLeaders);
          setMappingDataFilter(response.data.collegeTeamLeaders);
          setLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Mapping Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Mapping Details. Please try again later!!"
        );
      }
    };
    if (loading) {
      getTeamLeaderMappingList();
    }
  }, [cookies, loading]);

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Team Leader Name",
      selector: (row) => row.teamLeaderName,
      sortable: true,
    },
    {
      name: "Team Leader Name",
      selector: (row) => (
        <ul>
          {row.colleges.map((colleges) => (
            <li>{colleges.collegeName}</li>
          ))}
        </ul>
      ),
      sortable: true,
    },
  ];

  useEffect(() => {
    const result = mappingData.filter((item) => {
      return item.teamLeaderName
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setMappingDataFilter(result);
  }, [search]);

  return (
    <>
      <TeamLeaderMappingTabs tabActive={0}>
        <DataTable
          columns={columns}
          data={mappingDataFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={loading}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </TeamLeaderMappingTabs>
    </>
  );
};

export default TeamLeaderMappingList;
