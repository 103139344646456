import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  tabsClasses,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import SingleCollegeCard from "./SingleCollegeCard";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useNavigate } from "react-router-dom";

const TopCollege = ({ type, result, url }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [programId, setProgramId] = useState("");
  const [fetching, setFetching] = useState(false);
  const [collegeList, setCollegesList] = useState([]);
  const [streamsList, setStreamsList] = useState([]);
  const [programFetching, setProgramFetching] = useState(true);
  const [cookies] = useCookies();

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const selectedItemId = streamsList[newValue].id;
    setProgramId(selectedItemId);
    setFetching(true);
  };

  useEffect(() => {
    const getAllPrograms = async () => {
      let params = {
        status: "Active",
        instituteType: type,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setStreamsList(response.data.programs);
          setProgramFetching(false);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };
    // Set 'programId' to the ID of the first item in 'streamsList'
    if (streamsList?.length > 0) {
      setProgramId(streamsList[0].id);
      setFetching(true);
    }
    getAllPrograms();
  }, [cookies, programFetching]);

  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  //Fetching the data
  useEffect(() => {
    const getAllColleges = async () => {
      let params = {
        district: cookies[config.preferencesCookie]
          ? cookies[config.preferencesCookie]?.city
          : "bengaluru",
        status: "Active",
        programId: programId,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegesList(response.data.colleges);
          setFetching(false);
        } else {
          alert(
            "Something went wrong while getting the College Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the College Details. Please try again later!!"
        );
      }
    };

    if (fetching) {
      getAllColleges();
    }
  }, [fetching, programId]);

  return (
    <>
      {streamsList?.length > 0 && (
        <>
          <Box
            sx={{
              px: { md: "50px", xs: "auto" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "space-between" },
                m: 2,
              }}
            >
              <Box>
                <Typography sx={{ fontSize: { md: "25px", xs: "20px" } }}>
                  {type === "college"
                    ? `Top Colleges in ${
                        cookies[config.preferencesCookie]?.city || "Bengaluru"
                      }`
                    : type === "school"
                    ? `Top Schools in  ${
                        cookies[config.preferencesCookie]?.city || "Bengaluru"
                      }`
                    : type === "training institute"
                    ? `Top Training Institutes in  ${
                        cookies[config.preferencesCookie]?.city || "Bengaluru"
                      }`
                    : null}
                </Typography>
                <Typography
                  sx={{ fontSize: { md: "13px", xs: "15px" } }}
                  color={"#a3adb9"}
                >
                  {type === "College"
                    ? `Top Colleges in  ${
                        cookies[config.preferencesCookie]?.city || "Bengaluru"
                      }`
                    : type === "School"
                    ? `Top Schools in  ${
                        cookies[config.preferencesCookie]?.city || "Bengaluru"
                      }`
                    : type === "training institute"
                    ? `Top Training Institutes in  ${
                        cookies[config.preferencesCookie]?.city || "Bengaluru"
                      }`
                    : null}
                </Typography>
              </Box>
              {!isMd ? (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "25px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%),#162E7C",
                    color: "#fff",
                    textTransform: "none", // Preserve the case of the text
                    fontWeight: "bold", // Make the text bold
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
                    "&:hover": {
                      background:
                        "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%),linear-gradient(90deg, rgba(52, 68, 73, 0.30) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.30) 85.38%),#162E7C",
                    },
                  }}
                  onClick={() => {
                    navigate(url);
                  }}
                >
                  View All
                </Button>
              ) : null}
            </Box>

            <Tabs
              value={value}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons
              aria-label="Custom Tabs"
              indicatorColor="none"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              {streamsList?.map((item, index) => (
                <Tab
                  key={index}
                  label={
                    <Typography
                      sx={{
                        fontSize: { xs: "11.778px", md: "0.75rem" },
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        "::before": {
                          content: '""',
                          display: "inline-block",
                          width: "6px",
                          height: "6px",
                          borderRadius: "50%",
                          backgroundColor: "black",
                          marginRight: "8px",
                        },
                      }}
                    >
                      {item.programName}
                    </Typography>
                  }
                  sx={{
                    minWidth: "auto",
                    textTransform: "none",
                    fontWeight: "bold",
                    mx: 1,
                    color: value === index ? "black" : "gray",
                    px: "9px",
                    border:
                      value === index ? "1px solid black" : "1px solid gray",
                    borderRadius: "15px",
                    paddingTop: 0, // Adjust the top padding as needed
                    paddingBottom: 0, // Adjust the bottom padding as needed
                  }}
                />
              ))}
            </Tabs>
            <TabPanel value={value} index={value}>
              <Box
                sx={{
                  position: "relative",
                  backgroundColor: "#f5f5f5", // Light grey background color
                  overflow: "hidden", // Hide content that overflows the box
                  borderRadius: "20px", // All corners are rounded
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                  >
                    <path
                      fill="#fff"
                      fillOpacity="1"
                      d="M0,0L48,21.3C96,43,192,85,288,117.3C384,149,480,171,576,181.3C672,192,768,192,864,192C960,192,1056,192,1152,165.3C1248,139,1344,85,1392,58.7L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
                    ></path>
                  </svg>
                </Box>
                <Grid
                  container
                  spacing={2}
                  py={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    overflowX: "auto", // Enable horizontal scrolling
                    flexWrap: { md: "wrap", xs: "nowrap" }, // Do not allow items to wrap
                    "&::-webkit-scrollbar": {
                      height: "5px ", // Adjust the width of the scrollbar
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#405189;", // Set the color of the scrollbar thumb
                      borderRadius: "8px", // Round the corners of the thumb
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
                      borderRadius: "10px", // Round the corners of the track
                    },
                  }}
                >
                  {/* under this code is saved in task-27 */}
                  {collegeList.slice(0, result).map((colleges) => (
                    <Grid
                      item
                      key={colleges.id}
                      xs={12}
                      sm={6}
                      lg={4}
                      sx={{ zIndex: 3 }}
                    >
                      <SingleCollegeCard {...colleges} />
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                  >
                    <path
                      fill="#fff"
                      fillOpacity="1"
                      d="M0,256L80,224C160,192,320,128,480,80C640,32,800,0,960,21.3C1120,43,1280,117,1360,154.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                    ></path>
                  </svg>
                </Box>
              </Box>
            </TabPanel>

            {isMd ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}
              >
                <Button variant="outlined">View All</Button>
              </Box>
            ) : null}
          </Box>

          <Divider
            sx={{
              borderTop: "2px solid #ccc",
              my: 4,
              color: "#95959B",
              width: "80%",
              mx: "auto", // Set margin-left and margin-right to "auto"
              textAlign: "center",
            }}
          />
        </>
      )}
    </>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export default TopCollege;
