import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/main/home/Home";
import CollegesList from "./components/main/colleges/CollegesList";
import CollegeAdd from "./components/main/colleges/CollegeAdd";
import Logout from "./components/auth/logout/Logout";
import CollegeEdit from "./components/main/colleges/CollegeEdit";
import { Provider } from "react-redux";
import store from "./global/redux/store";
import DashboardAnalytics from "./components/main/home/DashboardAnalytics";
import LeadsList from "./components/main/leads/LeadsList";
import AddBlog from "./components/main/blog/AddBlog";
import CoursesList from "./components/main/colleges/CoursesList";
import UniversitiesList from "./components/main/colleges/UniversitiesList";
import AmenitiesList from "./components/main/colleges/AmenitiesList";
import PlacementCompanyList from "./components/main/colleges/PlacementCompanyList";
import InstituteTypeList from "./components/main/colleges/InstituteTypeList";
import InstituteBrandList from "./components/main/colleges/InstituteBrandList";
import ScholarshipList from "./components/main/colleges/ScholarshipList";
import ProgramList from "./components/main/colleges/ProgramList";
import ExamsList from "./components/main/colleges/ExamsList";
import SpecializationList from "./components/main/colleges/SpecializationList";
import ChangePassword from "./components/main/colleges/ChangePassword";
import TeamLeaderMappingList from "./components/main/team-leader-mapping/TeamLeaderMappingList";
import TeamLeaderMappingAdd from "./components/main/team-leader-mapping/TeamLeaderMappingAdd";
import TeamLeaderMappingView from "./components/main/team-leader-mapping/TeamLeaderMappingView";
import ProtectedRoute from "./components/general-components/functional-components/ProtectedRoute";
import PageNotFound from "./components/main/static-pages/PageNotFound";
import ReviewList from "./components/main/home/reviews/ReviewList";
import { CookiesProvider } from "react-cookie";
import MarketingExecutiveMappingList from "./components/main/marketing-executive-mapping/MarketingExecutiveMappingList";
import MarketingExecutiveMappingAdd from "./components/main/marketing-executive-mapping/MarketingExecutiveMappingAdd";
import MarketingExecutiveMappingView from "./components/main/marketing-executive-mapping/MarketingExecutiveMappingView";
import UsersList from "./components/main/users/UsersList";
import UserAdd from "./components/main/users/UserAdd";
import Admission from "./components/main/home/college-details/Admission";
import Info from "./components/main/home/college-details/Info";
import CourseFee from "./components/main/home/college-details/CourseFee";
import Gallery from "./components/main/home/college-details/Gallery";
import Placement from "./components/main/home/college-details/Placement";
import Reviews from "./components/main/home/college-details/Reviews";
import Results from "./components/main/home/college-details/Results";
import QuestionAnswer from "./components/main/home/college-details/QuestionAnswer";
import Scholarship from "./components/main/home/college-details/Scholarship";
import Facility from "./components/main/home/college-details/Facility";
import Cutoff from "./components/main/home/college-details/Cutoff";
import ContactUs from "./components/main/static-pages/ContactUs";
import ManageBlogs from "./components/main/blog/ManageBlogs";
import EditBlog from "./components/main/blog/EditBlog";
import ManageBlogCategories from "./components/main/blog/ManageBlogCategories";
import ReviewBlog from "./components/main/blog/ReviewBlog";
import BlogsList from "./components/main/blog/BlogsList";
import ViewBlog from "./components/main/blog/ViewBlog";
import Colleges from "./components/main/home/college-list/Colleges";
import AboutUs from "./components/main/static-pages/AboutUs";
import { Box, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEffect, useState } from "react";
import AddSchool from "./components/main/school/AddSchool";
import SchoolList from "./components/main/school/SchoolList";
import TrainingInstitute from "./components/main/training-instiute/TrainingInstitute";
import TrainingInstiuteList from "./components/main/training-instiute/TrainingInstiuteList";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002147",
    },
  },
  typography: {
    fontFamily: "Open Sans",
    fontWeightRegular: "600",
  },
});

function App() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // Change 100 to the desired scroll threshold
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <CookiesProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/college-list"
                  element={
                    <ProtectedRoute>
                      <CollegesList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-college"
                  element={
                    <ProtectedRoute>
                      <CollegeAdd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <DashboardAnalytics />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/course"
                  element={
                    <ProtectedRoute>
                      <CoursesList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/exam"
                  element={
                    <ProtectedRoute>
                      <ExamsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/university"
                  element={
                    <ProtectedRoute>
                      <UniversitiesList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/amenities"
                  element={
                    <ProtectedRoute>
                      <AmenitiesList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/placement-companies"
                  element={
                    <ProtectedRoute>
                      <PlacementCompanyList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/institute-types"
                  element={
                    <ProtectedRoute>
                      <InstituteTypeList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/institute-brands"
                  element={
                    <ProtectedRoute>
                      <InstituteBrandList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/scholarships"
                  element={
                    <ProtectedRoute>
                      <ScholarshipList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/program"
                  element={
                    <ProtectedRoute>
                      <ProgramList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/specialization"
                  element={
                    <ProtectedRoute>
                      <SpecializationList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-leads"
                  element={
                    <ProtectedRoute>
                      <LeadsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-blog"
                  element={
                    <ProtectedRoute>
                      <AddBlog />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/team-leader-mapping-list"
                  element={
                    <ProtectedRoute>
                      <TeamLeaderMappingList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/team-leader-mapping-add"
                  element={
                    <ProtectedRoute>
                      <TeamLeaderMappingAdd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/team-leader-mapping-view"
                  element={
                    <ProtectedRoute>
                      <TeamLeaderMappingView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/marketing-executive-mapping-list"
                  element={
                    <ProtectedRoute>
                      <MarketingExecutiveMappingList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/marketing-executive-mapping-add"
                  element={
                    <ProtectedRoute>
                      <MarketingExecutiveMappingAdd />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-school"
                  element={
                    <ProtectedRoute>
                      <AddSchool />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/school-list"
                  element={
                    <ProtectedRoute>
                      <SchoolList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/add-training-institute"
                  element={
                    <ProtectedRoute>
                      <TrainingInstitute />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/training-institute-list"
                  element={
                    <ProtectedRoute>
                      <TrainingInstiuteList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/marketing-executive-mapping-view"
                  element={
                    <ProtectedRoute>
                      <MarketingExecutiveMappingView />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-list"
                  element={
                    <ProtectedRoute>
                      <UsersList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/user-Add"
                  element={
                    <ProtectedRoute>
                      <UserAdd />
                    </ProtectedRoute>
                  }
                />
                <Route path="/logout" element={<Logout />} />
                <Route
                  path="/EditCollege/:id"
                  element={
                    <ProtectedRoute>
                      <CollegeEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/setPassword"
                  element={
                    <ProtectedRoute>
                      <ChangePassword />
                    </ProtectedRoute>
                  }
                />

                {/* Blogs */}
                <Route
                  path="/manage-blogs"
                  element={
                    <ProtectedRoute>
                      <ManageBlogs />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reviews"
                  element={
                    <ProtectedRoute>
                      <ReviewList />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/edit-blog"
                  element={
                    <ProtectedRoute>
                      <EditBlog />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/manage-blog-categories"
                  element={
                    <ProtectedRoute>
                      <ManageBlogCategories />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/review-blog/:id"
                  element={
                    <ProtectedRoute>
                      <ReviewBlog />
                    </ProtectedRoute>
                  }
                />
                <Route path="/blogs" element={<BlogsList />} />

                <Route
                  path="/blogs/:categoryname/:id"
                  element={<BlogsList />}
                />
                <Route
                  path="/blogs/:category/:subcategory/:subid"
                  element={<BlogsList />}
                />
                <Route path="/blog/:slug/:id" element={<ViewBlog />} />

                {/* home college details route  */}
                <Route
                  path="/institute/:slugUrl/info/:collegeId"
                  element={<Info />}
                />
                <Route
                  path="/institute/:slugUrl/courseandfee/:collegeId"
                  element={<CourseFee />}
                />
                <Route
                  path="/institute/:slugUrl/admission/:collegeId"
                  element={<Admission />}
                />
                <Route
                  path="/institute/:slugUrl/cutoff/:collegeId"
                  element={<Cutoff />}
                />
                <Route
                  path="/institute/:slugUrl/facility/:collegeId"
                  element={<Facility />}
                />
                <Route
                  path="/institute/:slugUrl/gallery/:collegeId"
                  element={<Gallery />}
                />
                <Route
                  path="/institute/:slugUrl/placement/:collegeId"
                  element={<Placement />}
                />
                <Route
                  path="/institute/:slugUrl/questionandanswer/:collegeId"
                  element={<QuestionAnswer />}
                />
                <Route
                  path="/institute/:slugUrl/results/:collegeId"
                  element={<Results />}
                />
                <Route
                  path="/institute/:slugUrl/reviews/:collegeId"
                  element={<Reviews />}
                />
                <Route
                  path="/institute/:slugUrl/scholarship/:collegeId"
                  element={<Scholarship />}
                />

                <Route path="*" element={<PageNotFound />} />
                {/* <Route path="/Quill" element={<TextEditor />} /> */}
                <Route path="/contact-us" element={<ContactUs />} />

                <Route path="/colleges/:type" element={<Colleges />} />
                <Route path="/schools/:type" element={<Colleges />} />
                <Route
                  path="/training-institutes/:type"
                  element={<Colleges />}
                />

                <Route path="/about-us" element={<AboutUs />} />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </Provider>
      </CookiesProvider>
      {showScrollButton && (
        <Box
          sx={{
            position: "fixed",
            bottom: showScrollButton ? "20px" : "-100px",
            right: "0px",
            zIndex: "999",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%),linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%),#162E7C",
            padding: "0px 11px",
            borderRadius: "5px 0px 0px 5px",
            transition: "bottom 0.3s ease-in-out, transform 0.3s ease-in-out", // Adding transition
            opacity: 0.9, // Adding opacity to the background
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
            transform: "translateY(0)", // Initial transform
            "&:hover": {
              transform: "translateY(-4px)", // Transform on hover
            },
          }}
        >
          <IconButton onClick={scrollToTop}>
            <ArrowUpwardIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      )}
    </>
  );
}

export default App;
