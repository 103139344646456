import { Typography, Button, useMediaQuery, Box, Stack } from "@mui/material";
import React from "react";

const style = {
  paddingLeft: "8px",
  paddingRight: "8px",
  marginLeft: "8px",
  borderRadius: "38px",
  backgroundColor: "#BBC4C2",
  color: "#5B5B5B",
};
const DateStyle = {
  display: "flex",
  fontSize: "13px",
  color: "#5B5B5B",
};

const HidStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  color: "#405189",
  fontSize: "20px",
};
const Hid = { overflow: "hidden" };

const CourseData = () => {
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  return (
    <>
      <Box
        sx={{ paddingLeft: "30px", paddingRight: "25px", marginTop: "20px" }}
      >
        <Typography sx={Hid} style={HidStyle}>
          M.Tech Placement on Going Higher
        </Typography>
        <Typography variant="body2" color={"#5B5B5B"}>
          M.tech Placement on Going HigherDegree placenemet is on going Higher
          M.tech Engineering Placement on going Higher M.tech Deegree Placement
          on Going HigherDegree placenemet is on going Higher M.tech Engineering
          Placement on going Higher M.tech
        </Typography>
        <Box paddingTop={"10px"} sx={DateStyle}>
          <Typography>26 January 2023</Typography>
          <Typography marginLeft={"20px"} sx={style}>
            M.Tech
          </Typography>
        </Box>
        <br />
        <Box>
          <Typography sx={Hid} style={HidStyle}>
            M.Tech Placement on Going Higher
          </Typography>
          <Typography variant="body2" color={"#5B5B5B"}>
            M.tech Placement on Going HigherDegree placenemet is on going Higher
            M.tech Engineering Placement on going Higher M.tech Deegree
            Placement on Going HigherDegree placenemet is on going Higher M.tech
            Engineering Placement on going Higher M.tech
          </Typography>
        </Box>
        <Box paddingTop={"10px"} sx={DateStyle}>
          <Typography>26 January 2023</Typography>
          <Typography marginLeft={"20px"} sx={style}>
            M.Tech
          </Typography>
        </Box>
        <br />
        <Box>
          <Typography sx={Hid} style={HidStyle}>
            M.Tech Placement on Going Higher
          </Typography>
          <Typography variant="body2" color={"#5B5B5B"}>
            M.tech Placement on Going HigherDegree placenemet is on going Higher
            M.tech Engineering Placement on going Higher M.tech Deegree
            Placement on Going HigherDegree placenemet is on going Higher M.tech
            Engineering Placement on going Higher M.tech
          </Typography>
        </Box>
        <Box paddingTop={"10px"} sx={DateStyle}>
          <Typography>26 January 2023</Typography>
          <Typography marginLeft={"20px"} sx={style}>
            M.Tech
          </Typography>
        </Box>
        <br />
      </Box>
      {isSmallScreen ? (
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #5B5B5B ",
              borderRadius: "50px",
              color: "#5B5B5B",
              width: "100px",
            }}
          >
            View all
          </Button>
        </Stack>
      ) : (
        <Box sx={{ paddingLeft: "20px" }}>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #5B5B5B ",
              borderRadius: "50px",
              color: "#5B5B5B",
              width: "100px",
            }}
          >
            View all
          </Button>
        </Box>
      )}
    </>
  );
};

export default CourseData;
