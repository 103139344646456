import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";

const Scholarship = () => {
  const [college, setCollege] = useState([]);
  return (
    <>
      <CollegeDetailHeader activeTab={5} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item md={8}>
            <Paper sx={{ p: 3 }}>
              {college.courses?.scholarshipDetails.length > 0
                ? college.courses?.map((item) => (
                    <>
                      <Typography
                        sx={{ fontSize: { xs: "14px", md: "18px" } }}
                        variant="subtitle1"
                      >
                        {item.courseName} Table
                      </Typography>

                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Scholarship Name</TableCell>
                            <TableCell>Scholarship Amount</TableCell>
                            <TableCell>Exam</TableCell>
                            <TableCell>Eligibility</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.scholarshipDetails?.map((item) => (
                            <TableRow>
                              <TableRow>
                                <TableCell>{item.scholarshipName}</TableCell>
                                <TableCell>{item.scholarshipAmount}</TableCell>
                                <TableCell>{item.examName}</TableCell>
                                <TableCell>{item.eligibility}</TableCell>
                              </TableRow>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </>
                  ))
                : null}
              {college.scholarshipSummary ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: { xs: "14px", md: "18px" },
                    }}
                    variant="subtitle1"
                  >
                    {college.collegeName} Scholarships:
                  </Typography>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: college.scholarshipSummary,
                    }}
                    sx={{ my: 2 }}
                  />
                  <br />
                </>
              ) : null}
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Scholarship;
