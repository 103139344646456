import React from "react";
import SchoolTabs from "./SchoolTabs";

const SchoolList = () => {
  return (
    <>
      <SchoolTabs tabActive={1}>
        <h1>SchoolList</h1>
      </SchoolTabs>
    </>
  );
};

export default SchoolList;
