import React, { useEffect, useState } from "react";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DashboardNav from "./../DashboardNav";
import DashboardHeader from "./../DashboardHeader";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DataTable from "react-data-table-component";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../global/redux/action";
import { toast } from "react-toastify";

const ReviewList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;
  const [fetching, setFetching] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [search, setSearch] = useState("");
  const [reviewsList, setReviewsList] = useState([]);
  const [filterReviewsList, setFilteredReviewsList] = useState([]);

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  useEffect(() => {
    const getAllReviews = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getReviews,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setReviewsList(response.data.collegeReviews);
          setFilteredReviewsList(response.data.collegeReviews);
          setFetching(false);
        } else {
          alert(
            "Something went wrong while getting the Reviews Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Reviews Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getAllReviews();
    }
  }, [cookies]);

  const getUserDetails = async (userId) => {
    let params = {
      status: "Active",
      id: userId,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.getUser,
      params,
      cookies
    );
    if (response?.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        setUserDetails(response.data.users);
        setUserDetailModal(true);
      } else {
        alert(
          "Something went wrong while getting the User Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while getting the User Details. Please try again later!!"
      );
    }
  };
  const updateReview = async (id, type) => {
    let params = {
      id: id,
      approvalStatus: type,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateReview,
      params,
      cookies
    );
    if (response?.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success(`${type} Successfully!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setFetching(true);
      } else {
        alert(
          "Something went wrong while getting the Update Review Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while getting the Update Review Details. Please try again later!!"
      );
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      sortable: true,
    },
    {
      name: "Rating Category",
      selector: (row) => row.ratingCategory,
      sortable: true,
    },
    {
      name: "review",
      selector: (row) => row.review,
      sortable: true,
    },
    {
      name: "Id Card",
      cell: (row) => (
        <IconButton
          size="small"
          color="success"
          onClick={() => {
            getUserDetails(row.userId);
          }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <Button
            size="small"
            color="success"
            onClick={() => {
              updateReview(row.id, "approved");
            }}
          >
            Approve
          </Button>
          <Button
            size="small"
            sx={{ color: "red" }}
            onClick={() => {
              updateReview(row.id, "rejected");
            }}
          >
            Reject
          </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    const result = reviewsList.filter((item) => {
      return item.ratingCategory
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setFilteredReviewsList(result);
  }, [search]);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />
          <DataTable
            columns={columns}
            data={filterReviewsList}
            pagination
            fixedHeader
            highlightOnHover
            subHeaderAlign="left"
            progressPending={fetching}
            subHeader
            subHeaderComponent={
              <TextField
                type="text"
                size="small"
                placeholder="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ my: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: 25,
                    border: "none",
                    outline: "none",
                    backgroundColor: "#fff",
                  }, // Adjust the border radius as needed
                }}
              />
            }
          ></DataTable>
        </Box>
      </Box>
      <Dialog
        onClose={() => setUserDetailModal(false)}
        aria-labelledby="customized-dialog-title"
        open={userDetailModal}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Student Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setUserDetailModal(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TableContainer>
            <Table>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{userDetails?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mobile Number</TableCell>
                <TableCell>{userDetails?.phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{userDetails?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  ID Card
                </TableCell>
              </TableRow>
              <TableRow></TableRow>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReviewList;
