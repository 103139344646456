import React, { useState } from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  apiList,
  invokeApi,
  invokeFormDataApi,
} from "../../../../services/apiServices";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import Login from "../../../auth/login/Login";
import SignUp from "../../../auth/signup/SignUp";

const Reviews = () => {
  const [cookies] = useCookies();
  const [loginModal, setLoginModal] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [signupModal, setSignUpModal] = useState(false);
  const [college, setCollege] = useState({});
  const { collegeId } = useParams();
  const [review, setReview] = useState("");
  const [reviewError, setReviewError] = useState(false);
  const [reviewHelperText, setReviewHelperText] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState(null);
  const [maxFileSizeErr, setMaxFileSizeErr] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileHelperText, setFileHelperText] = useState("");

  // rating states
  const [infrastructureRating, setInfrastructureRating] = useState(4);
  const [hostalRating, setHostalRating] = useState(4);
  const [facultyRating, setFacultyRating] = useState(4);
  const [placementRating, setplacementRating] = useState(4);

  const handleIsLoginModal = () => {
    setLoginModal((modal) => !modal);
  };

  const handleIsSignUpModal = () => {
    setSignUpModal((modal) => !modal);
  };

  const handleFileUpload = (ev) => {
    ev.preventDefault();
    const fileUploaded = ev.target.files[0];
    let acceptProfileFileTypes = fileUploaded.type.match(
      /^image\/(jpe?g|png|gif)/
    );

    if (fileUploaded && acceptProfileFileTypes) {
      if (fileUploaded.size < 512 * 512) {
        setUploadedFile(fileUploaded);
        setPreviewFile(URL.createObjectURL(fileUploaded));
        setMaxFileSizeErr("");
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 1024 * 1024;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], fileUploaded.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });

                setUploadedFile(compressedFile);
                setPreviewFile(URL.createObjectURL(compressedFile));
                setMaxFileSizeErr("");
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(fileUploaded);
      }
    } else {
      setMaxFileSizeErr(
        "Please upload a valid image in jpeg/jpg/png/gif format"
      );
    }
  };

  const handleUploadFile = async (ev) => {
    ev.preventDefault();
    setReviewLoading(true);
    var validation = true;
    if (!uploadedFile) {
      setFileError(true);
      setFileHelperText("Please Upload Id Card Image");
      setReviewLoading(false);
      validation = false;
    }
    if (!review) {
      setReviewError(true);
      setReviewHelperText("Please Enter Review");
      validation = false;
      setReviewLoading(false);
    }
    if (!cookies[config.cookieName] && review) {
      handleIsLoginModal();
      validation = false;
      setReviewLoading(false);
    }
    // upload the profile pic first, if exist
    if (uploadedFile && validation) {
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "Id_card");

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          saveReview(response.data.url);
        } else {
          alert(
            "Something went wrong while uploading Amenity picture. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while uploading Amenity picture. Please try again later!!"
        );
      }
    }
  };

  const saveReview = async (url) => {
    let params = {
      collegeId: collegeId,
      review: review,
      idCardPath: url,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.addReview,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Review Added SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setReviewLoading(false);
        setMaxFileSizeErr("");
        setFileHelperText("");
        setFileError(false);
        setPreviewFile(null);
        setUploadedFile(null);
        setReviewHelperText("");
        setReviewError(false);
        setReview("");
      } else {
        alert(
          "Something went wrong while saving the Review Details. Please try again later!"
        );
        setReviewLoading(false);
      }
    } else {
      alert(
        "Something went wrong while saving the Review Details. Please try again later!!"
      );
      setReviewLoading(false);
    }
  };
  
  return (
    <>
      <CollegeDetailHeader activeTab={9} transferCollegeData={setCollege}>
        <form onSubmit={handleUploadFile}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Typography
                  sx={{
                    backgroundColor: "#F2F2F2",
                    p: 1,
                    fontWeight: "bold",
                    color: "#000",
                  }}
                  variant="subtitle1"
                >
                  Reviews :
                </Typography>
                <Grid container>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: { sm: "row", xs: "column" },
                    }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h6"
                        sx={{ color: "#000", fontWeight: "bold" }}
                      >
                        Overall Rating
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{ color: "#5B5B5B" }}
                        >
                          (Out of 5)
                        </Typography>
                      </Typography>
                      <Typography
                        variant="h3"
                        textAlign="center"
                        sx={{ color: "#000", fontWeight: "bold" }}
                      >
                        5.0
                      </Typography>
                      <Rating name="read-only" value={5} readOnly />
                      <br />
                      <Typography
                        variant="caption"
                        sx={{ color: "#5B5B5B", textAlign: "center" }}
                      >
                        Based on Verified Reviews
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{ color: "#000", fontWeight: "bold" }}
                      >
                        Ratings Graph
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{ color: "gray" }}
                        >
                          (Out of 5)
                        </Typography>
                      </Typography>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                          5
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          sx={{
                            flex: 1,
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: "rgba(255, 223, 0, 0.2)", // Gold color with opacity
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FFDF00", // Gold color
                            },
                          }}
                        />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginLeft: 1 }}
                        >
                          {`${Math.round(100)}%`}
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                          4
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={0}
                          sx={{
                            flex: 1,
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: "rgba(255, 223, 0, 0.2)", // Gold color with opacity
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FFDF00", // Gold color
                            },
                          }}
                        />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginLeft: 1 }}
                        >
                          {`${Math.round(0)}%`}
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                          3
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={0}
                          sx={{
                            flex: 1,
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: "rgba(255, 223, 0, 0.2)", // Gold color with opacity
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FFDF00", // Gold color
                            },
                          }}
                        />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginLeft: 1 }}
                        >
                          {`${Math.round(0)}%`}
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                          2
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={0}
                          sx={{
                            flex: 1,
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: "rgba(255, 223, 0, 0.2)", // Gold color with opacity
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FFDF00", // Gold color
                            },
                          }}
                        />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginLeft: 1 }}
                        >
                          {`${Math.round(0)}%`}
                        </Typography>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                          1
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={0}
                          sx={{
                            flex: 1,
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: "rgba(255, 223, 0, 0.2)", // Gold color with opacity
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FFDF00", // Gold color
                            },
                          }}
                        />
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ marginLeft: 1 }}
                        >
                          {`${Math.round(0)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12} sx={{ textAlign: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{ color: "#000", fontWeight: "bold", mb: "10px" }}
                    >
                      Categories Ratings
                      <Typography
                        component="span"
                        variant="h6"
                        sx={{ color: "#5B5B5B" }}
                      >
                        (Out of 5)
                      </Typography>
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "10px",
                          }}
                        >
                          <Typography>Placements</Typography>
                          <Rating
                            name="read-only"
                            value={5}
                            readOnly
                            sx={{ fontSize: "15px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "10px",
                          }}
                        >
                          <Typography>Infrastructure</Typography>
                          <Rating
                            name="read-only"
                            value={5}
                            readOnly
                            sx={{ fontSize: "15px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "10px",
                          }}
                        >
                          <Typography>Facility</Typography>
                          <Rating
                            name="read-only"
                            value={5}
                            readOnly
                            sx={{ fontSize: "15px" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "10px",
                          }}
                        >
                          <Typography>Campus</Typography>
                          <Rating
                            name="read-only"
                            value={5}
                            readOnly
                            sx={{ fontSize: "15px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "10px",
                          }}
                        >
                          <Typography>Courses</Typography>
                          <Rating
                            name="read-only"
                            value={5}
                            readOnly
                            sx={{ fontSize: "15px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            mb: "10px",
                          }}
                        >
                          <Typography>Value for Money</Typography>
                          <Rating
                            name="read-only"
                            value={5}
                            readOnly
                            sx={{ fontSize: "15px" }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: "#5B5B5B" }} />
                <Box sx={{ py: 2 }}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="row"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      viewBox="0 0 55 55"
                      fill="none"
                      style={{ margin: "10px" }}
                    >
                      <circle cx="27.5" cy="27.5" r="27.5" fill="#D9D9D9" />
                    </svg>
                    <Box
                      sx={{
                        ml: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { sm: "row", xs: "column" },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontFamily: "Open Sans",
                            fontSize: { sm: "20px", xs: "13px" },
                            fontWeight: "600",
                          }}
                        >
                          Mainak Ray |
                        </Typography>
                        <Typography
                          sx={{
                            color: "#282828",
                            fontFamily: "Open Sans",
                            fontSize: { sm: "15px", xs: "10px" },
                            fontWeight: "400",
                          }}
                        >
                          Post Graduate Diploma in Management (PGDM) - Batch of
                          2025
                        </Typography>
                      </Box>
                      {/* date  */}
                      <Typography
                        style={{
                          color: "#5B5B5B",
                          fontSize: "13px",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          fontWeight: "400",
                        }}
                      >
                        Reviewed on 28 Oct 2023
                      </Typography>
                      {/* rating  */}
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ margin: "2px" }}>5.0</Typography>
                        <Rating disabled size={"small"} value={5} />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontStyle: "normal",
                        lineHeight: "29.5px",
                        fontSize: "13px",
                      }}
                    >
                      Best business college in Karnataka.
                    </Typography>
                    <Box
                      style={{
                        color: "black",
                        lineHeight: "17.5px",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                        Placements: I feel proud that I am studying at this
                        college. GIBS is one of the best colleges for
                        placements. The placement support starts from the start
                        of our course. Regular workshops, practical classes, and
                        boost camps are organized to make us good for
                        placements. The companies offer very attractive packages
                        and the highest package is 18 LPA. Some of our seniors
                        have got internships also. After the internship, they
                        can join the company or opt out of other opportunities.
                        All the companies that come here for placements are
                        genuine. They will not remove you if you are good at
                        your work.
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                        Infrastructure: The college infrastructure is good but
                        there is no Wi-Fi facility on the campus. Outside our
                        syllabus, our college also organizes some
                        extracurricular activities such as Yoga Classes, and
                        various club activities. There are separate auditoriums,
                        and seminar halls, for every department. I have stayed
                        in the college hostel since my first year. The roommates
                        are very good and friendly.
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "400" }}>
                        Faculty: The best academic teachers and also some
                        corporate persons are faculty here. Their teaching
                        method is very good and they share very important
                        experiences. The course curriculum is advanced compared
                        to other B Schools in Karnataka. Exams are taken as a
                        normal schedule. The best thing is our college does not
                        complete a semester in a hurry. They give proper time.
                      </Typography>
                    </Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="90%"
                      height="1"
                      viewBox="0 0 1322 1"
                      fill="none"
                    >
                      <path
                        d="M0.6875 0.651367L1321.23 0.651252"
                        stroke="black"
                        stroke-width="0.5"
                        stroke-dasharray="1 1"
                      />
                    </svg>
                  </Box>
                </Box>
                {/* read more button  */}
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    height={30}
                    gap={2}
                    justifyContent="center"
                    bgcolor="#f0f0f0"
                    border="1px solid #405189"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 25"
                      fill="none"
                      color="#405189"
                    >
                      <path
                        d="M9.517 13.8927L12 12.3867L14.483 13.8927L13.823 11.0677L16.019 9.18273L13.133 8.92773L12 6.27773L10.867 8.92773L7.981 9.18273L10.177 11.0677L9.517 13.8927ZM3 20.2967V4.83473C3 4.37473 3.15433 3.99073 3.463 3.68273C3.771 3.37406 4.155 3.21973 4.615 3.21973H19.385C19.845 3.21973 20.229 3.37406 20.537 3.68273C20.8457 3.99073 21 4.37473 21 4.83473V15.6047C21 16.0647 20.846 16.4491 20.538 16.7577C20.2293 17.0657 19.845 17.2197 19.385 17.2197H6.077L3 20.2967ZM5.65 16.2197H19.385C19.5383 16.2197 19.6793 16.1557 19.808 16.0277C19.936 15.8991 20 15.7581 20 15.6047V4.83473C20 4.68139 19.936 4.54039 19.808 4.41173C19.6793 4.28373 19.5383 4.21973 19.385 4.21973H4.615C4.46167 4.21973 4.32067 4.28373 4.192 4.41173C4.064 4.54039 4 4.68139 4 4.83473V17.8647L5.65 16.2197Z"
                        fill="#405189"
                      />
                    </svg>
                    <Typography
                      variant="body1"
                      color="#405189"
                      marginRight={1}
                      sx={{ textAlign: "center", border: "#405189" }}
                    >
                      Read More Reviews
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ background: "white", borderRadius: "15px", p: 3, mt: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  backgroundColor: "#F2F2F2",
                  p: 1,
                  fontWeight: "bold",
                  color: "#000",
                }}
                variant="subtitle1"
              >
                Write Reviews :
              </Typography>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="body1">Infrastructure :</Typography>
                <Rating
                  name="size-large"
                  value={infrastructureRating}
                  onChange={(e) => setInfrastructureRating(e.target.value)}
                  size="large"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="body1">Hostal :</Typography>
                <Rating
                  name="size-large"
                  value={hostalRating}
                  onChange={(e) => setHostalRating(e.target.value)}
                  size="large"
                />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="body1">Faculty :</Typography>
                <Rating
                  name="size-large"
                  value={facultyRating}
                  onChange={(e) => setFacultyRating(e.target.value)}
                  size="large"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography variant="body1">Placement:</Typography>
                <Rating
                  name="size-large"
                  value={placementRating}
                  onChange={(e) => setplacementRating(e.target.value)}
                  size="large"
                />
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <textarea
                  rows={7}
                  style={{
                    width: "80%",
                    padding: "20px",
                    fontSize: "15px",
                    borderColor: reviewError ? "red" : undefined,
                  }}
                  placeholder="Enter Your Review..........."
                  value={review}
                  onChange={(e) => {
                    setReview(e.target.value);
                    setReviewError(false);
                    setReviewHelperText("");
                  }}
                ></textarea>
                {reviewError ? (
                  <Typography sx={{ color: "red", fontSize: "0.75rem" }}>
                    {reviewHelperText}
                  </Typography>
                ) : null}
              </Box>
              {cookies[config.cookieName]?.token &&
              cookies[config.cookieName]?.loginUserId ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="profile-pic"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                  />

                  {uploadedFile && !maxFileSizeErr ? (
                    <label htmlFor="profile-pic">
                      <IconButton component="span">
                        <Box
                          component="img"
                          src={previewFile}
                          alt="profile"
                          sx={{
                            width: 100,
                            height: 100,
                            borderRadius: "50%",
                            objectFit: "cover",
                            boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                          }}
                        />
                      </IconButton>
                    </label>
                  ) : (
                    <>
                      <label htmlFor="profile-pic">
                        <IconButton component="span">
                          <Avatar
                            sx={{
                              width: 100,
                              height: 100,
                              background: "#FFFFFF",
                              boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                            }}
                          >
                            <Box
                              component={"img"}
                              src="/media/svg/noun-identity-card-592954.svg"
                              sx={{ width: 56, height: 56 }}
                              alt="profile"
                            />
                          </Avatar>
                        </IconButton>
                      </label>
                      <Typography variant="bodyregular" color={"error"}>
                        {maxFileSizeErr}
                        {fileError ? (
                          <Tooltip
                            title="Your Card is for Verification only we won't share your id card any where"
                            arrow
                          >
                            <IconButton>
                              <HelpOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Typography>
                    </>
                  )}
                  {fileError ? (
                    <Typography variant="bodyparagraph" sx={{ color: "red" }}>
                      {fileHelperText} *
                    </Typography>
                  ) : (
                    <Typography variant="bodyparagraph">
                      Upload Id Card Image{" "}
                      <Tooltip
                        title="Your Card is for Verification only we won't share your id card any where"
                        arrow
                      >
                        <IconButton>
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  )}
                </Box>
              ) : null}
              <Button
                variant="contained"
                type="submit"
                sx={{
                  color: "white", // Text color
                  borderRadius: "25px", // Border radius
                  padding: "10px 20px", // Padding
                  fontSize: "16px", // Font size
                  cursor: "pointer", // Cursor style
                }}
                disabled={reviewLoading}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </CollegeDetailHeader>
      <Login
        close={handleIsLoginModal}
        open={loginModal}
        signUpOpen={handleIsSignUpModal}
      />
      <SignUp
        close={handleIsSignUpModal}
        open={signupModal}
        loginOpen={handleIsLoginModal}
      />
    </>
  );
};

export default Reviews;
