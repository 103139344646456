import React, { useState } from "react";
import { Typography, TextField, Button, Box, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useCookies } from "react-cookie";
import { emailValidation, mobileNoValidation } from "../../../../common/common";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { Bounce, toast } from "react-toastify";

const SubscribeNewsLetter = () => {
  const [cookies] = useCookies();
  const [email, setEmail] = useState("");
  // const [mobileNumber, setMobileNumber] = useState("");
  // const mobileValidate = mobileNoValidation(mobileNumber);
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  // const [phoneError, setPhoneError] = useState(false);
  // const [phoneHelperText, setPhoneHelperText] = useState("");
  const emailValidate = emailValidation(email);
  // const [course, setCourse] = useState("");

  // const handleChange = (event) => {
  //   setCourse(event.target.value);
  // };
  const styleMenu = {
    backgroundColor: "#FFFFFF",
    fontSize: "20px",
    color: "#95959B",
    margin: { xs: "10px", md: "20px" },
  };

  const savePNewsLetter = async () => {
    let validate = true;
    if (!emailValidate) {
      setEmailError(true);
      setEmailHelperText("Please Enter Valid Email");
      validate = false;
    }
    // if (!mobileValidate) {
    //   setPhoneError(true);
    //   setPhoneHelperText("Please Enter Valid Email");
    //   validate = false;
    // }
    if (validate) {
      let params = {
        email: email,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveNewsLetter,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Subscribed SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setEmailError(false);
          setEmailHelperText("");
          setEmail("");
          // setMobileNumber("");
        } else if (
          response.data.responseCode === "GM001" &&
          response.data.responseMessage === "Email already exists"
        ) {
          toast.error("This Email Already Registered!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          alert(
            "Something went wrong while saving the program Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while saving the program Details. Please try again later!!"
        );
      }
    }
  };

  return (
    <Box
      xs={12}
      sm={4}
      md={4}
      style={{
        padding: "30px",
        backgroundColor: "#F5F5F5",
        height: { xs: "500px", md: "200px" },
        borderBottom: "1px solid #ccc",
        paddingBottom: "8px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: { xs: "22px", md: "25px" },
            }}
          >
            Subscribe To Our News Letter
          </Typography>
          <Typography
            variant="body2"
            sx={{
              paddingTop: "10px",
              color: "#5B5B5B",
              textAlign: "center",
            }}
          >
            Get Latest Notifications of Colleges, Exams and News.
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            placeholder="Enter Your Email Address"
            value={email}
            error={emailError}
            helperText={emailHelperText}
            onChange={(e) => setEmail(e.target.value)}
            sx={styleMenu}
            fullWidth
          ></TextField>
        </Grid>
        {/* <Grid item md={3} sm={6} xs={12}>
          <TextField
            placeholder="Enter Your Mobile Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            sx={styleMenu}
            error={phoneError}
            helperText={phoneHelperText}
            fullWidth
          ></TextField>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            select
            label="Select Course"
            value={course}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            sx={styleMenu}
          >
            <MenuItem value="option1">B.Tech</MenuItem>
            <MenuItem value="option2">M.Tech</MenuItem>
            <MenuItem value="option3">MCA</MenuItem>
          </TextField>
        </Grid> */}
        <Grid item md={3} xs={12}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#405189",
              radius: "5px",
              padding: "13px 0px",
              color: "white",
              fontSize: "16px",
              margin: { xs: "10px", md: "20px 9px 20px 20px" },
            }}
            onClick={savePNewsLetter}
            fullWidth
          >
            Subscribe Now!
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default SubscribeNewsLetter;
