import React, { useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  Divider,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { useCookies } from "react-cookie";
import { config } from "./../../../config/config";
import SignUp from "../../auth/signup/SignUp";
import Login from "../../auth/login/Login";
import YouTubeIcon from "@mui/icons-material/YouTube";

import TwitterIcon from "@mui/icons-material/Twitter";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import InstagramIcon from "@mui/icons-material/Instagram";

import TelegramIcon from "@mui/icons-material/Telegram";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import FacebookIcon from "@mui/icons-material/FacebookRounded";

const HidStyle = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  color: "#5B5B5B",
  textAlign: "left",
};

const Footer = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleIsSignUpModal = () => {
    setOpenSignUp(!openSignUp);
  };
  const handleIsLoginModal = () => {
    setOpenLogin(!openLogin);
  };

  const handleOpenProfile = () => {
    cookies[config.cookieName]?.loginUserId && cookies[config.cookieName]?.token
      ? navigate("/user-profile")
      : handleIsLoginModal();
  };
  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{
            padding: "20px",
            marginTop: "0px",
            pb: 5,
            textAlign: "center",
            backgroundColor: { xs: "#FFFFFF", md: "#F5F5F5" },
          }}
        >
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={2}
            sx={{ overflow: "hidden", marginLeft: { md: "30px" } }}
          >
            <Typography variant="h6" sx={{ color: "#000", textAlign: "left" }}>
              Top Colleges
            </Typography>
            <Stack sx={{ color: "gray" }}>
              <Typography variant="body2" style={HidStyle}>
                R.V college
              </Typography>
              <Typography variant="body2" style={HidStyle}></Typography>
              <Typography variant="body2" style={HidStyle}>
                BMS College
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Jain College
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                JSS Medical College
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                SPA Mysore college
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={2}
            sx={{ overflow: "hidden", marginLeft: { md: "30px" } }}
          >
            <Typography variant="h6" sx={{ color: "#000", textAlign: "left" }}>
              Top Schools
            </Typography>
            <Stack sx={{ color: "gray" }}>
              <Typography variant="body2" style={HidStyle}>
                Army Public School.
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Bishop School.
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                JSSMCH school
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Delhi Public School
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                SDMIMD College
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Doon School,
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={2}
            sx={{ overflow: "hidden", marginLeft: { md: "30px" } }}
          >
            <Typography variant="h6" sx={{ color: "#000", textAlign: "left" }}>
              Top University
            </Typography>
            <Stack sx={{ color: "gray" }}>
              <Typography variant="body2" style={HidStyle}>
                JN University
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Delhi University
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                PES University
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Christ University
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Kuvempu University
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Bangalore University
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={2}
            sx={{ overflow: "hidden", marginLeft: { md: "30px" } }}
          >
            <Typography variant="h6" sx={{ color: "#000", textAlign: "left" }}>
              Study Aboard
            </Typography>
            <Stack sx={{ color: "gray" }}>
              <Typography variant="body2" style={HidStyle}>
                Engineering.
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Computer Science
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Aeronautical
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Law
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Medical
              </Typography>
              <Typography variant="body2" style={HidStyle}>
                Hospitality
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2}
            sx={{ overflow: "hidden", marginLeft: { md: "30px" } }}
          >
            <Typography variant="h6" sx={{ color: "#000", textAlign: "left" }}>
              Our Developer Projects
            </Typography>
            <Stack sx={{ color: "gray" }}>
              <Link
                variant="body2"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "#5B5B5B",
                  textDecoration: "none",
                  textAlign: "left",
                }}
                to={"https://bengalurupainters.com/"}
                target="blank"
              >
                Bengaluru Painters
              </Link>
              {/* <Link
                variant="body2"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textDecoration: "none",
                  color: "#5B5B5B",
                  textAlign: "left",
                }}
              >
                GET My Studies
              </Link> */}
              <Link
                variant="body2"
                style={{
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "#5B5B5B",
                  textAlign: "left",
                }}
              >
                GET My Services
              </Link>
            </Stack>
          </Grid>
        </Grid>
        <Divider style={{ backgroundColor: { xs: "white", md: "#95959B" } }} />

        <Grid container spacing={2} sx={{ py: 2 }}>
          <Grid item md={8} xs={12}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                my: { md: "12px" },
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              Other Links
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#5B5B5B",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/blogs");
                  }}
                >
                  Blogs
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#5B5B5B",
                  }}
                >
                  Services
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#5B5B5B",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  Contact US
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    color: "#5B5B5B",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  About
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-end",
            }}
          >
            <Link
              to={"https://wa.me/919900127096?text=Admission"}
              target="_blank"
            >
              <div className="youtube-icon-container">
                <WhatsAppIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#25d366",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link
              to="https://www.linkedin.com/in/get-my-studies-a827462a1"
              target="_blank"
            >
              <div className="youtube-icon-container">
                <LinkedInIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#0a66c2",

                    borderRadius: "15px",
                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link to="https://www.instagram.com/getmystudies/" target="_blank">
              <div className="youtube-icon-container">
                <InstagramIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    background:
                      "linear-gradient(to right, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d, #f56040, #f77737, #fcaf45, #ffdc80)",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61554234971060&mibextid=ZbWKwL"
              target="_blank"
            >
              <div className="youtube-icon-container">
                <FacebookIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#1877f2",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link to="https://www.youtube.com/@Getmystudies" target="_blank">
              <div className="youtube-icon-container">
                <YouTubeIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "#dd2c00",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
            <Link to="https://twitter.com/Getmystudi11178" target="_blank">
              <div className="youtube-icon-container">
                <TwitterIcon
                  style={{
                    width: "30px",

                    height: "30px",

                    padding: "5px",

                    color: "white",

                    backgroundColor: "rgb(29, 155, 240)",

                    borderRadius: "15px",

                    cursor: "pointer",

                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </div>
            </Link>
          </Grid>
        </Grid>
        <Divider />
        <Grid container sx={{ p: 2 }}>
          <Grid item md={8}>
            <Typography sx={{ color: "#95a5a6" }}>Address :</Typography>
            <Typography>
              No. 296, 2nd Floor, 24th Main Rd, above Hema Book World, 6th
              Phase, J. P. Nagar, Bengaluru, Karnataka 560078
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ color: "#95a5a6" }}>Contact No :</Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Link
                to="tel:9686522212"
                style={{ textDecoration: "none", color: "#000" }}
              >
                +91 96865 22212
              </Link>
              <Link
                to="tel:9900127097"
                style={{ textDecoration: "none", color: "#000" }}
              >
                +91 99001 27097
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ width: "100%", py: 1 }}>
          <Typography
            sx={{
              fontSize: { md: "15px", xs: "13px" },
              fontWeight: "200px",
              textAlign: "center",
              lineHeight: "27.24px",
              marginTop: "10px",
            }}
          >
            @{new Date().getFullYear()} Copyright Reserved by Get My Studies
          </Typography>
        </Box>
      </Box>
      <SignUp
        open={openSignUp}
        close={handleIsSignUpModal}
        loginOpen={handleIsLoginModal}
      />
      <Login
        open={openLogin}
        close={handleIsLoginModal}
        signUpOpen={handleIsSignUpModal}
      />
    </>
  );
};

export default Footer;
