import {
  Button,
  CircularProgress,
  IconButton,
  Divider,
  TextField,
  Typography,
  Modal,
  Box,
  Avatar,
  Grid,
  InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  apiList,
  invokeFormDataApi,
  invokeApi,
} from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { mobileNoValidation } from "../../../common/common";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import DeleteModal from "./DeleteModal";

const PlacementCompanyList = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewFile, setPreviewFile] = useState("");
  const [maxFileSizeErr, setMaxFileSizeErr] = useState("");
  const [fileError, setFileError] = useState(false);
  const [fileHelperText, setFileHelperText] = useState("");

  const [placementData, setPlacementData] = useState([]);
  const [placementDataFilter, setPlacementDataFilter] = useState([]);
  const [search, setSearch] = useState("");

  const [companyName, setCompanyName] = useState("");

  // const [contactPersonName, setContactPersonName] = useState("");

  const [contactPersonEmail, setContactPersonEmail] = useState("");

  const [officeNumber, setOfficeNumber] = useState("");
  const officeNumberValidation = mobileNoValidation(officeNumber);

  const [contactPersonPersonalNumber, setContactPersonPersonalNumber] =
    useState("");
  const contactPersonNumberValidation = mobileNoValidation(
    contactPersonPersonalNumber
  );

  const [placementCompanyNameError, setPlacementCompanyNameError] =
    useState(false);

  const [placementCompanyNameHelperText, setPlacementCompanyNameHelperText] =
    useState("");

  const navigate = useNavigate();

  const [saveLoading, setSaveLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [isPlacementTypeFetching, setIsPlacementTypeFetching] = useState(true);

  const [companyLogoPath, setCompanyLogoPath] = useState("");

  const [id, setId] = useState("");

  const [
    placementContactPersonOfficeNumberError,
    setPlacementContactPersonOfficeNumberError,
  ] = useState(false);

  const [
    placementContactPersonOfficeNumberHelperText,
    setPlacementContactPersonOfficeNumberHelperText,
  ] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  // const [contactPersonNameHelperText, setContactPersonNameHelperText] =
  // useState("");

  const [
    placementContactPersonPersonalNumberError,
    setPlacementContactPersonPersonalNumberError,
  ] = useState(false);

  const [
    placementContactPersonPersonalNumberHelperText,
    setPlacementContactPersonPersonalNumberHelperText,
  ] = useState("");

  const handleIsAddModal = () => {
    setAddModal(!addModal);
  };
  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };
  const handleIsDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const clearAllError = () => {
    setId("");
    setSaveLoading(false);
    setUpdateLoading(false);
    setPlacementCompanyNameError(false);
    setPlacementCompanyNameHelperText("");
    setFileError(false);
    setCompanyLogoPath("");
    setCompanyName("");
    setContactPersonPersonalNumber("");
    setFileHelperText("");
    setContactPersonName("");
    setContactPersonEmail("");
    setOfficeNumber("");
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  // placement company logo upload validate
  const handleFileUpload = (ev) => {
    const fileUploaded = ev.target.files[0];
    let acceptProfileFileTypes = fileUploaded.type.match(
      /^image\/(jpe?g|png|gif)/
    );

    if (fileUploaded && acceptProfileFileTypes) {
      if (fileUploaded.size < 1024 * 1024) {
        setUploadedFile(fileUploaded);
        setPreviewFile(URL.createObjectURL(fileUploaded));
        setMaxFileSizeErr("");
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 1024 * 1024;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], fileUploaded.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });

                setUploadedFile(compressedFile);
                setPreviewFile(URL.createObjectURL(compressedFile));
                setMaxFileSizeErr("");
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(fileUploaded);
      }
    } else {
      setMaxFileSizeErr(
        "Please upload a valid image in jpeg/jpg/png/gif format"
      );
    }
  };

  const handleUploadFile = async (ev) => {
    ev.preventDefault();
    setSaveLoading(true);
    var validationError = true;
    if (!companyName) {
      setPlacementCompanyNameHelperText("Please Enter Company Name");
      setPlacementCompanyNameError(true);
      setSaveLoading(false);
      validationError = false;
    }
    if (!uploadedFile) {
      setFileError(true);
      setFileHelperText("Please Upload Company Logo");
      setSaveLoading(false);
      validationError = false;
    }
    if (contactPersonPersonalNumber && !contactPersonNumberValidation) {
      setPlacementContactPersonOfficeNumberError(true);
      setPlacementContactPersonOfficeNumberHelperText(
        "Please enter a valid number"
      );
      setSaveLoading(false);
      validationError = false;
    }
    if (officeNumber && !officeNumberValidation) {
      setPlacementContactPersonPersonalNumberError(true);
      setPlacementContactPersonPersonalNumberError("please enter valid number");
      setSaveLoading(false);
      validationError = false;
    }

    if (uploadedFile && validationError) {
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "PlacementCompany_logo");

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCompanyLogoPath(response.data.url);
          handleSavePlacementCompany(response.data.url);
        } else {
          alert(
            "Something went wrong while uploading Company Logo. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while uploading Company Logo. Please try again later!!"
        );
      }
    }
  };

  const handleSavePlacementCompany = async (url) => {
    let params = {
      companyName: companyName,
      companyLogoPath: url,
      contactPersonName: contactPersonName,
      contactPersonEmail: contactPersonEmail,
      contactPersonOfficeNumber: officeNumber,
      contactPersonPersonalNumber: contactPersonPersonalNumber,
    };

    let response = await invokeApi(
      config.getMyCollege + apiList.savePlacementCompany,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Placement Company Saved SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setPlacementCompanyNameHelperText("");
        setPlacementCompanyNameError(false);
        setFileError(false);
        setFileHelperText("");
        setPlacementContactPersonPersonalNumberError(false);
        setPlacementContactPersonPersonalNumberHelperText("");
        setPlacementContactPersonOfficeNumberError(false);
        setPlacementContactPersonOfficeNumberHelperText("");
        setIsPlacementTypeFetching(true);
        setUploadedFile(null);
        setSaveLoading(false);
        setPreviewFile("");
        setCompanyName("");
        setContactPersonEmail("");
        setCompanyLogoPath("");
        setContactPersonName("");
        setOfficeNumber("");
        setContactPersonPersonalNumber("");
        handleIsAddModal();
      } else if (response.data.responseCode === "GM003") {
        handleIsAddModal(false);
        toast.info("Company Already Exist!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCompanyLogoPath("");
        setSaveLoading(false);
        setPlacementCompanyNameHelperText("");
        setPlacementCompanyNameError(false);
        setPlacementContactPersonPersonalNumberError(false);
        setPlacementContactPersonPersonalNumberHelperText("");
        setPlacementContactPersonOfficeNumberError(false);
        setPlacementContactPersonOfficeNumberHelperText("");
      } else {
        alert(
          "Something went wrong while saving the Placement Company Details. Please try again later!"
        );
        setSaveLoading(false);
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while saving the Placement Company Details. Please try again later!!"
      );
      setSaveLoading(false);
    }
  };

  const handleUpdateUploadFile = async (ev) => {
    ev.preventDefault();
    setUpdateLoading(true);
    var validationError = true;
    if (!companyName) {
      setPlacementCompanyNameHelperText("Please Enter Company Name");
      setPlacementCompanyNameError(true);
      setUpdateLoading(false);
      validationError = false;
    }
    if (!uploadedFile && !companyLogoPath) {
      setFileError(true);
      setFileHelperText("Please Upload Company Logo");
      setUpdateLoading(false);
      validationError = false;
    }
    if (uploadedFile && validationError) {
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "company_logo");

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCompanyLogoPath(response.data.url);
          updateCompany(response.data.url);
        } else {
          alert(
            "Something went wrong while uploading Company Logo. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while uploading Company Logo. Please try again later!!"
        );
      }
    } else if (companyLogoPath && validationError) {
      setCompanyLogoPath(companyLogoPath);
      updateCompany(companyLogoPath);
    }
  };

  const updateCompany = async (url) => {
    var params = {
      id: id,
      companyName: companyName,
      companyLogoPath: url,
      contactPersonName: contactPersonName,
      contactPersonEmail: contactPersonEmail,
      contactPersonOfficeNumber: officeNumber,
      contactPersonPersonalNumber: contactPersonPersonalNumber,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updatePlacementCompany,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Placement Company Updated SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setPlacementCompanyNameHelperText("");
        setPlacementCompanyNameError(false);
        setPlacementContactPersonPersonalNumberError(false);
        setPlacementContactPersonPersonalNumberHelperText("");
        setPlacementContactPersonOfficeNumberError(false);
        setPlacementContactPersonOfficeNumberHelperText("");
        setIsPlacementTypeFetching(true);
        setUpdateLoading(false);
        handleIsEditModal();
        setCompanyLogoPath("");
      } else if (response.data.responseCode === "GM003") {
        handleIsEditModal(false);
        toast.info("Company Already Exist!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setCompanyLogoPath("");
        setUpdateLoading(false);
        setPlacementCompanyNameHelperText("");
        setPlacementCompanyNameError(false);
        setPlacementContactPersonPersonalNumberError(false);
        setPlacementContactPersonPersonalNumberHelperText("");
        setPlacementContactPersonOfficeNumberError(false);
        setPlacementContactPersonOfficeNumberHelperText("");
      } else {
        alert(
          "Something went wrong while update company Details. Please try again later!"
        );
        setUpdateLoading(false);
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while update company Details. Please try again later!!"
      );
      setUpdateLoading(false);
    }
  };

  const deleteCompany = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updatePlacementCompany,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Placement Company Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleIsDeleteModal();
        setIsPlacementTypeFetching(true);
        setId("");
      } else if (response.data.responseCode === "GM002") {
        handleIsDeleteModal();
        toast.info(
          "This Placement Company Mapped to Some College You Can't Delete This Placement Company!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete Company Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete Comapny Details. Please try again later!!"
      );
    }
  };

  useEffect(() => {
    const getAllPlacementType = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPlacementCompanies,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setPlacementData(response.data.placementCompanies);
          setPlacementDataFilter(response.data.placementCompanies);
          setIsPlacementTypeFetching(false);
        } else {
          alert(
            "Something went wrong while getting the Placement Company Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Placement Company Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    };
    if (isPlacementTypeFetching) {
      getAllPlacementType();
    }
  }, [cookies, isPlacementTypeFetching]);

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "10%",
    },
    {
      name: "Company Name",
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: "Company Logo",
      selector: (row) => (
        <img src={row.companyLogoPath} height={50} alt={row.companyName} />
      ),
    },
    {
      name: "Contact Person Name",
      selector: (row) => row.contactPersonName,
      sortable: true,
    },
    {
      name: "Contact Person Email",
      selector: (row) => row.contactPersonEmail,
      sortable: true,
    },
    {
      name: "Contact Person Office Number",
      selector: (row) => row.contactPersonOfficeNumber,
      sortable: true,
    },
    {
      name: "Contact Person Personal Number",
      selector: (row) => row.contactPersonPersonalNumber,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setSaveLoading(false);
                  setCompanyName(row.companyName);
                  setId(row.id);
                  setCompanyLogoPath(row.companyLogoPath);
                  setContactPersonName(row.contactPersonName);
                  setContactPersonEmail(row.contactPersonEmail);
                  setOfficeNumber(row.contactPersonOfficeNumber);
                  setContactPersonPersonalNumber(
                    row.contactPersonPersonalNumber
                  );
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setSaveLoading(false);
                  setCompanyName(row.companyName);
                  setId(row.id);
                  setCompanyLogoPath(row.companyLogoPath);
                  setContactPersonName(row.contactPersonName);
                  setContactPersonEmail(row.contactPersonEmail);
                  setOfficeNumber(row.contactPersonOfficeNumber);
                  setContactPersonPersonalNumber(
                    row.contactPersonPersonalNumber
                  );
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  handleIsDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = placementData.filter((item) => {
      return item.companyName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setPlacementDataFilter(result);
  }, [search]);
  return (
    <>
      <CollegeMetadataTabs tabActive={4}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={handleIsAddModal}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={placementDataFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isPlacementTypeFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* placement company list modal  */}
      <Modal
        open={addModal}
        onClose={() => {
          handleIsAddModal();
          clearAllError();
        }}
        sx={{
          backgroundColor: `rgba(0,0,0, 0.6)`,
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            pt: 2,
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsAddModal();
                clearAllError();
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="large" color="#000" />
            </IconButton>
          </Box>
          <form onSubmit={handleUploadFile} style={{ width: "100%" }}>
            {/* File upload */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                id="profile-pic"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />

              {uploadedFile && !maxFileSizeErr ? (
                <label htmlFor="profile-pic">
                  <IconButton component="span">
                    <Box
                      component="img"
                      src={previewFile}
                      alt="profile"
                      sx={{
                        width: 150,
                        height: 150,
                        borderRadius: "50%",
                        objectFit: "cover",
                        boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                      }}
                    />
                  </IconButton>
                </label>
              ) : (
                <>
                  <label htmlFor="profile-pic">
                    <IconButton component="span">
                      <Avatar
                        sx={{
                          width: 150,
                          height: 150,
                          background: "#FFFFFF",
                          boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                        }}
                      >
                        <Box
                          component={"img"}
                          src="/media/svg/user-profile.svg"
                          sx={{ width: 56, height: 56 }}
                          alt="profile"
                        />
                      </Avatar>
                    </IconButton>
                  </label>
                  <Typography variant="bodyregular" color={"error"}>
                    {maxFileSizeErr}
                  </Typography>
                </>
              )}
              {fileError ? (
                <Typography variant="bodyparagraph" color={"error"}>
                  {fileHelperText}
                </Typography>
              ) : (
                <Typography variant="bodyparagraph">
                  Upload Company Logo
                </Typography>
              )}
            </Box>
            <Box display={"flex"}>
              <TextField
                id="outlined"
                value={companyName}
                autoComplete="off"
                error={placementCompanyNameError}
                helperText={placementCompanyNameHelperText}
                sx={{ my: 2, mr: 1 }}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  setPlacementCompanyNameError(false);
                  setPlacementCompanyNameHelperText("");
                }}
                label="Company Name *"
              />
              <TextField
                id="outlined"
                value={contactPersonName}
                autoComplete="off"
                sx={{ my: 2, mr: 1 }}
                onChange={(e) => {
                  setContactPersonName(e.target.value);
                }}
                label="Contact Person Name"
              />
            </Box>
            <Box display={"flex"}>
              <TextField
                id="outlined"
                value={contactPersonEmail}
                autoComplete="off"
                sx={{ my: 2, mr: 1 }}
                onChange={(e) => {
                  setContactPersonEmail(e.target.value);
                }}
                label="Contact Person Email"
              />
              <TextField
                id="outlined"
                value={officeNumber}
                error={placementContactPersonOfficeNumberError}
                helperText={placementContactPersonOfficeNumberHelperText}
                sx={{ my: 2, mr: 1 }}
                inputProps={{
                  maxLength: 10,
                }}
                onChange={(e) => {
                  setOfficeNumber(e.target.value);
                  setPlacementContactPersonOfficeNumberError(false);
                  setPlacementContactPersonOfficeNumberHelperText("");
                }}
                label="Contact Person Office Mobile Number"
              />
            </Box>
            <TextField
              id="outlined"
              value={contactPersonPersonalNumber}
              autoComplete="off"
              error={placementContactPersonPersonalNumberError}
              helperText={placementContactPersonPersonalNumberHelperText}
              sx={{ my: 2, mr: 1 }}
              inputProps={{
                maxLength: 10,
              }}
              onChange={(e) => {
                setContactPersonPersonalNumber(e.target.value);
                setPlacementContactPersonPersonalNumberError(false);
                setPlacementContactPersonPersonalNumberHelperText("");
              }}
              label="Contact Person Mobile Number"
              fullWidth
            />

            <Button variant="contained" type="submit" disabled={saveLoading}>
              Submit
              {saveLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
            </Button>
          </form>
        </Box>
      </Modal>

      {/* placement company edit list modal  */}
      <Modal
        open={editModal}
        onClose={() => {
          handleIsEditModal();
          clearAllError();
        }}
        sx={{
          backgroundColor: `rgba(0,0,0, 0.6)`,
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            pt: 2,
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsEditModal();
                clearAllError();
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="large" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <form onSubmit={handleUpdateUploadFile} style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
              }}
            >
              {/* File upload */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="profile-pic"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />

                {uploadedFile && !maxFileSizeErr ? (
                  <label htmlFor="profile-pic">
                    <IconButton component="span">
                      <Box
                        component="img"
                        src={previewFile}
                        alt="profile"
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                          boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                        }}
                      />
                    </IconButton>
                  </label>
                ) : (
                  <>
                    <label htmlFor="profile-pic">
                      <IconButton component="span">
                        <Avatar
                          sx={{
                            width: 100,
                            height: 100,
                            background: "#FFFFFF",
                            boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={companyLogoPath}
                            alt="company"
                            sx={{ width: 56, height: 56 }}
                          />
                        </Avatar>
                      </IconButton>
                    </label>
                    <Typography variant="bodyregular" color={"error"}>
                      {maxFileSizeErr}
                    </Typography>
                  </>
                )}
              </Box>
              {fileError ? (
                <Typography variant="bodyparagraph" color={"error"}>
                  {fileHelperText}
                </Typography>
              ) : (
                <Typography variant="bodyparagraph">
                  Upload Company Logo
                </Typography>
              )}
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <TextField
                    id="company-name-basic"
                    label="Company Name *"
                    variant="outlined"
                    type="text"
                    value={companyName}
                    autoComplete="off"
                    sx={{ my: 2 }}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      setPlacementCompanyNameError(false);
                      setPlacementCompanyNameHelperText("");
                    }}
                    error={placementCompanyNameError}
                    helperText={placementCompanyNameHelperText}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="contact-person-name-basic"
                    label="Contact Person Name"
                    variant="outlined"
                    type="text"
                    value={contactPersonName}
                    autoComplete="off"
                    sx={{ my: 2 }}
                    onChange={(e) => {
                      setContactPersonName(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="contact-person-email-basic"
                    label="Contact Person Email"
                    variant="outlined"
                    type="email"
                    value={contactPersonEmail}
                    autoComplete="off"
                    sx={{ my: 2 }}
                    onChange={(e) => {
                      setContactPersonEmail(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="contact-person-office-number-basic"
                    label="Contact Person Office Number"
                    variant="outlined"
                    value={officeNumber}
                    autoComplete="off"
                    sx={{ my: 2 }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={(e) => {
                      setOfficeNumber(e.target.value);
                      setPlacementContactPersonOfficeNumberError(false);
                      setPlacementContactPersonOfficeNumberHelperText("");
                    }}
                    error={placementContactPersonOfficeNumberError}
                    helperText={placementContactPersonOfficeNumberHelperText}
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    id="contact-person-personal-number-basic"
                    label="Contact Person personal Number"
                    variant="outlined"
                    value={contactPersonPersonalNumber}
                    autoComplete="off"
                    sx={{ my: 2 }}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={(e) => {
                      setContactPersonPersonalNumber(e.target.value);
                      setPlacementContactPersonPersonalNumberError(false);
                      setPlacementContactPersonPersonalNumberHelperText("");
                    }}
                    error={placementContactPersonPersonalNumberError}
                    helperText={placementContactPersonPersonalNumberHelperText}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                type="submit"
                disabled={updateLoading}
              >
                Update
                {updateLoading && <CircularProgress size={20} />}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* delete modal  */}
      <DeleteModal
        handleIsDeleteModal={handleIsDeleteModal}
        Open={deleteModal}
        DeleteFunction={deleteCompany}
      />
    </>
  );
};

export default PlacementCompanyList;
