import React from "react";
import {
  Typography,
  Button,
  Grid,
  Stack,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const ExamResultPage = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ mr: "12px" }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: { xs: "20px", md: "25px" },
              fontWeight: "600",
              mb: "13px",
            }}
          >
            Training Institute, Exams, Results
          </Typography>
          <Typography
            sx={{ fontSize: "20px", color: "#95959B", fontWeight: "400" }}
          >
            {isSm
              ? "India’s Leading Education Portal"
              : " India’s Leading Education Portal For All Your Academic Needs"}
          </Typography>
        </Box>
        {!isSm ? (
          <img
            src="/Studio-portrait-of-funny-excited-joyful-student-girl-with-graduation-certificate.png"
            alt="student Girl"
          />
        ) : null}
      </Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "#F5F7F8",
          py: "40px",
          overflowX: "auto", // Enable horizontal scrolling
        }}
      >
        <Box
          sx={{
            overflowX: "auto",
            py: 3,
            "&::-webkit-scrollbar": {
              height: "5px", // Adjust the height of the scrollbar
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#405189;", // Set the color of the scrollbar thumb
              borderRadius: "8px", // Round the corners of the thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1", // Set the color of the scrollbar track
              borderRadius: "10px", // Round the corners of the track
            },
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: { md: "wrap", xs: "nowrap" },
            }}
          >
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mx: isSm ? "25px" : "0",
                }}
                key={index}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: { xs: "315px", md: "400.609px" },
                    maxHeight: "270.428px",
                    boxShadow: isSm
                      ? "none"
                      : "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        my: "15px",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "var(--primary-colors-405189, #405189)",
                            textAlign: "left",
                            fontSize: { xs: "27px", sm: "33.524px" },
                          }}
                        >
                          Exams
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "10px",
                            color: "var(--primary-colors-95959-b, #95959B)",
                          }}
                        >
                          {" "}
                          Check your exams Results <br />
                          Know More...{" "}
                        </Typography>
                      </Box>
                      <Box>
                        <img
                          src="/examresultlog.png"
                          alt="logo"
                          style={{
                            width: "159.018px",
                            height: "79.509px",
                          }}
                        />
                      </Box>
                    </Box>

                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: "15px",
                      }}
                    >
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",
                          color: "var(--Bullet-Points, #5B5B5B)",
                          padding: "6.705px 8.381px",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        Mains
                      </Button>

                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          padding: "4px 8px",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        Jee
                      </Button>
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        PUC
                      </Button>
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          padding: "4px 8px",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        B.E
                      </Button>
                    </Stack>

                    <Stack
                      spacing={2}
                      direction="row"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: "15px",
                      }}
                    >
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        CBSE
                      </Button>
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          padding: "4px 8px",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        M.A
                      </Button>
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          padding: "4px 8px",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        Degree
                      </Button>
                      <Button
                        sx={{
                          fontSize: { sm: "15.762px", xs: "12px" },
                          fontWeight: 400,
                          fontStyle: "normal",

                          color: "var(--Bullet-Points, #5B5B5B)",
                          padding: "2px 8px",
                          border:
                            "0.838px solid var(--primary-colors-95959-b, #95959B)",
                          backgroundColor: "#F5F5F5",
                          "&:hover": { backgroundColor: "transparent" },
                          "&:focus": { backgroundColor: "transparent" },
                          "&:active": { backgroundColor: "transparent" },
                          borderRadius: "8px",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                      >
                        {/* <FiberManualRecordIcon
                          style={{ color: "#5B5B5B", fontSize: "9px" }}
                        /> */}
                        Arts
                      </Button>
                    </Stack>

                    <Stack
                      direction="row"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "10px",
                          color: "var(--primary-colors-405189, #405189)",
                        }}
                      >
                        Check your exams results all over India
                      </Typography>

                      <Typography>
                        <img src="/media/svg/dot.svg" alt="dot" />
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ExamResultPage;
