import {
  Button,
  IconButton,
  Box,
  Divider,
  Modal,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import AddIcon from "@mui/icons-material/Add";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import { LoadingButton } from "@mui/lab";

const InstituteBrandList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;
  const [cookies] = useCookies();

  const [search, setSearch] = useState("");

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const [saveLoading, setSaveLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [brandName, setBrandName] = useState("");
  const [brandNameError, setBrandNameError] = useState(false);
  const [brandNameHelperText, setBrandNameHelperText] = useState("");

  const [isBrandNameFetching, setIsBrandNameFetching] = useState(true);

  const [id, setId] = useState("");
  const [brandNameList, setBrandNameList] = useState([]);
  const [brandNameListFilter, setBrandNameListFilter] = useState([]);

  const handleIsAddModal = () => {
    setAddModal(!addModal);
  };

  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };

  const handleIsDeleteModal = () => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  useEffect(() => {
    const getAllBrandName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getInstituteBrands,
        params,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setBrandNameList(response.data.instituteBrands);
          setBrandNameListFilter(response.data.instituteBrands);
          setSaveLoading(false);
          setBrandNameError(false);
          setIsBrandNameFetching(false);
        } else {
          alert(
            "Something went wrong while getting the Institute Brand Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Institute Brand Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    };
    if (isBrandNameFetching) {
      getAllBrandName();
    }
  }, [cookies, isBrandNameFetching]);

  const saveBrandName = async () => {
    var validation = true;
    if (!brandName) {
      setBrandNameError(true);
      setBrandNameHelperText("Please Enter Brand Name");
      setSaveLoading(false);
      validation = false;
    }
    if (validation) {
      setSaveLoading(true);
      let params = {
        brandName: brandName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveInstituteBrand,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Institute Brand Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          handleIsAddModal();
          setSaveLoading(false);
          setBrandNameError(false);
          setBrandNameHelperText("");
          setIsBrandNameFetching(true);
        } else if (response.data.responseCode === "GM003") {
          handleIsAddModal(false);
          toast.info("Institute Brand Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setSaveLoading(false);
          setBrandName("");
          setBrandNameError(false);
          setBrandNameHelperText("");
        } else {
          alert(
            "Something went wrong while saving the Brand Name Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the Brand Name Details. Please try again later!!"
        );
      }
    }
  };

  const deleteBrandName = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateInstituteBrand,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Institute Brand Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setId("");
        handleIsDeleteModal();
        setIsBrandNameFetching(true);
      } else if (response.data.responseCode === "GM002") {
        handleIsDeleteModal();
        toast.info(
          "This Institute Brand Mapped to Some College You Can't Delete This Institute Brand!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setId("");
      } else {
        alert(
          "Something went wrong while delete Brand Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete Brand Details. Please try again later!!"
      );
    }
  };

  const updateBrandName = async () => {
    var validate = true;
    if (!brandName) {
      setBrandNameError(true);
      setBrandNameHelperText("Please Enter Brand Name");
      setUpdateLoading(false);
      validate = false;
    }
    if (validate) {
      let params = {
        id: id,
        brandName: brandName,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateInstituteBrand,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Institute Brand Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          handleIsEditModal();
          setBrandName("");
          setBrandNameError(false);
          setBrandNameHelperText("");
          setIsBrandNameFetching(true);
        } else if (response.data.responseCode === "GM003") {
          handleIsEditModal(false);
          toast.info("Institute Brand Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          setBrandName("");
          setBrandNameError(false);
          setBrandNameHelperText("");
        } else {
          alert(
            "Something went wrong while Edit Brand Details. Please try again later!"
          );
          setUpdateLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit Brand Details. Please try again later!!"
        );
        setUpdateLoading(false);
      }
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Brand Name",
      selector: (row) => row.brandName,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  handleIsEditModal();
                  setBrandName(row.brandName);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  handleIsEditModal();
                  setBrandName(row.brandName);
                  setId(row.id);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  handleIsDeleteModal();
                  setId(row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = brandNameList.filter((item) => {
      return item.brandName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setBrandNameListFilter(result);
  }, [search]);
  return (
    <>
      <CollegeMetadataTabs tabActive={5}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => {
            handleIsAddModal();
            setSaveLoading(false);
          }}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={brandNameListFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isBrandNameFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      <DeleteModal
        handleIsDeleteModal={handleIsDeleteModal}
        Open={deleteConfirmation}
        DeleteFunction={deleteBrandName}
      />
      {/* edit modal  */}
      <Modal
        open={editModal}
        onClose={() => {
          handleIsEditModal();
          setBrandNameError(false);
          setBrandNameHelperText("");
          setBrandName("");
        }}
        sx={{
          backdropFilter: editModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsEditModal();
                setUpdateLoading(false);
                setBrandNameError(false);
                setBrandNameHelperText("");
                setBrandName("");
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="medium" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              gap: "10px",
            }}
          >
            <TextField
              id="brand-name-basic"
              label="Institute Brand *"
              variant="outlined"
              type="text"
              autoComplete="off"
              sx={{ my: 2 }}
              value={brandName}
              onChange={(e) => {
                setBrandName(e.target.value);
                setBrandNameError(false);
                setBrandNameHelperText("");
              }}
              error={brandNameError}
              helperText={brandNameHelperText}
              fullWidth
            />
            <LoadingButton
              loading={updateLoading}
              type="submit"
              variant="contained"
              onClick={() => {
                setUpdateLoading(true);
                updateBrandName();
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
      {/* add  */}
      <Modal
        open={addModal}
        onClose={handleIsAddModal}
        sx={{
          backdropFilter: addModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsAddModal();
                setBrandNameError(false);
                setBrandNameHelperText("");
                setBrandName("");
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="medium" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              gap: "10px",
            }}
          >
            <TextField
              id="brand-name-basic"
              label="Brand Name *"
              variant="outlined"
              autoComplete="off"
              sx={{ my: 2 }}
              onChange={(e) => {
                setBrandName(e.target.value);
                setBrandNameError(false);
                setBrandNameHelperText("");
              }}
              error={brandNameError}
              helperText={brandNameHelperText}
              fullWidth
            />
            <LoadingButton
              loading={saveLoading}
              type="submit"
              variant="contained"
              onClick={() => {
                saveBrandName();
              }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default InstituteBrandList;
