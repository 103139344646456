import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import { invokeApi, apiList } from "./../../../../services/apiServices";
import { useCookies } from "react-cookie";
import { config } from "../../../../config/config";
import { Link } from "react-router-dom";

const sliderItems = [
  {
    image: "./Sliderimage/3.jpg",
    paragraph:
      "I send good regards and welcome the university student to our campus. I hope the faculty enriches you with all the theoretical and practical knowledge they have.",
  },
  {
    image: "./Sliderimage/5.jpg",
    paragraph:
      "Each success only buys an admission ticket to a more difficult problem.",
  },
  {
    image: "./Sliderimage/6.jpg",
    paragraph:
      "Education is not preparation for life; education is life itself.",
  },
];

const initialPlaceholders = ["College", "Course", "Specializations", "School"];

const BannerSlider = () => {
  const [cookies] = useCookies();
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  const handleInputChange = (newInputValue) => {
    setSearchText(newInputValue);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % initialPlaceholders.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const getSuggetions = async () => {
      let params = {
        status: "Active",
        searchCollege: searchText,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.searchCollege,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setSuggestions(response.data.colleges);
        } else {
          alert(
            "Something went wrong while getting college Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting college Details. Please try again later!!"
        );
      }
    };
    getSuggetions();
  }, [searchText]);

  return (
    <>
      <Box sx={{ position: "relative", mb: 4 }}>
        <Carousel animation="slide" duration={800} indicators={false}>
          {sliderItems.map((item, idx) => (
            <React.Fragment key={idx}>
              <Box>
                <img
                  src={item.image}
                  alt={`Slider ${idx + 1}`}
                  style={{
                    height: "80vh",
                    width: "100%",
                    objectFit: "cover",
                    margin: 0,
                  }}
                />
              </Box>
              {/* Overlay */}
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.3)", // Black color with 0.3 opacity
                }}
              />
            </React.Fragment>
          ))}
        </Carousel>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            zIndex: 3,
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            width: { sm: "50%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              marginBottom: "16px",
              fontSize: { sm: "20px" },
              fontWeight: "700",
            }}
          >
            Your Searching now in
            <Box
              component="span"
              sx={{
                color: "#F5374D",
                fontWeight: "800",
                fontSize: { sm: "20px" },
                ml: "4px",
              }}
            >
              {cookies[config.preferencesCookie] &&
                cookies[config.preferencesCookie].city}
            </Box>
          </Typography>
          <Autocomplete
            id="search-autocomplete"
            freeSolo
            options={suggestions}
            fullWidth
            getOptionLabel={(option) => option.collegeName}
            // onChange={(event, value) => handleOptionClick(value)}
            inputValue={searchText}
            onInputChange={(event, newInputValue) =>
              handleInputChange(newInputValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={
                  "What are you looking for - " +
                  initialPlaceholders[placeholderIndex]
                }
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                  width: "70%",
                  backgroundColor: "white",
                  borderRadius: 30,
                  outline: "none",
                  textAlign: "center",
                  transition: "opacity 0.5s ease-in-out", // Transition effect added
                  opacity: 1, // Initial opacity set to 1
                  "&:hover": {
                    opacity: 0.8, // Opacity reduced on hover
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Link
                style={{ textDecoration: "none", color: "#000" }}
                to={`/institute/${option.seoOgUrl}/info/${option.id}`}
                {...props}
              >
                {option.collegeName}
              </Link>
            )}
          />
          <Typography sx={{ fontSize: { sm: "25px" }, fontWeight: "700" }}>
            Top Colleges In India based on {new Date().getFullYear()} Ranking
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BannerSlider;
