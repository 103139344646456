import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
  DialogActions,
  IconButton,
  InputAdornment,
  Autocomplete,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import SearchIcon from "@mui/icons-material/Search";
import { apiList, invokeApi } from "../../../services/apiServices";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./DeleteModal";
import TextEditor from "./TextEditor";
import { LoadingButton } from "@mui/lab";

const SpecializationList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;

  const [specializationList, setSpecializationList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [specializationListFilter, setSpecializationListFilter] = useState([]);

  const [search, setSearch] = useState("");

  const [isSpecializationListFetching, setIsSpecializationListFetching] =
    useState(true);

  const [specializationName, setSpecializationName] = useState("");
  const [specializationNameHelperText, setSpecializationNameHelperText] =
    useState("");
  const [specializationNameError, setSpecializationNameError] = useState(false);

  const [courseId, setCourseId] = useState("");
  const [courseError, setCourseError] = useState(false);
  const [courseHelperText, setCourseHelperText] = useState("");

  const [specializationDescription, setSpecializationDescription] =
    useState("");

  const [isAddSpecializationDialogOpen, setIsAddSpecializationDialogOpen] =
    useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [id, setId] = useState("");

  const [deleteConfirmation, setdeleteConfirmation] = useState(false);

  const handleChange = (event, newValue) => {
    const selectedCourse = courseList.find(
      (course) => course.courseName === newValue
    );

    if (selectedCourse) {
      const selectedCourseId = selectedCourse.id;
      setCourseId(selectedCourseId);
    }
  };

  const openEditExam = () => {
    setIsEditDialogOpen(!isEditDialogOpen);
  };

  const openAddSpecialization = () => {
    setIsAddSpecializationDialogOpen(!isAddSpecializationDialogOpen);
  };

  const clearAllCache = () => {
    setSaveLoading(false);
    setUpdateLoading(false);
    setSpecializationDescription("");
    setSpecializationName("");
    setCourseId("");
    setId("");
    setSpecializationNameHelperText("");
    setSpecializationNameError(false);
    setCourseError(false);
    setCourseHelperText("");
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const handleClose = () => {
    setdeleteConfirmation(!deleteConfirmation);
  };

  const validateExam = () => {
    let validationErrors = false;
    if (!specializationName) {
      setSpecializationNameHelperText("Please enter the course");
      setSpecializationNameError(true);
      setSaveLoading(false);
      setUpdateLoading(false);
      validationErrors = true;
    }

    if (!validationErrors) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getSpecializationsList = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAllSpecialization,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setSpecializationList(response.data.specializations);
          setSpecializationListFilter(response.data.specializations);
          setIsSpecializationListFetching(false);
        } else {
          alert(
            "Something went wrong while getting Specialization Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Specialization Details. Please try again later!!"
        );
      }
    };
    const getCoursesList = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseList(response.data.courses);
          setIsSpecializationListFetching(false);
        } else {
          alert(
            "Something went wrong while getting Courses Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting Courses Details. Please try again later!!"
        );
      }
    };
    if (isSpecializationListFetching) {
      getSpecializationsList();
      getCoursesList();
    }
  }, [cookies, isSpecializationListFetching]);

  const saveSpecialization = async () => {
    let validate = await validateExam();
    if (validate) {
      let params = {
        courseId: courseId,
        specialization: specializationName,
        specializationDescription: specializationDescription,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.saveSpecialization,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Specialization Saved SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          openAddSpecialization();
          clearAllCache();
          setIsSpecializationListFetching(true);
        } else if (response.data.responseCode === "GM003") {
          openAddSpecialization();
          toast.info("Specialization Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllCache();
        } else {
          alert(
            "Something went wrong while saving the Specialization Details. Please try again later!"
          );
          setSaveLoading(false);
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while saving the Specialization Details. Please try again later!!"
        );
        setSaveLoading(false);
      }
    }
  };

  const updateSpecialization = async () => {
    var validationError = await validateExam();
    if (validationError) {
      let params = {
        id: id,
        courseId: courseId,
        specialization: specializationName,
        specializationDescription: specializationDescription,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateSpecialization,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Specialization Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setUpdateLoading(false);
          openEditExam();
          clearAllCache();
          setIsSpecializationListFetching(true);
        } else if (response.data.responseCode === "GM003") {
          openEditExam();
          toast.info("Specialization Already Exist!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllCache();
        } else {
          alert(
            "Something went wrong while Edit Specialization Details. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while Edit Specialization Details. Please try again later!!"
        );
      }
    }
  };

  const deleteExam = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateSpecialization,
      params,
      cookies
    );
    if (response?.status >= 200 && response?.status < 300) {
      if (response.data.responseCode === "200") {
        handleClose();
        toast.success("Specialization Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsSpecializationListFetching(true);
        setId("");
      } else if (response.data.responseCode === "GM002") {
        handleClose();
        toast.info(
          "This Specialization Mapped to Some College You Can't Delete This Specialization!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete Specialization Details. Please try again later!"
        );
      }
    } else if (response?.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete Specialization Details. Please try again later!!"
      );
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Specialization Name",
      selector: (row) => row.specialization,
      sortable: true,
    },
    {
      name: "Course Name",
      selector: (row) => row.courseName,
      sortable: true,
    },
    {
      name: "Specialization Description",
      selector: (row) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: row.specializationDescription,
          }}
          sx={{ my: 2 }}
        />
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  setCourseId(row.courseId);
                  setSpecializationName(row.specialization);
                  setSpecializationDescription(row.specializationDescription);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setId(row.id);
                  setCourseId(row.courseId);
                  setSpecializationName(row.specialization);
                  setSpecializationDescription(row.specializationDescription);
                  setIsEditDialogOpen(true);
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  setdeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = specializationList.filter((item) => {
      return item.specialization
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setSpecializationListFilter(result);
  }, [search]);

  return (
    <>
      <CollegeMetadataTabs tabActive={9}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={() => setIsAddSpecializationDialogOpen(true)}
        >
          <AddIcon />
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={specializationListFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isSpecializationListFetching}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* add Specialization  */}
      <Dialog
        open={isAddSpecializationDialogOpen}
        onClose={() => {
          openAddSpecialization();
          clearAllCache();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            openAddSpecialization();
            clearAllCache();
          }}
        />
        <DialogTitle>Add New Specialization</DialogTitle>
        <DialogContent>
          <Autocomplete
            fullWidth
            options={courseList.map((item) => item.courseName)}
            onChange={handleChange}
            value={
              courseId
                ? courseList.find((course) => course.id === courseId)
                    ?.courseName
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Course"
                variant="outlined"
                error={courseError}
                helperText={courseHelperText}
              />
            )}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Specialization Name *"
            name="name"
            value={specializationName}
            onChange={(ev) => {
              setSpecializationName(ev.target.value);
              setSpecializationNameError(false);
              setSpecializationNameHelperText("");
            }}
            helperText={specializationNameHelperText}
            error={specializationNameError}
          />
          <TextEditor
            initialValue={specializationDescription}
            onEditorChange={setSpecializationDescription}
          />
          <DialogActions>
            <LoadingButton
              loading={saveLoading}
              variant="contained"
              onClick={() => {
                setSaveLoading(true);
                saveSpecialization();
              }}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* edit Specialization  */}
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          openEditExam();
          clearAllCache();
        }}
      >
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          onClick={() => {
            openEditExam();
            clearAllCache();
          }}
        />
        <DialogTitle>Edit Specialization</DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            fullWidth
            options={courseList.map((item) => item.courseName)}
            onChange={handleChange}
            value={
              courseId
                ? courseList.find((course) => course.id === courseId)
                    ?.courseName
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Course"
                variant="outlined"
                error={courseError}
                helperText={courseHelperText}
              />
            )}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Specialization Name *"
            name="name"
            value={specializationName}
            helperText={specializationNameHelperText}
            error={specializationNameError}
            onChange={(ev) => {
              setSpecializationName(ev.target.value);
              setSpecializationNameHelperText("");
              setSpecializationNameError(false);
            }}
          />
          <TextEditor
            value={specializationDescription}
            setValue={setSpecializationDescription}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={updateLoading}
            variant="contained"
            onClick={() => {
              setUpdateLoading(true);
              updateSpecialization();
            }}
          >
            update
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <DeleteModal
        DeleteFunction={deleteExam}
        handleIsDeleteModal={handleClose}
        Open={deleteConfirmation}
      />
    </>
  );
};

export default SpecializationList;
