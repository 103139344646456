import React, { useEffect, useState } from "react";
import {
  AppBar,
  Typography,
  Button,
  Stack,
  useMediaQuery,
  useTheme,
  ButtonGroup,
  ListItemIcon,
  MenuItem,
  Divider,
  Avatar,
  IconButton,
  CssBaseline,
  Menu,
  Drawer,
  List,
  Box,
  Grid,
  ListItemButton,
  ListItemText,
  ListItem,
  Input,
  InputAdornment,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../global/redux/action";
import SearchIcon from "@mui/icons-material/Search";
import { useCookies } from "react-cookie";
import { config } from "../../../config/config";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SignUp from "../../auth/signup/SignUp";
import Login from "../../auth/login/Login";
import { Logout, PersonAdd } from "@mui/icons-material";
import MenuItems from "./MenuItems";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { topCities, otherCities } from "./MenuItems";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { apiList, invokeApi } from "../../../services/apiServices";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const [cookies, setCookie] = useCookies();
  const globalState = useSelector((state) => state);
  const [programValue, setProgramValue] = useState("college");
  const { userData, userError, logout } = globalState.userReducer;
  const [addressDrawer, setAddressDrawer] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [filteredTopCities, setFilteredTopCities] = useState([]);
  const [filteredOtherCities, setFilteredOtherCities] = useState([]);
  const [programFetching, setProgramFetching] = useState(true);
  const [programList, setProgramList] = useState([]);
  const [activeProgram, setActiveProgram] = useState(null);
  const [fetchingCS, setFetchingCS] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [collegeSubMenuProgramDrawer, setCollegeSubMenuProgramDrawer] =
    useState(false);
  const [collegeSubMenuCourseDrawer, setCollegeSubMenuCourseDrawer] =
    useState(false);
  const [
    collegeSubMenuSpecializationDrawer,
    setCollegeSubMenuSpecializationDrawer,
  ] = useState(false);
  const [schoolProgramDrawer, setSchoolProgramDrawer] = useState(false);
  const [trainingProgramDrawer, setTrainingProgramDrawer] = useState(false);
  const handleCollegeSubMenuProgramDrawer = () => {
    setCollegeSubMenuProgramDrawer(!collegeSubMenuProgramDrawer);
    setOpenDrawer(false);
  };

  const handleCollegeSubMenuCourseDrawer = () => {
    setCollegeSubMenuCourseDrawer(!collegeSubMenuCourseDrawer);
    setCollegeSubMenuProgramDrawer(false);
    setOpenDrawer(false);
  };
  const handleCollegeSubMenuSpecializationDrawer = () => {
    setCollegeSubMenuSpecializationDrawer(!collegeSubMenuSpecializationDrawer);
    setCollegeSubMenuProgramDrawer(false);
    setCollegeSubMenuCourseDrawer(false);
    setOpenDrawer(false);
  };

  const handleSchoolProgramDrawer = () => {
    setSchoolProgramDrawer(!schoolProgramDrawer);
    setOpenDrawer(false);
  };
  const handleTrainingProgramDrawer = () => {
    setTrainingProgramDrawer(!trainingProgramDrawer);
    setOpenDrawer(false);
  };

  const handleIsSignUpModal = () => {
    setOpenSignUp(!openSignUp);
  };
  const handleIsLoginModal = () => {
    setOpenLogin(!openLogin);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openProfileMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddressDrawer = () => {
    setAddressDrawer((editing) => !editing);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    // Filter top cities
    const filteredTop = topCities.filter((city) =>
      city.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTopCities(filteredTop);

    // Filter other cities
    const filteredOther = otherCities.filter((cityName) =>
      cityName.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOtherCities(filteredOther);
  }, [searchText, topCities, otherCities]);

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  // When USER_LOGOUT action is dispatched, logout
  useEffect(() => {
    if (logout) {
      navigate("/logout");
    }
  }, [logout, navigate]);

  // Generate sessionId, if not exists
  useEffect(() => {
    if (
      !cookies[config.sessionCookie] ||
      !cookies[config.sessionCookie].sessionId
    ) {
      let sessionId = uuidv4();
      setCookie(
        config.sessionCookie,
        JSON.stringify({
          sessionId: sessionId,
        }),
        { path: "/", maxAge: 3000000, sameSite: "strict" }
      );
    }
  }, [cookies, setCookie]);

  const dashboard = () => {
    if (userData?.users.passwordChangeOnLogin === "Yes") {
      navigate("/setPassword");
    } else if (userData?.users.passwordChangeOnLogin === "No") {
      navigate("/dashboard");
    }
  };

  //  Address selection; if user not having address, try to fetch current location
  useEffect(() => {
    const getAddressCurrentLocation = (lat, lng) => {
      let url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        lat +
        "," +
        lng +
        "&key=" +
        config.googleMapKey;

      fetch(url, {
        method: "get",
      }).then((response) => {
        response.json().then((res) => {
          if (res.results.length > 0) {
            let data = res.results[0].address_components;
            if (data.length > 0) {
              let cityVal = "";
              let stateVal = "";
              data.forEach((element) => {
                if (element.types.includes("locality")) {
                  cityVal = element.long_name;
                } else if (
                  element.types.includes("administrative_area_level_1")
                ) {
                  stateVal = element.long_name;
                }
              });
              setCookie(
                config.preferencesCookie,
                JSON.stringify({
                  city: cityVal,
                  state: stateVal,
                }),
                { path: "/", maxAge: 3000000, sameSite: "strict" }
              );
              console.log(cookies);
            }
          }
        });
      });
    };

    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            getAddressCurrentLocation(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          // failure call back
          (error) => {
            getAddressCurrentLocation(
              config.defaultLocation.latitude,
              config.defaultLocation.longitude
            );
            console.log(error);
          }
        );
      } else {
        getAddressCurrentLocation(
          config.defaultLocation.latitude,
          config.defaultLocation.longitude
        );
      }
    };

    if (
      !cookies[config.preferencesCookie] ||
      !cookies[config.preferencesCookie].city
    ) {
      getCurrentLocation();
    }
  }, [cookies]);

  useEffect(() => {
    const getAllPrograms = async () => {
      let params = {
        status: "Active",
        instituteType: programValue,
      };

      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );

      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          const firstProgram = response.data.programs[0]?.programName;
          setProgramList(response.data.programs);
          setActiveProgram(firstProgram);
          setFetchingCS(true);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };

    // Check if programs are still fetching
    if (programFetching) {
      getAllPrograms();
      setProgramFetching(false);
    }
  }, [cookies, programFetching]);

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
        programId: activeProgram,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the course list Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the course list Details. Please try again later!!"
        );
      }
    };
    const getAllSpecializationName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAllSpecialization,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setSpecializationList(response.data.specializations);
        } else {
          alert(
            "Something went wrong while getting the specialization list Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the specialization list Details. Please try again later!!"
        );
      }
    };

    if (activeProgram && fetchingCS) {
      getAllCourseName();
      getAllSpecializationName();
      setFetchingCS(false);
    }
  }, [cookies, activeProgram, fetchingCS]);

  return (
    <>
      <CssBaseline />
      <AppBar
        position="static"
        sx={{
          textAlign: "center",
          width: "100%",
          py: 1,
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%), #C8EBFE",
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: "strong" }}>
          {isMatch
            ? "Start Your College Discovery... "
            : `Start Your College Discovery... Top Colleges in India based on ${new Date().getFullYear()} Ranking`}
        </Typography>
      </AppBar>

      <AppBar
        sx={{
          width: "100%",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%), #162E7C",
        }}
        position="static"
      >
        {isMatch ? (
          <>
            <Box sx={{ display: "flex" }}>
              <IconButton
                sx={{ color: "white" }}
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Stack
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "7px",
                  marginBottom: "7px",
                }}
              >
                <Link to={"/"}>
                  <img src="/logo-1.png" alt="logo" height="40px" />
                </Link>
              </Stack>
            </Box>
          </>
        ) : (
          <>
            <Grid container sx={{ py: 1, px: 3 }}>
              <Grid
                item
                md={2}
                onClick={() => {
                  navigate("/");
                }}
                sx={{ cursor: "pointer" }}
              >
                <img src="/logo-1.png" alt="logo" height="40px" />
              </Grid>
              <Grid
                item
                md={userData?.users.roles ? 9 : 8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {/* <MenuItems /> */}
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  onClick={() => navigate("/")}
                >
                  Home
                </Button>
                <MenuItems
                  name={cookies[config.preferencesCookie]?.city || "Bengaluru"}
                />
                <MenuItems name="Colleges" />
                <MenuItems name="Schools" />
                <MenuItems name="Training Institutes" />
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  onClick={() => navigate("/about-us")}
                >
                  About Us
                </Button>
                <Button
                  variant="text"
                  sx={{ color: "whitesmoke", textTransform: "capitalize" }}
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </Button>
              </Grid>
              <Grid
                md={!userData?.users.roles ? 2 : 1}
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {cookies[config.cookieName]?.loginUserId ? (
                  <>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={
                        openProfileMenu ? "account-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openProfileMenu ? "true" : undefined}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          textTransform: "uppercase",
                        }}
                      >
                        {userData?.users.name[0]}
                      </Avatar>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={openProfileMenu}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      {userData?.users.roles.includes("Student") ? (
                        <MenuItem onClick={handleClose}>
                          <Avatar /> Profile
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            dashboard();
                          }}
                        >
                          <ListItemIcon>
                            <PersonAdd fontSize="small" />
                          </ListItemIcon>
                          DashBoard
                        </MenuItem>
                      )}
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          navigate("/logout");
                        }}
                      >
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    {!isMd ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ textAlign: "center" }}>
                            99001 27097
                          </Typography>
                          <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            sx={{
                              flexDirection: { sm: "row", xs: "column" }, // Set to column under 600px
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                color: "white",
                                borderColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff", // Change to the desired hover color
                                  color: "#000", // Change to the desired hover text color
                                },
                              }}
                              size="small"
                              onClick={handleIsLoginModal}
                            >
                              Login
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{
                                color: "white",
                                borderColor: "#fff",
                                "&:hover": {
                                  backgroundColor: "#fff", // Change to the desired hover color
                                  color: "#000", // Change to the desired hover text color
                                },
                              }}
                              onClick={handleIsSignUpModal}
                              size="small"
                            >
                              SignUp
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </AppBar>

      <SignUp
        open={openSignUp}
        close={handleIsSignUpModal}
        loginOpen={handleIsLoginModal}
      />
      <Login
        open={openLogin}
        close={handleIsLoginModal}
        signUpOpen={handleIsSignUpModal}
      />

      {/* below the drawer for mobile version menu related  */}

      {/* below the drawer was mobile menu drawer  */}
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Box
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, rgba(52, 68, 73, 0.20) 0%, rgba(0, 0, 0, 0.00) 46.64%, rgba(14, 194, 251, 0.20) 85.38%), #162E7C",
            p: 1,
          }}
        >
          <Link to={"/"}>
            <img src="/logo-1.png" alt="logo" height="50px" />
          </Link>
        </Box>
        <Divider />
        <List sx={{ paddingTop: "0px" }}>
          {/* <MobileMenu /> */}
          <ListItemButton onClick={() => navigate("/")}>
            <ListItemIcon sx={{ color: "black" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText
              color="inherit"
              onClick={handleAddressDrawer}
              sx={{ fontSize: "13px" }}
            >
              {(cookies[config.preferencesCookie] &&
                cookies[config.preferencesCookie].city) ||
                "Bengaluru"}
            </ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleCollegeSubMenuProgramDrawer();
              setProgramValue("college");
            }}
          >
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Colleges" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleSchoolProgramDrawer();
              setProgramFetching(true);
              setProgramValue("school");
            }}
          >
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Schools" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleTrainingProgramDrawer();
              setProgramFetching(true);
              setProgramValue("training institute");
            }}
          >
            <ListItemIcon sx={{ color: "black" }}>
              {/* <KeyboardArrowDownIcon /> */}
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Training Institutes" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/about-us")}>
            <ListItemIcon sx={{ color: "black" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="About Us" />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/contact-us")}>
            <ListItemIcon sx={{ color: "black" }}>
              <KeyboardArrowRightIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItemButton>
        </List>
        {!cookies[config.cookieName]?.loginUserId ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#000",
                  "&:hover": {
                    backgroundColor: "#fff", // Change to the desired hover color
                    color: "#000", // Change to the desired hover text color
                  },
                }}
                onClick={handleIsLoginModal}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#000",
                  "&:hover": {
                    backgroundColor: "#0000", // Change to the desired hover color
                    color: "#000", // Change to the desired hover text color
                  },
                }}
                onClick={handleIsSignUpModal}
              >
                SingUp
              </Button>
            </ButtonGroup>
          </Box>
        ) : null}
      </Drawer>

      {/* Address Drawer */}
      <Drawer
        open={addressDrawer}
        onClose={handleAddressDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton onClick={handleAddressDrawer}>
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <ListItem>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </ListItem>
          <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
            <ListItemText
              primary="Top Cities"
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
              }}
            />
          </ListItem>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              px: "15px",
              columnGap: "10px",
            }}
          >
            {filteredTopCities?.map((cities) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  my: "10px",
                  p: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCookie(
                    config.preferencesCookie,
                    JSON.stringify({
                      ...cookies[config.preferencesCookie],
                      city: cities.name,
                    }),
                    {
                      path: "/",
                      maxAge: 3000000,
                      sameSite: "strict",
                    }
                  );
                  handleClose();
                }}
                key={cities.name}
              >
                <img src={cities.svg} height="30px" alt="cities" />
                <Typography
                  variant="caption"
                  sx={{ "&:hover": { color: "blue" } }}
                >
                  {cities.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <ListItem sx={{ cursor: "default", fontWeight: "bold" }}>
            <ListItemText
              primary="Other Cities"
              sx={{
                fontSize: "13px",
                textDecoration: "underline",
              }}
            />
          </ListItem>
          {filteredOtherCities?.map((subitem, subIndex) => (
            <ListItem
              key={subIndex}
              button
              // onClick={handleMenuClose}
            >
              <ListItemText primary={subitem} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu college program submenu drawer  */}
      <Drawer
        open={collegeSubMenuProgramDrawer}
        onClose={handleCollegeSubMenuProgramDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleCollegeSubMenuProgramDrawer();
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {programList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => {
                setActiveProgram(item.id);
                handleCollegeSubMenuCourseDrawer();
              }}
            >
              <ListItemText primary={item.programName} />
              <ListItemIcon>
                <ArrowForwardIosIcon />{" "}
                {/* Replace YourEndIcon with your actual end icon component */}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu college course submenu drawer  */}
      <Drawer
        open={collegeSubMenuCourseDrawer}
        onClose={handleCollegeSubMenuCourseDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleCollegeSubMenuCourseDrawer();
              handleCollegeSubMenuProgramDrawer();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {courseList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => {
                handleCollegeSubMenuSpecializationDrawer();
                handleCollegeSubMenuCourseDrawer();
              }}
            >
              <ListItemText primary={item.courseName} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu college specialization submenu drawer  */}
      <Drawer
        open={collegeSubMenuSpecializationDrawer}
        onClose={handleCollegeSubMenuSpecializationDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleCollegeSubMenuSpecializationDrawer();
              handleCollegeSubMenuCourseDrawer();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {specializationList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => navigate("/colleges/college")}
            >
              <ListItemText primary={item.specialization} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu school submenu drawer  */}
      <Drawer
        open={schoolProgramDrawer}
        onClose={handleSchoolProgramDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleSchoolProgramDrawer();
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {programList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => navigate("/schools/school")}
            >
              <ListItemText primary={item.programName} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* mobile menu training submenu drawer  */}
      <Drawer
        open={trainingProgramDrawer}
        onClose={handleTrainingProgramDrawer}
        anchor="right"
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <IconButton
            onClick={() => {
              handleTrainingProgramDrawer();
              setOpenDrawer(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          {programList?.map((item, index) => (
            <ListItem
              key={index}
              button
              onClick={() => navigate("/training-institutes/school")}
            >
              <ListItemText primary={item.programName} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
