import { Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "./../home/DashboardHeader";
import DashboardNav from "./../home/DashboardNav";
import { Box } from "@mui/material";

const CollegeTabs = (props) => {
  const navigate = useNavigate();
  const { children, tabActive } = props;
  const [value, setValue] = useState(tabActive || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Use the navigate function to navigate to the desired route
    switch (newValue) {
      case 0:
        navigate("/college-list"); // Specify the route you want to navigate to
        break;
      case 1:
        navigate("/add-college");
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{ flexGrow: 1, p: 2, backgroundColor: "#F6F8FB" }}
        >
          <DashboardHeader />
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: "20px" }}
          >
            <Tab label="Colleges" sx={{ backgroundColor: "white" }} />
            <Tab label="Add College" sx={{ backgroundColor: "white" }} />
          </Tabs>
          <TabPanel value={value} index={tabActive}>
            {children}
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default CollegeTabs;
