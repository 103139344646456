import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "314px",
  bgcolor: "background.paper",

  border: "2px solid white",
  boxShadow: 24,

  p: 3,
};

const DeleteModal = ({ handleIsDeleteModal, DeleteFunction, Open }) => {
  return (
    <>
      <Modal
        open={Open}
        onClose={() => {
          handleIsDeleteModal();
        }}
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "center" }}>
            <img
              src="/media/svg/delete.svg"
              alt="delete"
              height="70px"
              width="70px"
            />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{ textAlign: "center", fontSize: "25px", color: "#5B5B5B" }}
            >
              Are you sure?
            </Typography>
            <Typography
              sx={{ color: "#95959B", fontSize: "13px", textAlign: "center" }}
            >
              Do you really want to delete these records? This Process Cannot be
              undone
            </Typography>
          </Box>
          <Box
            sx={{
              my: 2,
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button
              onClick={() => {
                handleIsDeleteModal();
              }}
              variant="contained"
              sx={{
                color: "#fff",
                padding: "7px 16px",
                backgroundColor: "#5B5B5B",
                "&:hover": {
                  backgroundColor: "#5B5B5B",
                },
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                backgroundColor: "#F5374D",
                padding: "7px 16px",
                "&:hover": {
                  backgroundColor: "#F5374D",
                },
              }}
              onClick={() => {
                DeleteFunction();
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteModal;
