import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../general-components/ui-components/Header";
import Footer from "../../general-components/ui-components/Footer";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <section className="first-class">
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "640px",
              md: "768px",
              lg: "1024px",
              xl: "1536px",
            },
            marginX: "auto",
          }}
        >
          <div className="second-class" style={{ px: { sm: "5rem" } }}>
            <h1 className="third-class">404</h1>
            <h3 className="fourth-class">opps! page not found</h3>
            <p className="font-medium">
              Lost in Cyberspace? Sorry, the page you're looking for has
              vanished. Head back to safety by clicking the homepage button.
            </p>
            <div className="flex-wrap">
              <button
                className="border-transparent"
                onClick={() => navigate("/")}
              >
                Back To Home
              </button>
              <button
                className="border-2"
                onClick={() => navigate("/contact-us")}
              >
                Report Problem
              </button>
            </div>
          </div>
        </Box>
      </section>
      <Footer />
    </>
  );
};

export default PageNotFound;
