import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Typography, Box, useMediaQuery, Rating } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link } from "react-router-dom";
import LeadModal from "./LeadModal";

export default function SingleCollegeCard(props) {
  const [openLeadModal, setLeadModalOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleClose = () => {
    setLeadModalOpen((close) => !close);
  };
  const [btnValue, setBtnValue] = useState("apply");
  const {
    collegeLogoPath,
    collegeName,
    district,
    state,
    id,
    seoOgUrl,
    courseFee,
    brochurePath,
  } = props;

  function formatNumber(value) {
    // Convert the value to a number
    const numericValue = parseFloat(value);

    // Check if the value is greater than or equal to 1000
    if (numericValue >= 1000) {
      // Divide the value by 1000 and round to one decimal place
      const formattedValue = (numericValue / 1000).toFixed(1);

      // Add 'k' as a suffix
      return `${formattedValue}k`;
    } else if (numericValue >= 100000) {
      const formattedValue = (numericValue / 100000).toFixed(2);
      return `${formattedValue}L`;
    }

    // If the value is less than 1000, return the original value
    return value;
  }

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Card
        sx={{
          maxWidth: { xs: "auto", sm: "385px" },
          height: "auto",
          borderRadius: "40px",
          border: "1px solid #7C7E80",
          boxShadow: "0px 0px 3px rgba(124, 126, 128, 1)",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Box display={"flex"} marginTop={"15px"} height={"102px"}>
          <Link to={`/institute/${seoOgUrl}/info/${id}`}>
            <Box
              padding={"15px"}
              sx={{ cursor: "pointer", overflow: "hidden" }}
            >
              <img
                src={collegeLogoPath}
                alt={collegeName}
                width={isSmallScreen ? "70.661px" : "90.112px"}
                height={isSmallScreen ? "70.661px" : "90.112px"}
                style={{ borderRadius: "50%" }}
              />
            </Box>
          </Link>
          <Box
            sx={{
              overflow: "hidden",
              cursor: "pointer",
              whiteSpace: "nowrap",
              display: "grid",
              placeItems: "center",
              textOverflow: "ellipsis",
              height: "100px",
              width: "213px",
            }}
          >
            <Link
              to={`/institute/${seoOgUrl}/info/${id}`}
              style={{ textDecoration: "none", color: "#000" }}
            >
              <Typography sx={{ fontSize: { md: "20px", xs: "10px" } }}>
                {collegeName}
              </Typography>
              <Typography sx={{ fontSize: "13px" }}>
                {district + "," + state}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Approx Fee:{formatNumber(courseFee)}
              </Typography>
              <Box sx={{ display: "flex" }}>
                <Rating
                  name="size-small"
                  defaultValue={2}
                  disabled
                  size="small"
                  style={{ fontSize: 15 }}
                />
                <Typography sx={{ fontSize: "9px" }}>(432 reviews)</Typography>
              </Box>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px",
            width: { sm: "344px", xs: "auto" },
            height: "auto",
            gap: "5px",
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              display: "none" /* Hide scrollbar for Chrome, Safari, and Edge */,
            },
            scrollbarWidth: "none" /* Hide scrollbar for Firefox */,
          }}
        >
          <Link to={`/institute/${seoOgUrl}/admission/${id}`}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000",
                width: "auto",
                height: "37px",
                fontSize: { xs: "9.985px", sm: "13px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                borderColor: "#95959B",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: 16,
                  color: "#717D82",
                  paddingRight: "5px",
                  marginBottom: "3px",
                }}
              />
              Admissions 2024
            </Button>
          </Link>
          <Link to={`/institute/${seoOgUrl}/cutoff/${id}`}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000000",
                height: "37px",
                fontSize: { xs: "9.985px", sm: "13px" },
                textTransform: "none",
                whiteSpace: "nowrap",
                borderColor: "#95959B",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: 16,
                  color: "#717D82",
                  paddingRight: "5px",
                  marginBottom: "3px",
                }}
              />
              Cut Off
            </Button>
          </Link>
          <Link to={`/institute/${seoOgUrl}/placement/${id}`}>
            <Button
              variant="outlined"
              sx={{
                borderColor: "#F5F5F5",
                color: "#000000",
                width: "103px",
                height: "37px",
                fontSize: { xs: "9.985px", sm: "13px" },
                borderColor: "#95959B",
                textTransform: "none",
              }}
            >
              <FiberManualRecordIcon
                style={{
                  fontSize: 16,
                  color: "#717D82",
                  paddingRight: "5px",
                  marginBottom: "3px",
                }}
              />
              Placements
            </Button>
          </Link>
        </Box>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            widt: "317px",
            height: "60x",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#F5F5F5",
              color: "#F5374D",
              borderRadius: "40px",
              border: "1px solid #7C7E80",
              boxShadow: "1px 1px 2px #7C7E80",
              fontWeight: "bold",
              width: "150px",
              height: "40x",
              fontSize: isMobile ? "15px" : "20px",
              textTransform: "uppercase",
            }}
            onClick={() => {
              setBtnValue("brochure");
              setLeadModalOpen(true);
            }}
          >
            Brochure
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#405189",
              color: "white",
              borderRadius: "40px",
              fontWeight: "bold",
              width: "150px",
              height: "40x",
              fontSize: isMobile ? "15px" : "20px",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "#405189",
              },
            }}
            onClick={() => {
              setBtnValue("apply");
              setLeadModalOpen(true);
            }}
          >
            APPLY NOW
          </Button>
        </CardActions>
      </Card>
      <LeadModal
        collegeId={id}
        btnValue={btnValue}
        brochurePath={brochurePath}
        openLeadModal={openLeadModal}
        handleClose={handleClose}
      />
    </>
  );
}
