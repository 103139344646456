import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import React from "react";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import AddIcon from "@mui/icons-material/Add";
import {
  apiList,
  invokeFormDataApi,
  invokeApi,
} from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import DeleteModal from "./DeleteModal";
import { LoadingButton } from "@mui/lab";

const UniversitiesList = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [fileHelperText, setFileHelperText] = useState("");
  const [previewFile, setPreviewFile] = useState("");

  const [maxFileSizeErr, setMaxFileSizeErr] = useState("");
  const [rating, setRating] = useState(0);
  const [universityName, setUniversityName] = useState("");
  const [universityNameError, setUniversityNameError] = useState(false);
  const [universityNameHelperText, setUniversityNameHelperText] = useState("");

  const [isUniversityLoading, setIsUniversityLoading] = useState(true);

  const [id, setId] = useState("");
  const [updateUniversityLogoPath, setUpdateUniversityLogoPath] = useState("");

  // university data fetch
  const [search, setSearch] = useState("");
  const [universityData, setUniversityData] = useState([]);
  const [universityFilter, setUniversityFilter] = useState([]);

  const handleIsAddModal = () => {
    setAddModal(!addModal);
  };
  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };
  const handleIsDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const clearAllCache = () => {
    setUpdateUniversityLogoPath("");
    setId("");
    setUniversityNameHelperText("");
    setUniversityNameError(false);
    setUniversityName("");
    setRating(0);
    setMaxFileSizeErr("");
    setPreviewFile("");
    setFileHelperText("");
    setFileError(false);
    setUploadedFile(null);
    setSaveLoading(false);
    setUpdateLoading(false);
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  // university logo upload validate
  const handleFileUpload = (ev) => {
    const fileUploaded = ev.target.files[0];
    let acceptProfileFileTypes = fileUploaded.type.match(
      /^image\/(jpe?g|png|gif)/
    );

    if (fileUploaded && acceptProfileFileTypes) {
      if (fileUploaded.size < 512 * 512) {
        setUploadedFile(fileUploaded);
        setPreviewFile(URL.createObjectURL(fileUploaded));
        setMaxFileSizeErr("");
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;
          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 512 * 512;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], fileUploaded.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
                // const compressedSize = compressedFile.size;

                setUploadedFile(compressedFile);
                setPreviewFile(URL.createObjectURL(compressedFile));
                setMaxFileSizeErr("");
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(fileUploaded);
      }
    } else {
      setMaxFileSizeErr(
        "Please upload a valid image in jpeg/jpg/png/gif format"
      );
    }
  };

  const handleUploadFile = async (ev) => {
    ev.preventDefault();
    setSaveLoading(true);
    var validation = true;
    if (!uploadedFile) {
      setFileError(true);
      setFileHelperText("Please Upload University Logo");
      setSaveLoading(false);
      validation = false;
    }
    if (!universityName) {
      setUniversityNameError(true);
      setUniversityNameHelperText("Please Enter University Name");
      setSaveLoading(false);
      validation = false;
    }
    // upload the profile pic first, if exist
    if (uploadedFile && validation) {
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "university_logo");

      let response1 = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );

      if (response1.status >= 200 && response1.status < 300) {
        if (response1.data.responseCode === "200") {
          handleSaveUniversity(response1.data.url);
        } else {
          alert(
            "Something went wrong while uploading profile picture. Please try again later!"
          );
        }
      } else if (response1.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while uploading profile picture. Please try again later!!"
        );
      }
    }
  };
  const handleUpdateUploadFile = async (ev) => {
    ev.preventDefault();
    setUpdateLoading(true);
    var validation = true;
    if (!uploadedFile && !updateUniversityLogoPath) {
      setFileError(true);
      setFileHelperText("Please Upload University Logo");
      setUpdateLoading(false);
      validation = false;
    }
    if (!universityName) {
      setUniversityNameError(true);
      setUniversityNameHelperText("Please Enter University Name");
      setUpdateLoading(false);
      validation = false;
    }
    if (uploadedFile && validation) {
      // upload the profile pic first, if exist
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "university_logo");

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUpdateUniversityLogoPath(response.data.url);
          updateUniversity(response.data.url);
        } else {
          alert(
            "Something went wrong while uploading profile picture. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while uploading profile picture. Please try again later!!"
        );
      }
    } else if (updateUniversityLogoPath && validation) {
      setUpdateUniversityLogoPath(updateUniversityLogoPath);
      updateUniversity(updateUniversityLogoPath);
    }
  };

  const handleSaveUniversity = async (url) => {
    let params = {
      universityName: universityName,
      universityLogoPath: url,
      universityRating: rating,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.saveUniversity,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("University Saved SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setIsUniversityLoading(true);
        handleIsAddModal();
        clearAllCache();
        // alert("done");
      } else if (response.data.responseCode === "GM003") {
        handleIsAddModal();
        toast.info("University Already Exist!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        clearAllCache();
      } else {
        alert(
          "Something went wrong while saving the university Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while saving the university  Details. Please try again later!!"
      );
    }
  };

  // list the unviersity
  useEffect(() => {
    const getAllUniversity = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getUniversities,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUniversityData(response.data.universities);
          setUniversityFilter(response.data.universities);
          setIsUniversityLoading(false);
        } else {
          alert(
            "Something went wrong while getting the University Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the University Details. Please try again later!!"
        );
      }
    };
    if (isUniversityLoading) {
      getAllUniversity();
    }
  }, [cookies, isUniversityLoading]);

  const deleteUniversity = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateUniversity,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        handleIsDeleteModal();
        toast.success("University Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setIsUniversityLoading(true);
        setId("");
      } else if (response.data.responseCode === "GM002") {
        handleIsDeleteModal();
        toast.info(
          "This Scholarship Mapped to Some College You Can't Delete This University!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else {
        alert(
          "Something went wrong while delete University Details. Please try again later!"
        );
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else if (response.data.responseCode === "GM002") {
      handleIsDeleteModal();
      alert(
        "This University Mapped to Some College You Can't Delete This University!"
      );
    } else {
      alert(
        "Something went wrong while delete University Details. Please try again later!!"
      );
    }
  };

  const updateUniversity = async (url) => {
    setUpdateLoading(true);
    var params = {
      id: id,
      universityName: universityName,
      universityRating: rating,
      universityLogoPath: url,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateUniversity,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("University Updated SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleIsEditModal();
        clearAllCache();
        setIsUniversityLoading(true);

        setId("");
      } else if (response.data.responseCode === "GM003") {
        handleIsEditModal();
        toast.info("University Already Exist!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        clearAllCache();
      } else {
        alert(
          "Something went wrong while update university Details. Please try again later!"
        );
        setUpdateLoading(false);
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while update university Details. Please try again later!!"
      );
      setUpdateLoading(false);
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },

    {
      name: "University Name",
      selector: (row) => row.universityName,
      sortable: true,
    },
    {
      name: "University Logo",
      selector: (row) => (
        <img
          src={row.universityLogoPath}
          height={50}
          alt={row.universityName}
        />
      ),
    },
    {
      name: "University Rating",
      selector: (row) => (
        <Rating name="disabled" value={row.universityRating} disabled />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setUniversityName(row.universityName);
                  setId(row.id);
                  setUpdateUniversityLogoPath(row.universityLogoPath);
                  setRating(row.universityRating);
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setUniversityName(row.universityName);
                  setId(row.id);
                  setUpdateUniversityLogoPath(row.universityLogoPath);
                  setRating(row.universityRating);
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  handleIsDeleteModal(true);
                  setId(row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = universityData.filter((item) => {
      return item.universityName
        .toLowerCase()
        .match(search.toLocaleLowerCase());
    });
    setUniversityFilter(result);
  }, [search]);

  return (
    <>
      <CollegeMetadataTabs tabActive={7}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={handleIsAddModal}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          columns={columns}
          data={universityFilter}
          pagination
          // selectableRows
          fixedHeader
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isUniversityLoading}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        ></DataTable>
      </CollegeMetadataTabs>
      {/* delete modal  */}
      <DeleteModal
        DeleteFunction={deleteUniversity}
        handleIsDeleteModal={handleIsDeleteModal}
        Open={deleteModal}
      />
      {/* universities list modal  */}
      <Modal
        open={addModal}
        onClose={() => {
          handleIsAddModal();
          clearAllCache();
        }}
        sx={{
          backgroundColor: `rgba(0,0,0, 0.6)`,
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsAddModal();
                clearAllCache();
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="large" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <form onSubmit={handleUploadFile} style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
              }}
            >
              {/* File upload */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="profile-pic"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />

                {uploadedFile && !maxFileSizeErr ? (
                  <label htmlFor="profile-pic">
                    <IconButton component="span">
                      <Box
                        component="img"
                        src={previewFile}
                        alt="profile"
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                          boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                        }}
                      />
                    </IconButton>
                  </label>
                ) : (
                  <>
                    <label htmlFor="profile-pic">
                      <IconButton component="span">
                        <Avatar
                          sx={{
                            width: 100,
                            height: 100,
                            background: "#FFFFFF",
                            boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                          }}
                        >
                          <Box
                            component={"img"}
                            src="/media/svg/user-profile.svg"
                            sx={{ width: 56, height: 56 }}
                            alt="profile"
                          />
                        </Avatar>
                      </IconButton>
                    </label>
                    <Typography variant="bodyregular" color={"error"}>
                      {maxFileSizeErr}
                    </Typography>
                  </>
                )}
              </Box>
              {fileError ? (
                <Typography variant="bodyparagraph" sx={{ color: "red" }}>
                  {fileHelperText} *
                </Typography>
              ) : (
                <Typography variant="bodyparagraph">
                  Upload University Logo *
                </Typography>
              )}
              <Box>
                <TextField
                  id="outlined-basic"
                  label="University Name *"
                  variant="outlined"
                  autoComplete="off"
                  value={universityName}
                  sx={{ my: 2 }}
                  onChange={(e) => {
                    setUniversityName(e.target.value);
                    setUniversityNameError(false);
                    setUniversityNameHelperText("");
                  }}
                  error={universityNameError}
                  helperText={universityNameHelperText}
                  fullWidth
                />
                <Box sx={{ my: 2 }}>
                  <Typography component="legend">University Rating</Typography>
                  <Rating
                    name="Rating"
                    value={rating}
                    onChange={(item, newValue) => {
                      setRating(newValue);
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
              <LoadingButton
                loading={saveLoading}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* universities edit list modal  */}
      <Modal
        open={editModal}
        onClose={() => {
          handleIsEditModal();
          clearAllCache();
        }}
        sx={{
          backgroundColor: `rgba(0,0,0, 0.6)`,
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsEditModal();
                clearAllCache();
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="large" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <form onSubmit={handleUpdateUploadFile} style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
              }}
            >
              {/* File upload */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="profile-pic"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />

                {uploadedFile && !maxFileSizeErr ? (
                  <label htmlFor="profile-pic">
                    <IconButton component="span">
                      <Box
                        component="img"
                        src={previewFile}
                        alt="profile"
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                          boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                        }}
                      />
                    </IconButton>
                  </label>
                ) : (
                  <>
                    <label htmlFor="profile-pic">
                      <IconButton component="span">
                        <Avatar
                          sx={{
                            width: 100,
                            height: 100,
                            background: "#FFFFFF",
                            boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={updateUniversityLogoPath}
                            alt="university"
                            sx={{ width: 56, height: 56 }}
                          />
                        </Avatar>
                      </IconButton>
                    </label>
                    <Typography variant="bodyregular" color={"error"}>
                      {maxFileSizeErr}
                    </Typography>
                  </>
                )}
              </Box>
              {fileError ? (
                <Typography variant="bodyparagraph" sx={{ color: "red" }}>
                  {fileHelperText} *
                </Typography>
              ) : (
                <Typography variant="bodyparagraph">
                  Upload University Logo *
                </Typography>
              )}
              <Box>
                <TextField
                  id="outlined-basic"
                  label="University Name *"
                  variant="outlined"
                  value={universityName}
                  autoComplete="off"
                  sx={{ my: 2 }}
                  onChange={(e) => {
                    setUniversityName(e.target.value);
                    setUniversityNameError(false);
                    setUniversityNameHelperText("");
                  }}
                  error={universityNameError}
                  helperText={universityNameHelperText}
                  fullWidth
                />
                <Box sx={{ my: 2 }}>
                  <Typography component="legend">University Rating</Typography>
                  <Rating
                    name="simple-controlled"
                    value={rating}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
              <LoadingButton
                loading={updateLoading}
                type="submit"
                variant="contained"
              >
                update
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default UniversitiesList;
