import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { apiList, invokeApi } from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useCookies } from "react-cookie";
import MarketingExecutiveMappingTabs from "./MarketingExecutiveMappingTabs";

const MarketingExecutiveMappingView = () => {
  const [mktngExec, setMktngExec] = useState("");
  const [mappingData, setMappingData] = useState([]);
  const [collegesList, setCollegesList] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [cookies] = useCookies();
  const [deletedCollegeIds, setDeletedCollegeIds] = useState([]);

  useEffect(() => {
    const getTeamLeaderMappingList = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getMarketingExecutiveMapping,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setMappingData(response.data.collegeMktngExecs);
        } else {
          alert(
            "Something went wrong while getting the Mapping Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Mapping Details. Please try again later!!"
        );
      }
    };
    const getAllColleges = async () => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegesList(
            response.data.colleges?.filter((item) => item.status === "Active")
          );
        } else {
          alert(
            "Something went wrong while getting the Colleges Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Colleges Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getAllColleges();
      getTeamLeaderMappingList();
      setFetching(false);
      setUpdateLoading(false);
    }
  }, [cookies, fetching]);

  const removeCollege = (index, collegeId) => {
    // Add the collegeId to the state array
    setDeletedCollegeIds([...deletedCollegeIds, collegeId]);
  };

  const removeCollegeMktngExec = async (e) => {
    e.preventDefault();
    setUpdateLoading(true);
    var validation = false;
    if (deletedCollegeIds.length <= 0) {
      alert("Please Select Team leader and Remove atleast 1 college");
      validation = true;
    }
    if (!validation) {
      let params = {
        mktngUserId: mktngExec,
        collegeIds: deletedCollegeIds,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.removeMarketingExecutiveMapping,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          alert("Deleted");
          setMktngExec("");
          setDeletedCollegeIds([]);
          setFetching(true);
        } else {
          alert(
            "Something went wrong while Update College Team Leader Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while Update Team Leader Details. Please try again later!!"
        );
      }
    }
  };
  return (
    <>
      <MarketingExecutiveMappingTabs tabActive={2}>
        <TableContainer>
          <form onSubmit={removeCollegeMktngExec}>
            <FormControl sx={{ my: 1 }} fullWidth>
              <InputLabel id="select-team-leader">
                Select Team Leader
              </InputLabel>
              <Select
                labelId="select-team-leader"
                id="select-team-leader"
                value={mktngExec}
                label="Team Leader"
                onChange={(e) => setMktngExec(e.target.value)}
              >
                {mappingData ? (
                  mappingData.map((e) => (
                    <MenuItem value={e.mktngUserId} key={e.mktngUserId}>
                      {e.mktngExecName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <Typography>Loading...</Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sl No</TableCell>
                  <TableCell>College Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {mktngExec ? (
                <TableBody>
                  {mappingData
                    .filter((item) => item.mktngUserId === mktngExec)
                    .map((filteredItem, index) =>
                      filteredItem.colleges.map((collegeId, innerIndex) => {
                        if (deletedCollegeIds.includes(collegeId.id)) {
                          // If collegeId is in the deletedCollegeIds array, skip rendering
                          return null;
                        }
                        const foundCollegeDetails = collegesList.find(
                          (collegeDetails) => collegeDetails.id === collegeId.id
                        );

                        return foundCollegeDetails ? (
                          <TableRow key={innerIndex}>
                            <TableCell>{innerIndex + 1}</TableCell>
                            <TableCell>
                              {foundCollegeDetails.collegeName}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  removeCollege(
                                    innerIndex,
                                    foundCollegeDetails.id
                                  )
                                }
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ) : null;
                      })
                    )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} style={{ textAlign: "center" }}>
                      <Typography variant="h5" color="gray">
                        Select Team Leader
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={
                  updateLoading || !mktngExec || deletedCollegeIds.length <= 0
                }
              >
                Update
                {updateLoading && <CircularProgress size={20} />}
              </Button>
            </Box>
          </form>
        </TableContainer>
      </MarketingExecutiveMappingTabs>
    </>
  );
};

export default MarketingExecutiveMappingView;
