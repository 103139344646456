export const mobileNoValidation = (mobileNo) => {
  const mobileNoRegex = /^[6-9][0-9]{9}$/;
  if (mobileNoRegex.test(mobileNo)) {
    return true;
  }
  return false;
};

export const otpValidation = (otp) => {
  const otpRegex = /^[0-9]{6}$/;
  if (otpRegex.test(otp)) {
    return true;
  }
  return false;
};

export const emailValidation = (email) => {
  return email.match(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  // Reference: https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
};

export const backdropStyle = {
  backdropFilter: "blur(1px)", // Adjust the blur value as needed
};
