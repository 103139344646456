import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import { Box, Card, Grid, Paper, Typography } from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";

const Facility = () => {
  const [college, setCollege] = useState([]);

  return (
    <>
      <CollegeDetailHeader activeTab={8} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }}>
              {college.collegeAmenities ? (
                <>
                  <br />
                  <Typography
                    variant="h5"
                    sx={{
                      backgroundColor: "#F2F2F2",
                      color: "#000",
                      fontFamily: "Open Sans",
                      fontSize: { xs: "14px", md: "18px" },
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    {college.collegeName} Infrastructure Facilities:
                  </Typography>
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "34px",
                      flexWrap: "wrap",
                    }}
                  >
                    {college.collegeAmenities.map((item, index) => (
                      <Card
                        sx={{
                          width: "111.439px",
                          height: "87.839px",
                          flexShrink: "0",
                          background: "#FFF",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={item.amenityLogoPath}
                          alt={item.amenityName}
                          width="50px"
                          height="50px"
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: "#000",
                            textAlign: "center",
                            fontFamily: "Open Sans",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                        >
                          {item.amenityName}
                        </Typography>
                      </Card>
                    ))}
                  </Box>
                  {college.collegeAmenities.map((item) => (
                    <>
                      <br />
                      <Typography
                        sx={{
                          color: "var(--Text-Color, #282828)",
                          fontFamily: "Open Sans",
                          fontSize: { xs: "14px", md: "18px" },
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                        }}
                      >
                        {item.amenityName}:
                      </Typography>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: college.amenityDescription,
                        }}
                        sx={{ my: 2 }}
                      />
                    </>
                  ))}
                </>
              ) : null}
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Facility;
