import React from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import { Box, Grid, Paper, Typography, Button } from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";
// import { Button } from "react-scroll";

const Results = () => {
  const [college, setCollege] = useState([]);
  return (
    <>
      <CollegeDetailHeader activeTab={7} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item md={8} xs={12}>
            <Paper sx={{ p: 3, height: "auto" }}>
              <Typography
                sx={{
                  backgroundColor: "#F2F2F2",
                  p: 1,
                  fontWeight: "bold",
                  color: "#000",
                  fontSize: { xs: "14px", md: "18px" },
                }}
                variant="subtitle1"
              >
                {college.collegeName} Result
              </Typography>
              <Box
                dangerouslySetInnerHTML={{
                  __html: college.resultsSummary,
                }}
                sx={{ my: 2 }}
              />
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Results;
