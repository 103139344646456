import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
  InputAdornment,
  Dialog,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCookies } from "react-cookie";
import {
  apiList,
  invokeFormDataApi,
  invokeApi,
} from "../../../services/apiServices";
import { config } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import CollegeMetadataTabs from "./CollegeMetadataTabs";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "../../../global/redux/action";
import DeleteModal from "./DeleteModal";
import { LoadingButton } from "@mui/lab";

const AmenitiesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const globalState = useSelector((state) => state);
  const { userData, userError } = globalState.userReducer;

  const [amenityName, setAmenityName] = useState("");

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [previewFile, setPreviewFile] = useState("");
  const [search, setSearch] = useState("");
  const [maxFileSizeErr, setMaxFileSizeErr] = useState("");

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [fileHelperText, setFileHelperText] = useState("");

  const [amenityNameError, setAmenityNameError] = useState(false);
  const [amenityNameHelperText, setAmenityNameHelperText] = useState("");

  const [isAmenitiesLoading, setIsAmenitiesLoading] = useState(true);

  const [amenititesData, setAmenititesData] = useState([]);
  const [amenitiesFilter, setAmenitiesFilter] = useState([]);
  const [id, setId] = useState("");

  const [updateAmenityLogoPath, setUpdateAmenityLogoPath] = useState("");

  const handleIsAddModal = () => {
    setAddModal(!addModal);
  };
  const handleIsEditModal = () => {
    setEditModal(!editModal);
  };
  const handleIsDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const clearAllError = () => {
    setFileError(false);
    setFileHelperText("");
    setSaveLoading(false);
    setId("");
    setUpdateAmenityLogoPath("");
    setAmenityName("");
    setUploadedFile(null);
    setUpdateLoading(false);
    setAmenityNameError(false);
    setAmenityNameHelperText("");
  };

  // On error when get user api is called
  useEffect(() => {
    if (userError) {
      alert(
        "Something went wrong while fetching user details. Please try again later!"
      );
    }
  }, [userError]);

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);

  const handleFileUpload = (ev) => {
    ev.preventDefault();
    const fileUploaded = ev.target.files[0];
    let acceptProfileFileTypes = fileUploaded.type.match(
      /^image\/(jpe?g|png|gif)/
    );

    if (fileUploaded && acceptProfileFileTypes) {
      if (fileUploaded.size < 512 * 512) {
        setUploadedFile(fileUploaded);
        setPreviewFile(URL.createObjectURL(fileUploaded));
        setMaxFileSizeErr("");
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const image = new Image();
          image.src = event.target.result;

          image.onload = () => {
            const canvas = document.createElement("canvas");
            const MAX_SIZE = 512 * 512;
            let width = image.width;
            let height = image.height;

            while (width * height > MAX_SIZE) {
              width *= 0.9;
              height *= 0.9;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const compressedFile = new File([blob], fileUploaded.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
                setUploadedFile(compressedFile);
                setPreviewFile(URL.createObjectURL(compressedFile));
                setMaxFileSizeErr("");
              },
              "image/jpeg",
              0.8
            );
          };
        };
        reader.readAsDataURL(fileUploaded);
      }
    } else {
      setMaxFileSizeErr(
        "Please upload a valid image in jpeg/jpg/png/gif format"
      );
    }
  };

  const handleUploadFile = async (ev) => {
    ev.preventDefault();
    setSaveLoading(true);
    var validation = true;
    if (!uploadedFile) {
      setFileError(true);
      setFileHelperText("Please Upload Amenity Image");
      setSaveLoading(false);
      validation = false;
    }
    if (!amenityName) {
      setAmenityNameError(true);
      setAmenityNameHelperText("Please Enter Amenity Name");
      setSaveLoading(false);
      validation = false;
    }
    // upload the profile pic first, if exist
    if (uploadedFile && validation) {
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "Amunity_logo");

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          handleSaveAmenity(response.data.url);
        } else {
          alert(
            "Something went wrong while uploading Amenity picture. Please try again later!"
          );
        }
      } else if (response?.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while uploading Amenity picture. Please try again later!!"
        );
      }
    }
  };

  const handleSaveAmenity = async (url) => {
    let params = {
      amenityName: amenityName,
      amenityLogoPath: url,
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.saveAmenities,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Amenity Saved SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleIsAddModal();
        clearAllError();
        setIsAmenitiesLoading(true);
        // alert("done");
      } else if (response.data.responseCode === "GM003") {
        toast.error("This Amenity Already Exist", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        alert(
          "Something went wrong while saving the Amenity Details. Please try again later!"
        );
        clearAllError();
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while saving the Amenity Details. Please try again later!!"
      );
      clearAllError();
    }
  };

  const deleteAmenities = async () => {
    let params = {
      id: id,
      status: "Deleted",
    };
    let response = await invokeApi(
      config.getMyCollege + apiList.updateAmenities,
      params,
      cookies
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.responseCode === "200") {
        toast.success("Amenity Deleted SuccessFully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleIsDeleteModal();
        clearAllError();
        setIsAmenitiesLoading(true);
      } else if (response.data.responseCode === "GM002") {
        toast.info(
          "This Amenity Mapped to Some College You Can't Delete This Amenity!",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        handleIsDeleteModal();
        clearAllError();
      } else {
        alert(
          "Something went wrong while delete Amenity Details. Please try again later!"
        );
        clearAllError();
      }
    } else if (response.status === 401) {
      navigate("/logout");
    } else {
      alert(
        "Something went wrong while delete Amenity Details. Please try again later!!"
      );
      clearAllError();
    }
  };

  const updateAmenities = async (url) => {
    if (url) {
      var params = {
        id: id,
        amenityName: amenityName,
        amenityLogoPath: url,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.updateAmenities,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          toast.success("Amenity Updated SuccessFully!", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          handleIsEditModal();
          clearAllError();

          setIsAmenitiesLoading(true);
        } else if (response.data.responseCode === "GM003") {
          handleIsEditModal();
          toast.error("This Amenity Already Exist", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          clearAllError();
        } else {
          alert(
            "Something went wrong while update Amenity Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while update Amenity Details. Please try again later!!"
        );
      }
    }
  };

  const handleUpdateUploadFile = async (ev) => {
    ev.preventDefault();
    setUpdateLoading(true);
    var validation = true;
    if (!uploadedFile && !updateAmenityLogoPath) {
      setFileError(true);
      setFileHelperText("Please Upload Amenity Image");
      setUpdateLoading(false);
      validation = false;
    }
    if (!amenityName) {
      setAmenityNameError(true);
      setAmenityNameHelperText("Please Enter Amenity Name");
      setUpdateLoading(false);
      validation = false;
    }
    // upload the profile pic first, if exist
    if (uploadedFile && validation) {
      let formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("path", "university_logo");

      let response = await invokeFormDataApi(
        config.getMyCollege + apiList.uploadFile,
        formData,
        cookies
      );

      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          updateAmenities(response.data.url);
          handleIsEditModal();
        } else {
          alert(
            "Something went wrong while uploading update Amenity picture. Please try again later!"
          );
          setUpdateLoading(false);
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while uploading update Amenity picture. Please try again later!!"
        );
        setUpdateLoading(false);
      }
    } else if (updateAmenityLogoPath && validation) {
      updateAmenities(updateAmenityLogoPath);
    }
  };

  // list the unviersity
  useEffect(() => {
    const getAllAmenitiesType = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAmenitites,
        params,
        cookies
      );

      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setAmenititesData(response.data.amenities);
          setAmenitiesFilter(response.data.amenities);
          setIsAmenitiesLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Amenity Details. Please try again later!"
          );
        }
      } else if (response.status === 401) {
        navigate("/logout");
      } else {
        alert(
          "Something went wrong while getting the Amenity Details. Please try again later!!"
        );
      }
    };
    if (isAmenitiesLoading) {
      getAllAmenitiesType();
    }
  }, [cookies, isAmenitiesLoading]);

  useEffect(() => {
    const result = amenititesData.filter((item) => {
      return item.amenityName.toLowerCase().match(search.toLocaleLowerCase());
    });
    setAmenitiesFilter(result);
  }, [search]);

  const columns = [
    {
      name: "Sl No",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: "15%",
    },
    {
      name: "Amenity Name",
      selector: (row) => row.amenityName,
      sortable: true,
    },
    {
      name: "Amenity Image",
      selector: (row) => (
        <img src={row.amenityLogoPath} height={50} alt={row.amenityName} />
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {userData?.users.roles.includes("Marketing Executive") ? (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setAmenityName(row.amenityName);
                  setId(row.id);
                  setUpdateAmenityLogoPath(row.amenityLogoPath);
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                color="success"
                onClick={() => {
                  setAmenityName(row.amenityName);
                  setId(row.id);
                  setUpdateAmenityLogoPath(row.amenityLogoPath);
                  handleIsEditModal();
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                color="error"
                onClick={() => {
                  setId(row.id);
                  handleIsDeleteModal();
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <CollegeMetadataTabs tabActive={3}>
        <Button
          variant="contained"
          sx={{ m: 1, borderRadius: "20px" }}
          onClick={handleIsAddModal}
        >
          <AddIcon></AddIcon>
          Add New
        </Button>
        <DataTable
          fixedHeader
          pagination
          highlightOnHover
          subHeaderAlign="left"
          progressPending={isAmenitiesLoading}
          data={amenitiesFilter}
          columns={columns}
          subHeader
          subHeaderComponent={
            <TextField
              type="text"
              size="small"
              placeholder="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{ my: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: {
                  borderRadius: 25,
                  border: "none",
                  outline: "none",
                  backgroundColor: "#fff",
                }, // Adjust the border radius as needed
              }}
            />
          }
        />
      </CollegeMetadataTabs>
      {/* delete  */}
      <DeleteModal
        handleIsDeleteModal={handleIsDeleteModal}
        DeleteFunction={deleteAmenities}
        Open={deleteModal}
      />
      {/* amenities add list modal  */}
      <Modal
        open={addModal}
        onClose={() => {
          handleIsAddModal();
          clearAllError();
        }} // Use an arrow function here
        sx={{
          backdropFilter: addModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsAddModal();
                clearAllError();
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="large" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <form onSubmit={handleUploadFile} style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
              }}
            >
              {/* File upload */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="profile-pic"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />

                {uploadedFile && !maxFileSizeErr ? (
                  <label htmlFor="profile-pic">
                    <IconButton component="span">
                      <Box
                        component="img"
                        src={previewFile}
                        alt="profile"
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                          boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                        }}
                      />
                    </IconButton>
                  </label>
                ) : (
                  <>
                    <label htmlFor="profile-pic">
                      <IconButton component="span">
                        <Avatar
                          sx={{
                            width: 100,
                            height: 100,
                            background: "#FFFFFF",
                            boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                          }}
                        >
                          <Box
                            component={"img"}
                            src="/media/svg/user-profile.svg"
                            sx={{ width: 56, height: 56 }}
                            alt="profile"
                          />
                        </Avatar>
                      </IconButton>
                    </label>
                    <Typography variant="bodyregular" color={"error"}>
                      {maxFileSizeErr}
                    </Typography>
                  </>
                )}
              </Box>
              {fileError ? (
                <Typography variant="bodyparagraph" sx={{ color: "red" }}>
                  {fileHelperText} *
                </Typography>
              ) : (
                <Typography variant="bodyparagraph">
                  Upload Amenity Image *
                </Typography>
              )}
              <Box>
                <TextField
                  id="select-amanity"
                  label="Amenity Name *"
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  sx={{ my: 2 }}
                  value={amenityName}
                  onChange={(ev) => {
                    setAmenityName(ev.target.value);
                    setAmenityNameError(false);
                    setAmenityNameHelperText("");
                  }}
                  error={amenityNameError}
                  helperText={amenityNameHelperText}
                  fullWidth
                />
              </Box>
              <LoadingButton
                loading={saveLoading}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* amenities edit list modal  */}
      <Modal
        open={editModal}
        onClose={() => {
          handleIsEditModal();
          clearAllError();
        }}
        sx={{
          backdropFilter: editModal ? "blur(1px)" : "none",
        }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <IconButton
              onClick={() => {
                handleIsEditModal();
                clearAllError();
              }}
              sx={{ float: "right", pb: 2 }}
            >
              <CloseIcon fontSize="large" color="#000" />
            </IconButton>
          </Box>
          <Divider />
          <form onSubmit={handleUpdateUploadFile} style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                gap: "10px",
              }}
            >
              {/* File upload */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="profile-pic"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />

                {uploadedFile && !maxFileSizeErr ? (
                  <label htmlFor="profile-pic">
                    <IconButton component="span">
                      <Box
                        component="img"
                        src={previewFile}
                        alt="profile"
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          objectFit: "cover",
                          boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                        }}
                      />
                    </IconButton>
                  </label>
                ) : (
                  <>
                    <label htmlFor="profile-pic">
                      <IconButton component="span">
                        <Avatar
                          sx={{
                            width: 100,
                            height: 100,
                            background: "#FFFFFF",
                            boxShadow: "0px 5px 25px rgba(42, 48, 55, 0.12)",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={updateAmenityLogoPath}
                            sx={{ width: 56, height: 56 }}
                            alt="amenity"
                          />
                        </Avatar>
                      </IconButton>
                    </label>
                    <Typography variant="bodyregular" color={"error"}>
                      {maxFileSizeErr}
                    </Typography>
                  </>
                )}
              </Box>
              {fileError ? (
                <Typography variant="bodyparagraph" sx={{ color: "red" }}>
                  {fileHelperText} *
                </Typography>
              ) : (
                <Typography variant="bodyparagraph">
                  Upload Amenity Image *
                </Typography>
              )}
              <Box>
                <TextField
                  id="select-amenity"
                  label="Amenity Name *"
                  variant="outlined"
                  type="text"
                  value={amenityName}
                  autoComplete="off"
                  sx={{ my: 2 }}
                  onChange={(e) => {
                    setAmenityName(e.target.value);
                    setAmenityNameError(false);
                    setAmenityNameHelperText("");
                  }}
                  error={amenityNameError}
                  helperText={amenityNameHelperText}
                  fullWidth
                />
              </Box>
              <LoadingButton
                loading={updateLoading}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default AmenitiesList;
