import React, { useEffect } from "react";
import CollegeDetailHeader from "./CollegeDetailHeader";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SideBar from "./SideBar";
import { mobileNoValidation } from "../../../../common/common";
import { toast } from "react-toastify";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { useParams } from "react-router-dom";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";

const Admission = () => {
  const { collegeId } = useParams();
  const [cookies] = useCookies();
  const [college, setCollege] = useState({});
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [courseNameList, setCourseNameList] = useState([]);
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [fullNameHelperText, setFullNameHelperText] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [mobileNumberHelperText, setMobileNumberHelperText] = useState("");
  const mobileNumberValidate = mobileNoValidation(mobileNumber);

  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [emailIdHelperText, setEmailIdHelperText] = useState("");

  const handleRemoveCourse = (prev) => {
    setSelectedCourse(selectedCourse.filter((r) => r !== prev.id));
  };
  const handleCourseChange = (event, newValue) => {
    const stringIds = newValue.map((company) => company.id.toString());

    setSelectedCourse(stringIds);
  };

  useEffect(() => {
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseNameList(response.data.courses);
        } else {
          alert(
            "Something went wrong while getting the College Types Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the College Types Details. Please try again later!!"
        );
      }
    };
    getAllCourseName();
  }, [cookies]);

  const saveLead = async (e) => {
    e.preventDefault();

    if (mobileNumberValidate) {
      let validationError = false;

      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
        validationError = true;
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
        validationError = true;
      }
      if (!validationError) {
        let params = {
          studentName: fullName,
          mobileNumber: mobileNumber,
          coursesList: selectedCourse,
          email: emailId,
          collegeId: collegeId,
        };
        let response = await invokeApi(
          config.getMyCollege + apiList.saveLead,
          params,
          cookies
        );
        if (response.status >= 200 && response.status < 300) {
          if (response.data.responseCode === "200") {
            toast.success("Lead Created Successfully!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setFullName("");
            setFullNameHelperText("");
            setFullNameError(false);

            setMobileNumber("");
            setMobileNumberError(false);
            setMobileNumberHelperText("");

            setEmailId("");
            setEmailIdError(false);
            setEmailIdHelperText("");

            setSelectedCourse("");
          } else {
            alert(
              "Something went wrong while saving the Leading Details. Please try again later!"
            );
          }
        } else {
          alert(
            "Something went wrong while saving the Leading Details. Please try again later!!"
          );
        }
      }
    } else {
      setMobileNumberHelperText("Please Enter Valid Mobile Number");
      setMobileNumberError(true);
      if (fullName === "") {
        setFullNameHelperText("Please enter your Full Name");
        setFullNameError(true);
      }
      if (emailId === "") {
        setEmailIdHelperText("Please enter your Email Id");
        setEmailIdError(true);
      }
    }
  };

  return (
    <>
      <CollegeDetailHeader activeTab={2} transferCollegeData={setCollege}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{ p: 3, minHeight: "auto", overflow: "auto", width: "100%" }}
            >
              {college.admissionSummary ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#F2F2F2",
                      p: 1,
                      fontWeight: "bold",
                      color: "#000",
                      fontSize: { md: "18px", xs: "14px" },
                      textAlign: { xs: "center", md: "initial" },
                    }}
                    variant="subtitle1"
                  >
                    Browse Admission Information Course-wise
                  </Typography>
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: college.admissionSummary,
                    }}
                    sx={{ my: 2 }}
                  />
                </>
              ) : null}
              {college.collegeCourses && college.collegeCourses.length > 0 ? (
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <colgroup>
                    <col style={{ width: "33%" }} />
                    <col style={{ width: "33%" }} />
                    <col style={{ width: "34%" }} />
                  </colgroup>
                  <thead>
                    <tr
                      fontSize={{ xs: "14px", md: "18px" }}
                      font
                      fontWeight={"400"}
                      style={{ backgroundColor: "#162E7C" }}
                    >
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          fontSize: { xs: "14px", md: "18px" },
                          color: "#fff",
                        }}
                      >
                        Course Name
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          color: "#fff",
                        }}
                      >
                        Details
                      </th>
                      <th
                        style={{
                          border: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          color: "#fff",
                        }}
                      >
                        Selection Criteria
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    sx={{ fontSize: { md: "18px", xs: "14px" } }}
                    fontWeight={"400"}
                  >
                    {college.collegeCourses.map((item) => (
                      <tr>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          {item.courseName}
                          {/* {college.collegeCourses} */}
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          <Typography paddingLeft={"20px"}>
                            <ul>
                              <li>{item.years + "/" + item.months}</li>
                              <li>Fees: {item.courseFee}/-</li>
                              <li>Eligibility: Graduation</li>
                            </ul>
                          </Typography>
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            textAlign: "left",
                            padding: "8px",
                          }}
                        >
                          <Typography paddingLeft={"20px"}>
                            <ul>
                              <li>
                                Research Experience of at least two years Fluent
                                in written and spoken.
                              </li>
                            </ul>
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
              {college.collegeCourses?.map((item) => (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: "10px",
                      fontSize: { xs: "14px", md: "18px" },
                      fontWeight: "600",
                    }}
                  >
                    {item.courseName} Specialization:
                  </Typography>
                  <Typography
                    paddingLeft={"30px"}
                    sx={{ fontSize: { xs: "14px", md: "18px" } }}
                    height="6"
                  >
                    <ul sx={{ paddingLeft: "20px" }}>
                      <li>{item.courseSpecializationName}</li>
                    </ul>
                  </Typography>
                </>
              ))}
            </Paper>
            <Paper sx={{ mt: 4, p: 2 }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={6}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRight: "2px solid #bdc3c7",
                  }}
                >
                  <Typography variant="h1" sx={{ fontSize: "3rem" }}>
                    Apply Now
                  </Typography>
                  <Typography>
                    Get Seat In Your Favorite College With Easy Way.
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Name"
                    value={fullName}
                    style={{ marginTop: { xs: "10px" } }}
                    onChange={(ev) => {
                      setFullName(ev.target.value);
                      setFullNameError(false);
                      setFullNameHelperText("");
                    }}
                    helperText={fullNameHelperText}
                    error={fullNameError}
                  ></TextField>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Mobile Number"
                    value={mobileNumber}
                    onChange={(ev) => {
                      setMobileNumber(ev.target.value);
                      setMobileNumberError(false);
                      setMobileNumberHelperText("");
                    }}
                    helperText={mobileNumberHelperText}
                    error={mobileNumberError}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <>
                            {/* flag and country code */}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "0px",
                                gap: "5px",
                              }}
                            >
                              <Box
                                component={"img"}
                                sx={{ width: "24px", height: "24px" }}
                                alt="india"
                                src="/media/svg/indianflag.svg"
                              />
                              <Typography
                                variant="bodyparagraph"
                                sx={{
                                  color: "text.primary",
                                }}
                              >
                                +91
                              </Typography>
                            </Box>
                            {/* vertical line */}
                            <Box
                              sx={{
                                width: { md: "26px", xs: "20px" },
                                height: "0px",
                                border: "0.5px solid #AAACAE",
                                transform: "rotate(90deg)",
                              }}
                            />
                          </>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                  ></TextField>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Email Id"
                    value={emailId}
                    onChange={(ev) => {
                      setEmailId(ev.target.value);
                      setEmailIdError(false);
                      setEmailIdHelperText("");
                    }}
                    helperText={emailIdHelperText}
                    error={emailIdError}
                  ></TextField>
                  <Autocomplete
                    multiple
                    id="scholarship-autocomplete"
                    options={courseNameList}
                    getOptionLabel={(option) => option.courseName}
                    value={courseNameList.filter((company) =>
                      selectedCourse.includes(company.id.toString())
                    )}
                    fullWidth
                    onChange={handleCourseChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Course"
                        fullWidth
                        margin="normal"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.courseName}
                          {...getTagProps({ index })}
                          onDelete={() => handleRemoveCourse(option)}
                        />
                      ))
                    }
                  />
                  <Button type="submit" onClick={saveLead} variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <SideBar />
        </Grid>
      </CollegeDetailHeader>
    </>
  );
};

export default Admission;
