import { Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "./../home/DashboardHeader";
import DashboardNav from "./../home/DashboardNav";
import { Box } from "@mui/material";

const CollegeMetadataTabs = (props) => {
  const navigate = useNavigate();
  const { children, tabActive } = props;
  const [value, setValue] = useState(tabActive || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Use the navigate function to navigate to the desired route
    switch (newValue) {
      case 2:
        navigate("/course"); // Specify the route you want to navigate to
        break;
      case 7:
        navigate("/university");
        break;
      case 3:
        navigate("/amenities");
        break;
      case 4:
        navigate("/placement-companies");
        break;
      case 0:
        navigate("/institute-types");
        break;
      case 5:
        navigate("/institute-brands");
        break;
      case 6:
        navigate("/scholarships");
        break;
      case 1:
        navigate("/program");
        break;
      case 8:
        navigate("/exam");
        break;
      case 9:
        navigate("/specialization");
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Box sx={{ display: "flex", height: "100vh" }}>
        <DashboardNav />
        <Box
          component="header"
          sx={{
            flexGrow: 1,
            p: 2,
            backgroundColor: "#F6F8FB",
            overflowX: "auto",
          }}
        >
          <DashboardHeader />
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ width: "100%" }}
          >
            <Tab label="Institute Type" sx={{ backgroundColor: "white" }} />
            <Tab label="Program" sx={{ backgroundColor: "white" }} />
            <Tab label="Course" sx={{ backgroundColor: "white" }} />
            <Tab label="Amenity" sx={{ backgroundColor: "white" }} />
            <Tab label="Placement Company" sx={{ backgroundColor: "white" }} />
            <Tab label="Institute Brand" sx={{ backgroundColor: "white" }} />
            <Tab label="Scholorship" sx={{ backgroundColor: "white" }} />
            <Tab label="University" sx={{ backgroundColor: "white" }} />
            <Tab label="Exam" sx={{ backgroundColor: "white" }} />
            <Tab label="Specialization" sx={{ backgroundColor: "white" }} />
          </Tabs>
          <TabPanel value={value} index={tabActive}>
            {children}
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default CollegeMetadataTabs;
