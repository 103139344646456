import { Tabs, Tab, Typography, Box, useMediaQuery } from "@mui/material";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CourseData from "./CourseData";
import { useState } from "react";

const TabBox = {
  // border: { xs: "none", md: " ridge " },
  borderBottom: "1px solid #F5F5F5",
  shadows: "gray",
  backgroundColor: { xs: "#F5F5F5", md: "white" },
  justifyContent: "Start",
  width: { xs: "30px", md: "200px" },
  minHeight: { xs: "30px", md: "100%" },
  height: { xs: "40px", md: "55px" },
  borderBottom: { xs: "0px", md: "1px" },
};

const NewsBlog = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isSmallScreen = useMediaQuery("(max-width:500px)");
  var isMobileScreeen = useMediaQuery("(max-width:1200px)");
  return (
    <>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", fontWeight: "bold", marginTop: "20px" }}
      >
        News From the Blog{" "}
      </Typography>
      {isSmallScreen ? (
        <Typography align="center">India's Leading Education Portal</Typography>
      ) : null}
      <Box
        sx={{
          display: "flex",
          paddingLeft: "5px",
          paddingTop: "5px",
          paddingBottom: "5px",
        }}
      >
        {!isMobileScreeen ? (
          <Box
            sx={{
              display: "flex",
              paddingLeft: "35px",
              paddingTop: "20px",
              paddingBottom: "15px",
            }}
          >
            <Box>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderColor: "divider",
                  boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25);",
                  color: "#5B5B5B",
                  paddingBottom: "1px",
                }}
              >
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="Degree"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="M.Tech"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="B.Tech"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="MBA"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="MCA"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="Degree"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="College"
                  sx={TabBox}
                />
                <Tab
                  icon={<CastForEducationIcon />}
                  iconPosition="start"
                  label="School"
                  sx={TabBox}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <CourseData />
            </TabPanel>
          </Box>
        ) : (
          <Box sx={{ width: "100%", paddingLeft: "1px" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider", height: "30px" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable prevent tabs example"
              >
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="Degree"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="M.Tech"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="B.Tech"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="MBA"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="MCA"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="Degree"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="College"
                  sx={TabBox}
                />
                <Tab
                  // icon={<CastForEducationIcon />}
                  // iconPosition="start"
                  label="School"
                  sx={TabBox}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <CourseData />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <CourseData />
            </TabPanel>
          </Box>
        )}
      </Box>
    </>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default NewsBlog;
