import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  Dialog,
  Grid,
  Alert,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { config } from "../../../config/config";
import { apiList, invokeApi } from "../../../services/apiServices";
import { useCookies } from "react-cookie";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../global/redux/action";
import { emailValidation } from "../../../common/common";

const Login = (props) => {
  const [cookies, setCookie] = useCookies();
  const [email, setEmail] = useState("");
  const emailValidate = emailValidation(email);
  const [emailError, setEmailError] = useState(false);
  const [pwdMismatch, setPwdMismatch] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();

  const globalState = useSelector((state) => state);
  const { userData } = globalState.userReducer;

  // password show and hide
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLogin(true);
    var validationError = true;
    if (!emailValidate) {
      setEmailError(true);
      setEmailHelperText("Please enter valid email address");
      validationError = false;
      setIsLogin(false);
    }
    if (!password) {
      setPasswordError(true);
      setPasswordHelperText("Please Enter Password");
      validationError = false;
      setIsLogin(false);
    }
    if (validationError) {
      let params = {
        email: email,
        password: password,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.userLogin,
        params,
        cookies
      );
      if (response?.status >= 200 && response?.status < 300) {
        if (response.data.responseCode === "200") {
          setIsLogin(false);
          setCookie(
            config.cookieName,
            JSON.stringify({
              token: response.data.token,
              loginUserId: response.data.userId,
            }),
            { path: "/", maxAge: 3000000, sameSite: "strict" }
          );
          props.close();
        } else if (response.data.responseCode === "HE001") {
          setIsLogin(false);
          // setOtpHelperText("Invalid OTP. Please enter correct OTP.");
        } else {
          alert("Something went wrong while login. Please try again later!");
          setIsLogin(false);
        }
      } else if (response.data.responseMessage.includes("Password missMatch")) {
        setPwdMismatch(true);
        setPasswordError(false);
        setPasswordHelperText("");
        setEmailError(false);
        setEmailHelperText("");
        setIsLogin(false);
      } else if (response.data.responseMessage.includes("No user found")) {
        setUserNotFound(true);
        setPasswordError(false);
        setPasswordHelperText("");
        setEmailError(false);
        setEmailHelperText("");
        setIsLogin(false);
      } else {
        alert("Something went wrong while login. Please try again later!!");
        setIsLogin(false);
      }
    }
  };

  useEffect(() => {
    if (cookies[config.cookieName]?.loginUserId && !userData?.users) {
      dispatch(
        getUser({ id: cookies[config.cookieName].loginUserId, cookies })
      );
    }
  }, [dispatch, cookies, userData]);
  return (
    <>
      <Dialog open={props.open} onClose={props.close} fullWidth maxWidth={"md"}>
        <Grid
          container
          sx={{
            py: { xs: "60px", md: "0px" },
            px: { xs: "20px", md: "0px" },
            position: "relative",
          }}
        >
          <Grid
            item
            md={5}
            sx={{
              backgroundColor: "#FBFBFB",
              padding: "75.923px 45.322px",
              display: { xs: "none", md: "block" },
            }}
          >
            <Box sx={{ width: "207px", pb: "18px" }}>
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: "400",
                  color: "#282828",
                  fontFamily: "Open Sans",
                }}
              >
                Login
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "400",
                  color: "#5B5B5B",
                  fontFamily: "Open Sans",
                }}
              >
                Get access to College Admissions brochures, favourites and
                dashboard
              </Typography>
            </Box>
            <Box sx={{ pt: "18px" }}>
              <img
                src="/media/svg/loginImage.svg"
                alt="Login"
                width="239px"
                height="219px"
              />
            </Box>
          </Grid>
          <Grid
            item
            md={7}
            xs={12}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: { xs: "block", md: "flex" },
            }}
          >
            <Box
              onClick={() => {
                props.close();
              }}
              component={"img"}
              sx={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
                mt: "5px",
                position: "absolute",
                right: { md: "20px", xs: "10px" },
                top: "20px",
                mr: "5px",
              }}
              src="/media/svg/cross-circled.svg"
              alt="close icon"
            />
            <form onSubmit={handleLogin}>
              <Box sx={{ width: { xs: "100%", md: "297px" } }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: "16px",
                  }}
                >
                  <img
                    src="/loginlogo.png"
                    alt="login logo"
                    width="112.966px"
                    height="86.087px"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#282828",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Continue with email
                  </Typography>
                  <TextField
                    fullWidth
                    value={email}
                    autoComplete="off"
                    error={emailError}
                    helperText={emailHelperText}
                    variant="outlined"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    sx={{ mt: "12px" }}
                  />
                  {userNotFound ? (
                    <Alert severity="error">User Not Found This Email!</Alert>
                  ) : null}
                  <TextField
                    fullWidth
                    error={passwordError}
                    helperText={passwordHelperText}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    sx={{ mt: "12px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {pwdMismatch ? (
                    <Alert severity="error">Please Check Your Password!</Alert>
                  ) : null}
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{ mt: "28px", py: "12px" }}
                    disabled={isLogin}
                  >
                    LOGIN
                    {isLogin && (
                      <CircularProgress
                        size={24}
                        sx={{ ml: 2, color: "white" }}
                      />
                    )}
                  </Button>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#282828",
                      mt: "16px",
                    }}
                  >
                    New to Get my Studies?{" "}
                    <Box
                      component="span"
                      sx={{
                        color: "#F5374D",
                        fontWeight: "600",
                        fontSize: "13px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        props.close();
                        props.signUpOpen();
                      }}
                    >
                      Create an account.
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Login;
