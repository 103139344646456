import React from "react";
import TrainingInstituteTabs from "./TrainingInstituteTabs";
import { Paper } from "@mui/material";

const TrainingInstitute = () => {
  function handleSave(e) {
    e.preventDefault();
  }
  return (
    <>
      <TrainingInstituteTabs tabActive={0}>
        <form onSubmit={handleSave}>
          <Paper sx={{ p: 2 }}></Paper>
        </form>
      </TrainingInstituteTabs>
    </>
  );
};

export default TrainingInstitute;
