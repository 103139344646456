import React, { useEffect, useState } from "react";
import Header from "../../general-components/ui-components/Header";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { config } from "../../../config/config";
import { apiList, invokeApi } from "../../../services/apiServices";
import Footer from "../../general-components/ui-components/Footer";

const BlogsList = () => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [blogsData, setBlogsData] = useState(null);
  const [isBlogsDataFetching, setIsBlogsDataFetching] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const { subid } = useParams();
  const [value, setValue] = useState(0);
  const [blogCategory, setBlogCategory] = useState([]);

  const handleChange = (event, newValue) => {
    const findId = blogCategory[newValue].id;
    setValue(newValue);
    setCategoryId(findId);
    setIsBlogsDataFetching(true);
  };

  useEffect(() => {
    const getBlogs = async () => {
      let params = {
        categoryId: categoryId,
        subCategoryId: subid,
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getBlogs,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setBlogsData(response.data.blogs);
          setIsBlogsDataFetching(false);
        } else {
          alert(
            "Something went wrong while getting blogs. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting blogs. Please try again later!!"
        );
      }
    };
    if (isBlogsDataFetching && categoryId) {
      getBlogs();
    }
  }, [isBlogsDataFetching, subid, categoryId, cookies]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getBlogCategoriesHierarchy = async (id) => {
      let params = {};
      let response = await invokeApi(
        config.getMyCollege + apiList.getBlogCategoriesHierarchy,
        params,
        cookies
      );
      if (response.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          let initialCategoryId = response.data.blogCategories[0].id;
          setBlogCategory(response.data.blogCategories);
          setCategoryId(initialCategoryId);
        } else {
          alert(
            "Something went wrong while getting the blogs. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the blogs. Please try again later!!"
        );
      }
    };
    getBlogCategoriesHierarchy();
  }, [cookies]);
  return (
    <>
      <Header />

      <Card
        sx={{
          width: { xs: "80%", md: "1350px" },
          margin: "auto",
          marginY: 2,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={3}>
                <Grid item md={2}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                  >
                    {blogCategory?.map((item, index) => (
                      <Tab key={item.id} label={item.category} />
                    ))}
                  </Tabs>
                </Grid>
                {!isBlogsDataFetching ? (
                  blogsData?.map((el, idx) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={idx}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          gap: 1,
                          alignSelf: "stretch",
                          padding: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            alignSelf: "stretch",
                            cursor: "pointer",
                            "&:hover img": {
                              transform: "scale(1.07)",
                            },
                          }}
                          onClick={() => {
                            navigate(`/blog/${el.slug}/${el.id}`);
                          }}
                        >
                          <Box
                            component="img"
                            src={el.bannerImageUrl}
                            alt="Banner"
                            sx={{
                              width: "100%",
                              height: "280px",
                              objectFit: "cover",
                              transition: "transform 0.5s ease-in-out",
                            }}
                          />
                        </Box>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          {el.blogAuthorName} {"|"}{" "}
                          {format(
                            new Date(el.createdDate.split(" ")[0]),
                            "dd LLL yyyy"
                          )}{" "}
                        </Typography>

                        <b
                          style={{ fontSize: "1.7em", cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/blog/${el.slug}/${el.id}`);
                          }}
                        >
                          {el.blogTitle}
                        </b>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary" }}
                        >
                          {el.metaDescription}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <CircularProgress sx={{ margin: "auto" }} />
                )}
              </Grid>
            </Box>
          </>
        </CardContent>
      </Card>

      <Footer />
    </>
  );
};

export default BlogsList;
