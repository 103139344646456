import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../general-components/ui-components/Header";
import Footer from "../../../general-components/ui-components/Footer";
import SubscribeNewsLetter from "../home-sections/SubscribeNewsLetter";
import SingleCollegeCard from "../home-sections/SingleCollegeCard";
import { apiList, invokeApi } from "../../../../services/apiServices";
import { config } from "../../../../config/config";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { SwipeableDrawer, IconButton } from "@material-ui/core";
import TuneIcon from "@mui/icons-material/Tune";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../static-pages/Loader";
import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";

const Colleges = () => {
  const [cookies, setCookies] = useCookies();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const { type } = useParams();

  const [collegesList, setCollegesList] = useState([]);

  const [programList, setProgramList] = useState([]);
  const [streamSearchText, setStreamSearchText] = useState("");
  const [isStreamMinimized, setIsStreamMinimized] = useState(false);
  const [programIdLoading, setProgramIdLoading] = useState(true);
  const handleToggleStreamMinimize = () =>
    setIsStreamMinimized((isMinimized) => !isMinimized);

  const filteredProgramList = programList.filter((item) =>
    item.programName.toLowerCase().includes(streamSearchText.toLowerCase())
  );

  const [universityList, setUniversityList] = useState([]);
  const [universitySearchText, setUniversitySearchText] = useState("");
  const [isUniversityMinimized, setIsUniversityMinimized] = useState(false);
  const [universityFetching, setUniversityFetching] = useState(true);
  const handleToggleUniversityMinimize = () =>
    setIsUniversityMinimized((isMinimized) => !isMinimized);

  const filteredUniversityList = universityList.filter((item) =>
    item.universityName
      .toLowerCase()
      .includes(universitySearchText.toLowerCase())
  );

  const [courseList, setCourseList] = useState([]);
  const [courseSearchText, setCourseSearchText] = useState("");
  const [courseIdFetching, setCourseIdFetching] = useState(true);
  const [isCourseMinimized, setIsCourseMinimized] = useState(false);
  const handleToggleCourseMinimize = () =>
    setIsCourseMinimized((isMinimized) => !isMinimized);

  const filteredCourseList = courseList.filter((item) =>
    item.courseName.toLowerCase().includes(courseSearchText.toLowerCase())
  );

  const [indiaStates, setIndiaStates] = useState([]);
  const [stateNameLoading, setStateNameLoading] = useState(true);
  const [stateSearchText, setStateSearchText] = useState("");
  const [isStateMinimized, setIsStateMinimized] = useState(false);
  const handleToggleStateMinimize = () =>
    setIsStateMinimized((isMinimized) => !isMinimized);

  const filteredStateList = indiaStates.filter((item) =>
    item.name.toLowerCase().includes(stateSearchText.toLowerCase())
  );

  const [indiaDistricts, setIndiaDistricts] = useState([]);
  const [cityNameLoading, setCityNameLoading] = useState(true);
  const [citySearchText, setCitySearchText] = useState("");
  const [isCityMinimized, setIsCityMinimized] = useState(false);
  const handleToggleCityMinimize = () =>
    setIsCityMinimized((isMinimized) => !isMinimized);

  const filteredCityList = indiaDistricts.filter((item) =>
    item.name.toLowerCase().includes(citySearchText.toLowerCase())
  );

  const [examList, setExamList] = useState([]);
  const [examIdLoading, setExamIdLoading] = useState(true);
  const [examSearchText, setExamSearchText] = useState("");
  const [isExamMinimized, setIsExamMinimized] = useState(false);
  const handleToggleExamMinimize = () =>
    setIsExamMinimized((isMinimized) => !isMinimized);

  const filteredExamList = examList.filter((item) =>
    item.examName.toLowerCase().includes(examSearchText.toLowerCase())
  );

  const [amenityList, setAmenitiesList] = useState([]);
  const [amenityIdLoading, setAmenityIdLoading] = useState(true);
  const [amenitySearchText, setAmenitySearchText] = useState("");
  const [isAmenityMinimized, setIsAmenityMinimized] = useState(false);
  const handleToggleAmenityMinimize = () =>
    setIsAmenityMinimized((isMinimized) => !isMinimized);

  const filteredAmenityList = amenityList.filter((item) =>
    item.amenityName.toLowerCase().includes(amenitySearchText.toLowerCase())
  );

  const programTypes = [
    { id: "1", name: "Full Time" },
    { id: "2", name: "Part Time" },
  ];
  const [isProgramTypeMinimized, setIsProgramTypeMinimized] = useState(false);
  const handleToggleProgramTypeMinimize = () =>
    setIsProgramTypeMinimized((isMinimized) => !isMinimized);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [collegeFetching, setCollegeFetching] = useState(true);
  const [category, setCategory] = useState("All");

  const universityInitialValue = cookies[config.filterCookie]?.university;
  const courseInitialValue = cookies[config.filterCookie]?.course;
  const stateInitialValue = cookies[config.filterCookie]?.state;
  const cityInitialValue = cookies[config.filterCookie]?.city;
  const programInitialValue = cookies[config.filterCookie]?.program;
  const programTypeInitialValue = cookies[config.filterCookie]?.programType;
  const examInitialValue = cookies[config.filterCookie]?.exam;
  const amenityInitialValue = cookies[config.filterCookie]?.amenity;

  const [universityId, setUniversityId] = useState(
    universityInitialValue || null
  );
  const [courseId, setCourseId] = useState(courseInitialValue || null);
  const [state, setStateName] = useState(stateInitialValue || null);
  const [cityName, setCityName] = useState(cityInitialValue || null);
  const [programId, setProgramId] = useState(programInitialValue || null);
  const [programTypeName, setProgramTypeName] = useState(
    programTypeInitialValue || null
  );
  const [examId, setExamId] = useState(examInitialValue || null);
  const [amenityId, setAmenityId] = useState(amenityInitialValue || null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!cookies[config.filterCookie]) {
      setCookies(
        config.filterCookie,
        JSON.stringify({
          university: null,
          program: null,
          programType: null,
          course: null,
          exam: null,
          amenity: null,
          state: cookies[config.preferencesCookie]?.state || null,
          city: cookies[config.preferencesCookie]?.city || null,
        }),
        {
          path: "/",
          maxAge: 3000000,
          sameSite: "strict",
        }
      );
    }
  }, [cookies]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  // Function to handle applying filters
  const handleApplyFilters = () => {
    toggleDrawer(false)();
  };

  const handleSetDefault = () => {
    setCookies(
      config.filterCookie,
      JSON.stringify({
        university: null,
        program: null,
        programType: null,
        course: null,
        exam: null,
        amenity: null,
        state: cookies[config.preferencesCookie]?.state || null,
        city: cookies[config.preferencesCookie]?.city || null,
      }),
      {
        path: "/",
        maxAge: 3000000,
        sameSite: "strict",
      }
    );
    setUniversityId(null);
    setCourseId(null);
    setStateName(null);
    setCityName(null);
    setProgramId(null);
    setProgramTypeName(null);
    setExamId(null);
    setAmenityId(null);
    setCategory("All");
    setCollegeFetching(true);
  };

  const extractIndiaData = (countries) => {
    // Find the country object for India
    const indiaCountry = countries.find(
      (country) => country.countryName === "India"
    );

    if (indiaCountry) {
      // Extract states under India with id and state
      const states = indiaCountry.states.map((state, index) => ({
        id: index + 1, // You can use a more meaningful id if you have it in your data
        name: state.stateName,
      }));
      setIndiaStates(states);
      setStateNameLoading(false);
      // Extract districts under India with id and districtName
      const districts = indiaCountry.states.flatMap((state) =>
        state.districts.map((district, index) => ({
          id: index + 1, // You can use a more meaningful id if you have it in your data
          name: district.districtName,
        }))
      );
      setIndiaDistricts(districts);
      setCityNameLoading(false);
    }
  };

  // fetch colleges
  useEffect(() => {
    const getAllUniversity = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getUniversities,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setUniversityList(response.data.universities);
          setUniversityFetching(false);
        } else {
          alert(
            "Something went wrong while getting the University Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the University Details. Please try again later!!"
        );
      }
    };
    const getAllCourseName = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getCourses,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCourseList(response.data.courses);
          setCourseIdFetching(false);
        } else {
          alert(
            "Something went wrong while getting the course list Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the course list Details. Please try again later!!"
        );
      }
    };
    const getExam = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getExams,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setExamList(response.data.exams);
          setExamIdLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Exams Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Exams Details. Please try again later!!"
        );
      }
    };
    const getAllProgram = async () => {
      let params = {
        status: "Active",
        instituteType: type,
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getPrograms,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setProgramList(response.data.programs);
          setProgramIdLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Program Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Program Details. Please try again later!!"
        );
      }
    };
    const getAllAmenities = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAmenitites,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setAmenitiesList(response.data.amenities);
          setAmenityIdLoading(false);
        } else {
          alert(
            "Something went wrong while getting the Amenities Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Amenities Details. Please try again later!!"
        );
      }
    };
    const getAllAddress = async () => {
      let params = {
        status: "Active",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getAddress,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          extractIndiaData(response.data.countries);
        } else {
          alert(
            "Something went wrong while getting the Address Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Address Details. Please try again later!!"
        );
      }
    };
    if (fetching) {
      getAllAmenities();
      getAllUniversity();
      getAllAddress();
      getAllProgram();
      getExam();
      getAllCourseName();
      setFetching(false);
    }
  }, [cookies, fetching]);

  useEffect(() => {
    const getAllColleges = async () => {
      let params = {
        status: "Active",
        universityId,
        courseId,
        state,
        programId,
        courseType: programTypeName,
        amenityId,
        examId,
        city: cookies[config.preferencesCookie]
          ? cookies[config.preferencesCookie]?.city
          : cityName
          ? cityName
          : "bengaluru",
      };
      let response = await invokeApi(
        config.getMyCollege + apiList.getColleges,
        params,
        cookies
      );
      if (response?.status >= 200 && response.status < 300) {
        if (response.data.responseCode === "200") {
          setCollegesList(response.data.colleges);
          setCollegeFetching(false);
        } else {
          alert(
            "Something went wrong while getting the Colleges Details. Please try again later!"
          );
        }
      } else {
        alert(
          "Something went wrong while getting the Colleges Details. Please try again later!!"
        );
      }
    };
    if (collegeFetching) {
      getAllColleges();
    }
  }, [cookies, collegeFetching]);

  function handleUpdateCookies(identity, value) {
    const updatedCookies = {
      ...cookies[config.filterCookie],
      [identity]: value,
    };
    setCookies(config.filterCookie, JSON.stringify(updatedCookies), {
      path: "/",
      maxAge: 3000000,
      sameSite: "strict",
    });
    setCollegeFetching(true);
  }

  return (
    <>
      <Header />
      {collegeFetching ? (
        <>
          <Box
            sx={{
              height: "90vh",
            }}
          >
            <Box sx={{ position: "absolute", left: "50%", top: "50%" }}>
              <Loader />
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ mt: "16px" }}>
          {isMatch ? (
            <>
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: { md: "1.5rem", xs: "0.8rem" },
                      fontWeight: "bold",
                    }}
                  >
                    List of Top {type} In{" "}
                    {cookies[config.preferencesCookie]?.city} based on{" "}
                    {new Date().getFullYear()} Ranking
                  </Typography>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="Popular">Popular</MenuItem>
                      <MenuItem value="Rating">Rating</MenuItem>
                      <MenuItem value="Highest Fees">Highest Fees</MenuItem>
                      <MenuItem value="Lowest Fees">Lowest Fees</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Divider sx={{ width: "80%", mx: "auto", my: "20px" }} />
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { md: "1fr 1fr", sm: "1fr" },
                    justifyContent: "center",
                  }}
                >
                  {collegesList.length <= 0 ? (
                    <Typography variant="h3" sx={{ color: "gray" }}>
                      No {type} Found
                    </Typography>
                  ) : (
                    collegesList?.map((item, index) => (
                      <SingleCollegeCard key={index} {...item} />
                    ))
                  )}
                </Box>
              </Container>
              <Box
                sx={{
                  backgroundColor: "#405189",
                  borderRadius: "40px",
                  position: "fixed",
                  bottom: "16px",
                  right: "10px",
                  transform: "translateX(-50%)",
                  zIndex: "1000", // Ensure it's above other elements
                }}
              >
                <IconButton
                  style={{ color: "#fff" }}
                  onClick={toggleDrawer(true)}
                >
                  <TuneIcon />
                </IconButton>
              </Box>
            </>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={{ borderRight: "1px solid #dee2e6" }}
                >
                  <Box sx={{ p: "10px", borderBottom: "1px solid #dee2e6" }}>
                    <Typography variant="h6" sx={{ fontSize: "24px" }}>
                      Filters
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ color: "#adb5bd", fontSize: "14px" }}>
                        Found {collegesList.length} {type}
                      </Typography>
                      <Typography
                        style={{
                          color: "#4285f4",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={handleSetDefault}
                      >
                        set default
                      </Typography>
                    </Box>
                  </Box>
                  {/* below the start for university filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isUniversityMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>
                          University
                        </Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleUniversityMinimize}
                        >
                          {isUniversityMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isUniversityMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={universitySearchText}
                            onChange={(e) => {
                              setUniversitySearchText(e.target.value);
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {universityFetching ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredUniversityList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.id === universityId}
                                      onChange={() => {
                                        setUniversityId(item.id);
                                        handleUpdateCookies(
                                          "university",
                                          item.id
                                        );
                                      }}
                                    />
                                  }
                                  label={item.universityName}
                                />
                              ))}

                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the end for university filter ui section  */}
                  <Divider />
                  {/* below the start for stream filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isStreamMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>
                          Stream
                        </Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleStreamMinimize}
                        >
                          {isStreamMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isStreamMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={streamSearchText}
                            onChange={(e) =>
                              setStreamSearchText(e.target.value)
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {programIdLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredProgramList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.id === programId}
                                      onChange={() => {
                                        setProgramId(item.id);
                                        handleUpdateCookies("program", item.id);
                                      }}
                                    />
                                  }
                                  label={item.programName}
                                />
                              ))}
                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the end for university filter ui section  */}
                  <Divider />
                  {/* below the start for course filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isCourseMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>
                          Course
                        </Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleCourseMinimize}
                        >
                          {isCourseMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isCourseMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={courseSearchText}
                            onChange={(e) =>
                              setCourseSearchText(e.target.value)
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {courseIdFetching ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredCourseList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.id === courseId}
                                      onChange={() => {
                                        setCourseId(item.id);
                                        handleUpdateCookies("course", item.id);
                                      }}
                                    />
                                  }
                                  label={item.courseName}
                                />
                              ))}
                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the end for course filter ui section  */}
                  <Divider />
                  {/* below the start for state filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isStateMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>State</Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleStateMinimize}
                        >
                          {isStateMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isStateMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={stateSearchText}
                            onChange={(e) => setStateSearchText(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {stateNameLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredStateList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.name === state}
                                      onChange={() => {
                                        setStateName(item.name);
                                        handleUpdateCookies("state", item.id);
                                      }}
                                    />
                                  }
                                  label={item.name}
                                />
                              ))}
                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the start for state filter ui section  */}
                  <Divider />
                  {/* below the start for city filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isCityMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>City</Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleCityMinimize}
                        >
                          {isCityMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isCityMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={citySearchText}
                            onChange={(e) => setCitySearchText(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {cityNameLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredCityList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.name === cityName}
                                      onChange={() => {
                                        setCityName(item.name);
                                        handleUpdateCookies("city", item.id);
                                      }}
                                    />
                                  }
                                  label={item.name}
                                />
                              ))}
                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the end for city filter ui section  */}
                  <Divider />
                  {/* below the start for programType filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isProgramTypeMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>
                          Program Type
                        </Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleProgramTypeMinimize}
                        >
                          {isProgramTypeMinimized ? (
                            <AddIcon />
                          ) : (
                            <RemoveIcon />
                          )}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isProgramTypeMinimized && (
                      <Box
                        sx={{
                          maxHeight: "150px",
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": { width: "5px" },
                        }}
                      >
                        {/* Checkboxes and text in one column */}
                        <FormControl component="fieldset">
                          {programTypes?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={item.name === programTypeName}
                                  onChange={() => {
                                    setProgramTypeName(item.name);
                                    handleUpdateCookies("programType", item.id);
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                          {/* Add more checkboxes as needed */}
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                  {/* below the end for programType filter ui section  */}
                  <Divider />
                  {/* below the start for exam filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isExamMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>Exam</Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleExamMinimize}
                        >
                          {isExamMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isExamMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={examSearchText}
                            onChange={(e) => setExamSearchText(e.target.value)}
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {examIdLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredExamList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.id === examId}
                                      onChange={() => {
                                        setExamId(item.id);
                                        handleUpdateCookies("exam", item.id);
                                      }}
                                    />
                                  }
                                  label={item.examName}
                                />
                              ))}
                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the start for exam filter ui section  */}
                  <Divider />
                  {/* below the start for amenity filter ui section  */}
                  <Box
                    className={`minimizable-content ${
                      isAmenityMinimized ? "minimized" : ""
                    }`}
                    sx={{ p: "10px" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ flex: 1 }}>
                        {/* Left side text or tabs */}
                        <Typography sx={{ fontSize: "18px" }}>
                          Facitlities
                        </Typography>
                      </Box>
                      <Box>
                        {/* Right side icon for minimize/maximize */}
                        <Button
                          variant="text"
                          onClick={handleToggleAmenityMinimize}
                        >
                          {isAmenityMinimized ? <AddIcon /> : <RemoveIcon />}
                        </Button>
                      </Box>
                    </Box>

                    {/* Content to be minimized or maximized */}
                    {!isAmenityMinimized && (
                      <>
                        <Box>
                          {/* Your search input and other text */}
                          <Input
                            type="search"
                            placeholder="Search..."
                            fullWidth
                            value={amenitySearchText}
                            onChange={(e) =>
                              setAmenitySearchText(e.target.value)
                            }
                            startAdornment={
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            }
                            sx={{
                              borderRadius: "15px",
                              marginBottom: "8px",
                            }}
                          />
                        </Box>
                        {amenityIdLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Box
                            sx={{
                              maxHeight: "150px",
                              overflowY: "scroll",
                              "&::-webkit-scrollbar": { width: "5px" },
                            }}
                          >
                            {/* Checkboxes and text in one column */}
                            <FormControl component="fieldset">
                              {filteredAmenityList?.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={item.id === amenityId}
                                      onChange={() => {
                                        setAmenityId(item.id);
                                        handleUpdateCookies("amenity", item.id);
                                      }}
                                    />
                                  }
                                  label={item.amenityName}
                                />
                              ))}
                              {/* Add more checkboxes as needed */}
                            </FormControl>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  {/* below the start for amenity filter ui section  */}
                </Grid>
                <Grid item md={9} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                    >
                      List of Top {type} In{" "}
                      {cookies[config.preferencesCookie]?.city} based on{" "}
                      {new Date().getFullYear()}
                      Ranking
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Popular">Popular</MenuItem>
                        <MenuItem value="Rating">Rating</MenuItem>
                        <MenuItem value="Highest Fees">Highest Fees</MenuItem>
                        <MenuItem value="Lowest Fees">Lowest Fees</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Divider sx={{ width: "80%", mx: "auto", my: "20px" }} />

                  {collegesList.length <= 0 ? (
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h3" sx={{ color: "gray" }}>
                        No {type} Found
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      {collegesList?.map((item, index) => (
                        <SingleCollegeCard key={index} {...item} />
                      ))}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
      <SubscribeNewsLetter />
      <Footer />
      {/* mobile version filter  */}
      <SwipeableDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: "10px", borderBottom: "1px solid #dee2e6" }}>
          <Typography variant="h6" sx={{ fontSize: "24px" }}>
            Filters
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ color: "#adb5bd", fontSize: "14px" }}>
              Found {collegesList.length} {type}
            </Typography>
            <Typography
              style={{
                color: "#4285f4",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={handleSetDefault}
            >
              set default
            </Typography>
          </Box>
        </Box>
        <Box sx={{ height: "100%", overflowY: "auto" }}>
          <Box
            className={`minimizable-content ${
              isUniversityMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>University</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleUniversityMinimize}>
                  {isUniversityMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isUniversityMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={universitySearchText}
                    onChange={(e) => setUniversitySearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {universityFetching ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredUniversityList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.id === universityId}
                              onChange={() => {
                                setUniversityId(item.id);
                                handleUpdateCookies("university", item.id);
                              }}
                            />
                          }
                          label={item.universityName}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isStreamMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>Stream</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleStreamMinimize}>
                  {isStreamMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isStreamMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={streamSearchText}
                    onChange={(e) => setStreamSearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {programIdLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredProgramList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.id === programId}
                              onChange={() => {
                                setProgramId(item.id);
                                handleUpdateCookies("program", item.id);
                              }}
                            />
                          }
                          label={item.programName}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isCourseMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>Course</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleCourseMinimize}>
                  {isCourseMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isCourseMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={courseSearchText}
                    onChange={(e) => setCourseSearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {courseIdFetching ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredCourseList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.id === courseId}
                              onChange={() => {
                                setCourseId(item.id);
                                handleUpdateCookies("course", item.id);
                              }}
                            />
                          }
                          label={item.courseName}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isStateMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>State</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleStateMinimize}>
                  {isStateMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isStateMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={stateSearchText}
                    onChange={(e) => setStateSearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {stateNameLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredStateList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.name === state}
                              onChange={() => {
                                setStateName(item.name);
                                handleUpdateCookies("state", item.id);
                              }}
                            />
                          }
                          label={item.name}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isCityMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>City</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleCityMinimize}>
                  {isCityMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isCityMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={citySearchText}
                    onChange={(e) => setCitySearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {cityNameLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredCityList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.name === cityName}
                              onChange={() => {
                                setCityName(item.name);
                                handleUpdateCookies("city", item.id);
                              }}
                            />
                          }
                          label={item}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isProgramTypeMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>Program Type</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button
                  variant="text"
                  onClick={handleToggleProgramTypeMinimize}
                >
                  {isProgramTypeMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isProgramTypeMinimized && (
              <Box
                sx={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": { width: "5px" },
                }}
              >
                {/* Checkboxes and text in one column */}
                <FormControl component="fieldset">
                  {programTypes?.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={item.name === programTypeName}
                          onChange={() => {
                            setProgramTypeName(item.name);
                            handleUpdateCookies("programType", item.id);
                          }}
                        />
                      }
                      label={item.name}
                    />
                  ))}
                  {/* Add more checkboxes as needed */}
                </FormControl>
              </Box>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isExamMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>Exam</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleExamMinimize}>
                  {isExamMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isExamMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={examSearchText}
                    onChange={(e) => setExamSearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {examIdLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredExamList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.id === examId}
                              onChange={() => {
                                setExamId(item.id);
                                handleUpdateCookies("exam", item.id);
                              }}
                            />
                          }
                          label={item.examName}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box
            className={`minimizable-content ${
              isAmenityMinimized ? "minimized" : ""
            }`}
            sx={{ p: "10px" }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ flex: 1 }}>
                {/* Left side text or tabs */}
                <Typography sx={{ fontSize: "18px" }}>Facitlities</Typography>
              </Box>
              <Box>
                {/* Right side icon for minimize/maximize */}
                <Button variant="text" onClick={handleToggleAmenityMinimize}>
                  {isAmenityMinimized ? <AddIcon /> : <RemoveIcon />}
                </Button>
              </Box>
            </Box>

            {/* Content to be minimized or maximized */}
            {!isAmenityMinimized && (
              <>
                <Box>
                  {/* Your search input and other text */}
                  <Input
                    type="search"
                    placeholder="Search..."
                    fullWidth
                    value={amenitySearchText}
                    onChange={(e) => setAmenitySearchText(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderRadius: "15px",
                      marginBottom: "8px",
                    }}
                  />
                </Box>
                {amenityIdLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box
                    sx={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": { width: "5px" },
                    }}
                  >
                    {/* Checkboxes and text in one column */}
                    <FormControl component="fieldset">
                      {filteredAmenityList?.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={item.id === amenityId}
                              onChange={() => {
                                setAmenityId(item.id);
                                handleUpdateCookies("amenity", item.id);
                              }}
                            />
                          }
                          label={item.amenityName}
                        />
                      ))}
                      {/* Add more checkboxes as needed */}
                    </FormControl>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </SwipeableDrawer>
    </>
  );
};

export default Colleges;
